import React from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

function SkeletonPlaceholder() {
  return (
    <div className="card card-h-2 me-3">
      <div className="card-h-2-width">
        <ReactPlaceholder type="rect" color="red" rows={7} />
      </div>
    </div>
  );
}

export default SkeletonPlaceholder;
