import React from "react";
import { GoogleMap, useLoadScript, MarkerF} from "@react-google-maps/api";


export default function NewMap({lat,lng}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBsnVLVZOQYt7P1IJKKZ_nAoBjlMn5wgjw",
    // libraries: ["places"]
  });
  // <LoadScript id="script-loader" googleMapsApiKey="AIzaSyBsnVLVZOQYt7P1IJKKZ_nAoBjlMn5wgjw" libraries={["places"]}/>
  if (!isLoaded) return <div>Loading...</div>;
  return <Map  lat={lat} lng={lng}/>;
}

function Map({lat,lng}) {

  return (
    <>
      <GoogleMap
        zoom={12}
        center={{ lat: lat, lng: lng }}
        mapContainerStyle={{ width: "100%", height: "300px" }}
      >
        <MarkerF position={{ lat: lat, lng: lng }} />
       
      </GoogleMap>
    </>
  );
}

