import React from "react";
import Button from "../../../../components/Button";

const RetrieveTicket = () => {
  return (
    <div
      className="modal fade"
      id="retrieve-modal"
      aria-hidden="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content moa">
          <div className="modal-body">
            <div className="share-content">
              <div className="mb-4 mt-5 mx-lg-5">
                <h4>Retrieve Your Ticket Code</h4>
                <hr />
              </div>
              <div className="mb-2 mx-lg-5">
                <div className="d-flex">
                  <label htmlFor="phone" className="me-5">
                    <div className="d-flex">
                      <input type="radio" name="phone" value="" id="phone" />
                      <h3 className="my-auto ms-2">Phone</h3>
                    </div>
                  </label>
                  <label htmlFor="email" className="ms-5">
                    <div className="d-flex">
                      <input type="radio" name="phone" value="" id="email" />
                      <h3 className="my-auto ms-2">Email</h3>
                    </div>
                  </label>
                </div>
              </div>
              <div className="mx-lg-5 mt-1">
                <input type="text" className="form-control" />
              </div>
              <div className="mx-lg-5 mt-3">
                <Button
                  buttonText={"Save Nominee Details"}
                  cssClasses="btn btn-primary bg-default buttonedit1 w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetrieveTicket;
