import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import usePostFetch from "../../../../../../components/customHooks/usePostFetch";
import Loader from "../../../../../../components/helpers/Loader";
import UsersTable from "./partials/UsersTable";

const ManageUsers = () => {

  const { id } = useParams();
  const [reload, setReload] = useState(false);
  const { data : users, isloaded } = usePostFetch(`/api/v2/affiliate/all?event_id=${id}`, reload);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <Link
                  to={`/dashboard/event/${id}/create-user`}
                  className="btn btn-primary float-right veiwbutton"
                >
                  Create New User
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table ">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">
                    <h4>Available Users</h4>
                  </div>
                  <div className="p-2">
                    <Link
                      to={`/dashboard/event/${id}/tickets`}
                      className="btn btn-secondary float-right"
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body booking_card">
                {isloaded ? (
                  <div className="table-responsive">
                    <UsersTable
                      users={users}
                      reload={reload}
                      setReload={setReload}
                    />

                    {users?.length === 0 && (
                      <div className="mt-5">
                        <h3 className="text-center">No Category Available</h3>
                        <div className="text-center mt-3">
                          <Link
                            to={`/dashboard/event/${id}/create-user`}
                            className="btn btn-primary veiwbutton"
                          >
                            Create New User
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;
