import { Link } from "react-router-dom";
import CardItem from "../../components/CardItem";
import routes from "../../../../components/helpers/routes";

const OngoingAwards = ({ awards }) => {
  return (
    <div className="col-lg-12">
      <div className="row advert-margin ">
        <div className="col-lg-12 d-flex justify-content-between mb-2">
          <div className="text-margin-start12">
            <h3>Ongoing Awards</h3>
          </div>
          <div>
            <Link
              to={routes.AWARDS_PAGE}
              className="btn btn-outline-primary1 rounded-pill view-text"
            >
              View more
            </Link>
          </div>
        </div>
        <div className="col-lg-12 col-sm-auto">
          <div className="row">
            {awards
              ?.filter(
                ({ status, end_date }) =>
                  status === "voting" && !(new Date() >= new Date(end_date))
              )
              .slice(0, 10)
              .map(({ id, image, title }, i) => (
                <div className="col-custom-2 col-md-3" id="custom-col" key={i}>
                  <Link to={`/award/${id}/categories`}>
                    <CardItem image={image} title={title} />
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OngoingAwards;
