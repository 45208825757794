import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import usePostFetch from "../../../components/customHooks/usePostFetch";
import LoadingPage from "../../LoadingPage";
import { QRCode } from "react-qrcode-logo";
import convertDate from "../../../components/helpers/ConverDate";

const AttendeeTicket = () => {
  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Ticket",
    onAfterPrint: () => {},
  });

  const { id } = useParams();
  const {
    data: ticket,
    errors,
    isloaded,
  } = usePostFetch(`/api/v2/guest/get-ticket`, { ticket_id: id });
  if (errors?.status === 422) {
    const message = errors?.data?.errors?.ticket_id;
    Swal.fire({
      title: "Not Found",
      text: message,
      icon: "info",
      confirmButtonColor: "#011535",
      confirmButtonText: "Okay",
    }).then((_) => {
      navigate("*");
    });
  }

  // console.log(ticket);

  return (
    <>
      {isloaded ? (
        <div className="container">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary text-align-center mt-5 w-md-80"
              onClick={handlePrint}
            >
              Download/Print
            </button>
          </div>
          <div className="card card-shadow mt-md-4 mt-4 p-lg-5 p-2 p-md-3">
            <div className="col-md-12 col-12">
              <div ref={componentRef} className="padding-div">
                <div className="row">
                  <div className="col-md-12">
                    <div className="logo-container">
                      <img
                        src="/assets/images/logo1.png"
                        alt="arrow"
                        className="img-fluid"
                        width="150"
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-flex align-items-center justify-content-center mb-3">
                  <div className="col-md-6 col-12 mt-md-5 mt-3 col-width">
                    <div className="text-font">
                      <h3 className="text-capitalize">
                        {ticket?.event?.title}
                      </h3>
                      <h5 className="mt-md-4 mt-3">Event Date and Time</h5>
                      <h4>{ticket?.ticket?.date}</h4>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 col-width1">
                    <div className="qr-container">
                      <QRCode
                        value={ticket?.qr_code}
                        size={200}
                        logoImage={"/assets/images/logoqr.png"}
                        logoWidth={50}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row mt-3">
                  <div className="col-lg-10 col-md-12 col-12">
                    <table>
                      <tbody>
                        <tr>
                          <td className="section-1">
                            <h4>Ticket Number:</h4>
                          </td>
                          <td className="section-2">
                            <h4>{ticket?.id_number}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="section-1">
                            <h4>Ticket Holder:</h4>
                          </td>
                          <td className="section-2">
                            <h4 className="text-capitalize">{ticket?.name}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="section-1">
                            <h4>Ticket Type:</h4>
                          </td>
                          <td className="section-2 text-capitalize">
                            <h4>{ticket?.ticket?.title}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="section-1">
                            <h4>Price:</h4>
                          </td>
                          <td className="section-2">
                            <h4>GHC {ticket?.ticket?.price}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="section-1">
                            <h4>Purchased:</h4>
                          </td>
                          <td className="section-2">
                            <h4>{convertDate(ticket?.updated_at)}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="section-1">
                            <h4>Trans Ref#:</h4>
                          </td>
                          <td className="section-2">
                            <h4>{ticket?.ref_code}</h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="text-font">
                      <h4>Venue</h4>
                      <h5 className="text-capitalize">
                        {ticket?.event?.venue}
                      </h5>
                    </div>
                    <div className="text-font mt-4">
                      <h4>Location</h4>
                      <h5 className="text-capitalize">
                        {ticket?.event?.location}
                      </h5>
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          (window.location.href = `https://maps.google.com/maps?q=${ticket?.event?.lat},${ticket?.event?.long}`)
                        }
                        target={"_blank"}
                        className="btn btn-primary "
                      >
                        Get Direction
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="text-font mt-4">
                      <h4>Contact us for questions and concerns</h4>
                      <h5>
                        website: https:://www.opencastgh.com
                        <br />
                        email: ticket@opencastgh.com
                        <br />
                        phone: 0558571228
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary text-align-center mt-2 mb-5 w-md-80"
              onClick={handlePrint}
            >
              Download/Print
            </button>
          </div>
        </div>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};
export default AttendeeTicket;
