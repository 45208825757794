import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "../partials/Nav";
import CardItem from "../components/CardItem";
import Footer from "../partials/Footer";
import useFetch from "../../../components/customHooks/useFetch";
import LoadingPage from "../../LoadingPage";

const NominationsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: awards,
    isloaded,
  } = useFetch("/api/v2/guest/nomination/awards");
  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
  }, []);

  const filter = awards?.filter((award) => {
    const hasTitle = award?.title?.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase());
    
    return searchTerm === "" || hasTitle;
  });

  return (
    <>
      <Nav />
      <div className="award-div d-flex">
        <div className="award-img-bg">
          {/* <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${award?.image}`}
            className="img-thumbnail"
            alt="award"
          /> */}
        </div>
        <div className="award-img-title1">
          <h1 className="text-white text-capitalize">
            Available Awards For Nomination
          </h1>
        </div>
      </div>
      <div className="advert-margin mt-3">
        <div className="col-lg-12">
          <div className="text-center">
            <h3>
              <i>Search For Award</i>
            </h3>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="search-cover">
            <input
              type="text"
              className="form-control rounded-pill search-text"
              placeholder="Search for award here..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value.toLocaleLowerCase());
              }}
            />
            <i className="fas fa-search search-icon"></i>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        {isloaded ? (
          <div className="mt-4">
            {awards?.length > 0 ? (
              <div className="row advert-margin">
                {filter?.map((award, i) => (
                  <div
                    className="col-custom-2 col-md-3"
                    id="custom-col"
                    key={i}
                  >
                    <Link to={`/nominations/${award?.id}`}>
                      <CardItem
                        image={award?.image}
                        title={award?.title}
                        status=""
                      />
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <div className="row text-center">
                <h4>No Award is currently opened for nominations</h4>
              </div>
            )}
          </div>
        ) : (
          <LoadingPage />
        )}
      </div>
      <Footer />
    </>
  );
};
export default NominationsPage;
