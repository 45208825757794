import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PagePagination from "../../../../components/PagePagination";
import LoadingPage from "../../../LoadingPage";
import CardItem from "../../components/CardItem";

const Nominees = ({ award, searchTerm, selectedCategory, isloaded }) => {
  // eslint-disable-next-line array-callback-return
  const nominees = award?.nominees?.filter((nominee) => {
    if (searchTerm === "" && selectedCategory === "") {
      return nominee;
    } else if (Number(nominee?.category_id) === Number(selectedCategory)) {
      return nominee;
    } else if (
      (nominee?.stage_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        nominee?.full_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        nominee?.nominee_code
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      selectedCategory === ""
    ) {
      return nominee;
    }
  });

  const [itemOffset, setItemOffset] = useState(0);
  const itemPerPage = 10;
  const endOffset = itemOffset + itemPerPage;
  const [pageCount, setPageCount] = useState(
    Math.ceil(nominees?.length / itemPerPage)
  );
  const [currentItems, setCurrentItems] = useState(
    nominees?.slice(itemOffset, endOffset)
  );

  useEffect(() => {
    setCurrentItems(nominees?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(nominees?.length / itemPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isloaded, itemOffset, nominees]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemPerPage) % nominees.length;
    setItemOffset(newOffset);
  };

  // console.log(nominees);

  return isloaded ? (
    <div className="container-fluid">
      <div className="d-flex justify-content-between mb-2">
        <div className="text-margin-start12">
          <h3>Nominees / Contestants</h3>
        </div>
      </div>
      {currentItems?.length > 0 ? (
        <div className="row">
          {currentItems?.map((nominee, i) => (
            <div className="col-custom-2 col-md-3" id="custom-col" key={i}>
              <Link to={`/awards/${nominee?.nominee_code}`}>
                <CardItem
                  image={
                    nominee?.is_award_image === "true"
                      ? award?.image
                      : nominee?.image
                  }
                  title={nominee?.stage_name || nominee?.full_name}
                  status={nominee?.nominee_code}
                />
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div className="row">
          <h4>No Nominee/Contestant Available</h4>
        </div>
      )}
      <div className="mb-5">
        <PagePagination
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  ) : (
    <LoadingPage />
  );
};
export default Nominees;
