import React, { useEffect, useState } from "react";
import TableRows from "./TableRows";
import PagePagination from "../../../../../components/PagePagination";
import ExcelFile from "../../../../../components/ExcelFile";

export default function CategoryTable({ categories, award_id, reloadTable, setReloadTable }) {
  const [search, setSearch] = useState("");

 
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset]= useState(0);
  const itemPerPage = 3;

  useEffect(() => {
   const endOffset = itemOffset + itemPerPage;
   setCurrentItems(categories?.slice(itemOffset, endOffset));
   setPageCount(Math.ceil(categories?.length / itemPerPage))
  }, [itemOffset, itemPerPage, categories])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemPerPage) % categories.length;
    setItemOffset(newOffset);
  }


  return (
    <>
      <div className="d-flex justify-content-between align-content-center">
        <div className="w-50 mb-2">
          <input
            type="text"
            className="form-control text-center"
            placeholder="Search Category"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
          <ExcelFile categories={categories}/>
      </div>

      <table
        id="category-table"
        className="datatable table-stripped table table-hover table-center mb-0"
      >
        <thead>
          <tr>
            <th>Title</th>
            <th>Gender Type</th>
            <th>Capacity</th>
            <th>Nominations Picked</th>
            <th>Price</th>
            <th>Status</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.filter(item => item?.title.toLowerCase().includes(search.toLowerCase()))?.map((category, index) => (
            <TableRows category={category} award_id={award_id} key={index} reloadTable={reloadTable} setReloadTable={setReloadTable} />
          ))}
        </tbody>
      </table>
      <div className="pagin-pos">
        <PagePagination pageCount={pageCount} handlePageClick={handlePageClick}/>
      </div>

    </>
  );
}
