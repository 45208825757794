import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../../components/customHooks/useFetch";
import { useEffect, useState } from "react";
import PagePagination from "../../../../components/PagePagination";
import BarChart from "../../../../components/BarChart";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Results = () => {
  const { award_id, category_id } = useParams();
  const navigate = useNavigate();
  const { data } = useFetch(
    `/api/v2/awards/${award_id}/category/${category_id}`
  );

  const [search, setSearch] = useState("");

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemPerPage = 5;
  const results = data?.results;

  useEffect(() => {
    const endOffset = itemOffset + itemPerPage;
    setCurrentItems(results?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(results?.length / itemPerPage));
  }, [itemOffset, itemPerPage, results]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemPerPage) % data?.results?.length;
    setItemOffset(newOffset);
  };

  const fileType = "xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(results);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Results.xlsx");
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2 text-uppercase">
                  {data?.category?.award?.title || "Loading..."}
                </h4>{" "}
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-primary float-right veiwbutton"
                >
                  Back
                </button>{" "}
              </div>
            </div>
          </div>
        </div>

        <BarChart results={results} />
        <div className="row mt-3">
          <div className="col-sm-2"></div>
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-hearder">
                <div className="d-flex justify-content-between mx-3 mt-2">
                  <div className="p-2">
                    <h5>Contestants Vote Count</h5>
                  </div>
                  <div className="p-2">
                    <input
                      type="text"
                      placeholder="Search Here"
                      className="form-control"
                      required
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div className="p-2">
                    <button className="btn btn-primary" onClick={exportToCSV}>
                      Export as Excel
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body booking_card">
                <div className="table-responsive">
                  <table
                    id="results-table"
                    className="datatable table table-stripped table table-hover table-center mb-0"
                  >
                    <thead>
                      <tr>
                        <th>Nominee Code</th>
                        <th>Full Name/Stage Name</th>
                        <th className="text-center">Votes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        currentItems
                        // eslint-disable-next-line array-callback-return
                          ?.filter((item) => {
                            if (search === "") {
                              return item;
                            } else if (
                              item?.stage_name
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              item?.full_name
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              item?.nominee_code
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          ?.map((result, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-uppercase">
                                  {result?.nominee_code}
                                </td>
                                <td className="text-capitalize">
                                  {result?.stage_name || result?.full_name}
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {result?.votes?.count || 0}{" "}
                                </td>
                              </tr>
                            );
                          })
                      }
                    </tbody>
                  </table>
                </div>
                <div className="pagin-pos">
                  <PagePagination
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
