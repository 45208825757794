import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";
import { getCurrentDate } from "../../../../components/helpers/getCurrentDate";

const EditAward = () => {
  const [selectedImage, setSelectedImage] = useState();
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [userInput, setUserInput] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/v2/awards/${id}`)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setUserInput(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        } else if (err.response.status === 400) {
          const msg =
            err?.response?.data?.error_msg?.award ||
            "This award has already ended, kindly contact our support team for assistance/support";
          Swal.fire("Failed", msg, "info").then(()=>{navigate('/dashboard/awards')});
        }
      });
  }, [id,navigate]);

  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    var hour = ("0" + date.getUTCHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    return `${date.getFullYear()}-${mnth}-${day} ${hour}:${minutes}:00`;
  }

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));

    setErrors((errors) => ({
      ...errors,
      [e.target.name]: [""],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("title", userInput.title);
    data.append("start_date", convertDate(userInput.start_date));
    data.append("end_date", convertDate(userInput.end_date));
    // data.append("award_prefix", userInput.award_prefix);
    data.append("price_per_vote", userInput.price_per_vote);
    selectedImage && data.append("image", selectedImage);
    axios
      .post(`/api/v2/awards/update/${id}`, data)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setLoading(false);
          let msg = res.data.msg;
          Swal.fire({
            title: "Success",
            text: msg,
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          }).then(() => {
            navigate("/dashboard/awards");
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        if (err.response.status === 400) {
          setErrors(err?.response?.data?.error_msg);
        }
        if (err.response.status === 422) {
          setErrors(err?.response?.data?.errors);
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  return (
    <div className="page-wrapper">
      <div className="content container">
        <div className="page-header">
          <div className="row align-items-center">
            {/* <div className="col">
              <h3 className="page-title mt-5 text-center">Update Award Details</h3>
            </div> */}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="card">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">
                    <h3>Update Award Details</h3>
                  </div>
                  <div className="p-2">
                    <Link to="/dashboard/awards" className="btn btn-primary">
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row mx-2">
                  <div className="col-lg-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row formtype">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Award Title</label>
                            <input
                              className="form-control"
                              required
                              onChange={handleUserInput}
                              value={userInput?.title}
                              name="title"
                              type="text"
                              placeholder="Award Title"
                            />
                            <span className="text-danger">{errors?.title}</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Start Date</label>
                            <div className="">
                              <input
                                type="datetime-local"
                                name="start_date"
                                value={userInput?.start_date}
                                onChange={handleUserInput}
                                required
                                className="form-control "
                              />
                              <span className="text-danger">
                                {errors?.start_date}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>End Date</label>
                            <div className="">
                              <input
                                type="datetime-local"
                                name="end_date"
                                value={userInput?.end_date}
                                onChange={handleUserInput}
                                min={getCurrentDate}
                                required
                                className="form-control "
                              />
                              <span className="text-danger">
                                {errors?.end_date}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Nomination Code Prefix</label>
                            <div className="">
                              <input
                                type="text"
                                name="award_prefix"
                                required
                                className="form-control"
                                value={userInput?.award_prefix}
                                onChange={handleUserInput}
                                maxLength="4"
                                placeholder="Eg. GMB (max-characters:4)"
                              />

                              <span className="text-danger">
                                {errors?.award_prefix}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Price Per Vote (GHC)</label>
                            <div className="">
                              <input
                                type="number"
                                name="price_per_vote"
                                required
                                className="form-control"
                                value={userInput?.price_per_vote}
                                onChange={handleUserInput}
                                maxLength="4"
                                step="any"
                                placeholder="min: 0.5"
                                min="0.5"
                                max="100"
                              />
                              <span className="text-danger">
                                {errors?.price_per_vote}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Upload Event Flyer</label>
                            <div className="">
                              <input
                                type="file"
                                name="image"
                                // required
                                className="form-control"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                              />
                              <span className="text-danger">
                                {errors?.image}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            style={{
                              border: "1px solid black",
                              maxWidth: "40%",
                              maxHeight: "150%",
                            }}
                          >
                            {selectedImage ? (
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="uploaded-file"
                                width="100%"
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_BACKEND_URL}/${userInput?.image}`}
                                alt="uploaded-file"
                                width="100%"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <Button
                          buttonText="Update Award"
                          isloading={isloading}
                          cssClasses="btn btn-primary form-control text-white buttonedit mt-2"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAward;
