import React, { useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../../components/customHooks/useFetch";
import LoadingPage from "../../LoadingPage";
import Card from "../partials/Card";
import SvgIcons from "../partials/SvgIcons";
import HomeAwardCard from "./awards/components/HomeAwardCard";
import Spinners from "../../../components/Spinners";

const Home = () => {
  const [reload, setReload] = useState(false);
  const { data } = useFetch("/api/dashboard/statistics");
  const { isloaded } = useFetch("/api/v2/transaction/award/22/stats");
  const { data: awards } = useFetch("/api/v2/awards/all", reload);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12 mt-5">
              <h3 className="page-title mt-3 text-capitalize">
                Welcome, {localStorage.getItem("name")} !
              </h3>
              <ul className="breadcrumb mt-3">
                <li className="breadcrumb-item active">Dashboard</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <Card
            title="My Awards"
            value={data?.award_count || "0"}
            svg={SvgIcons.awards}
          />
          <Card
            title="My Ticketing Events"
            value={data?.event_count || "0"}
            svg={SvgIcons.tickects}
          />
          <Card
            title="Wallet Balance"
            value={data?.wallet_balance || "0"}
            svg={SvgIcons.wallet}
          />
        </div>

        <div className="row">
          <div className="col-md-12 d-flex">
            {!isloaded ? (
              <LoadingPage />
            ) : (
              <div className="content container-fluid card">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="mt-1">
                        <h4 className="card-title float-left mt-1">
                          My Awards
                        </h4>
                        <Link
                          to="/dashboard/award/create"
                          className="btn btn-primary float-right veiwbutton mt-5"
                        >
                          Add New Award
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {isloaded ? (
                    <div className="col-sm-12">
                      {awards?.length > 0 ? (
                        <>
                          <div className="container-fluid">
                            <div className="row">
                              {awards?.map((award, index) => (
                                <HomeAwardCard
                                  key={index}
                                  award={award}
                                  reload={reload}
                                  setReload={setReload}
                                />
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        <NoAwards />
                      )}
                    </div>
                  ) : (
                    <div className="bg-default">
                      <h2 className="text-white text-center">
                        {Spinners.rotatingLines} Loading... Please Wait...
                      </h2>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

const NoAwards = () => {
  return (
    <section className=" py-3">
      <div className="text-center">
        <h2 className="mt-3">No Awards Available</h2>
        <div className="mt-3 text-center">
          <Link to="/dashboard/award/create" className="btn btn-primary">
            Create New Award
          </Link>
        </div>
      </div>
    </section>
  );
};
