import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../../components/customHooks/useFetch";
import Spinners from "../../../../components/Spinners";
import ResultCard from "./components/ResultCard";

const ResultAwards = () => {
  const [reload,setReload]=useState(false);

  const { data: awards, isloaded, errors } = useFetch("/api/v2/awards/all",reload);
  const navigate = useNavigate();
  errors &&
    Swal.fire({
      title: "Failed",
      text: "Failed to load available categories",
      icon: "error",
    }).then((_) => {
      navigate(-1);
    });

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5"></div>
            </div>
          </div>
        </div>
        <div className="row">
          {isloaded ? (
            <div className="col-sm-12">
              {awards?.length > 0 ? (
                <>
                  <section className="pricing py-5">
                    <h2
                      className="text-white text-center"
                      style={{ marginTop: "-3%" }}
                    >
                      Select Award To View Results
                    </h2>

                    <div className="container">
                      <div className="row mt-5">
                        {awards?.map((award, index) => (
                          <ResultCard key={index} award={award} url={`/dashboard/award/${award?.id}/results/categories`} reload={reload} setReload={setReload}/>
                        ))}
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <NoAwards />
              )}
            </div>
          ) : (
            <div className="bg-default">
              <h2 className="text-white text-center">
                {Spinners.rotatingLines} Loading... Please Wait...
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NoAwards = () => {
  return (
    <section className=" py-3">
      <div className="text-center">
        <h2 className="mt-3">No Awards Available</h2>
        <div className="mt-3 text-center">
          <Link to="/dashboard/award/create" className="btn btn-primary">
            Create New Award
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ResultAwards;
