import React, { useRef, useState, useEffect } from "react";
import Button from "../../../../components/Button";
import Swal from "sweetalert2";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";

const EditNominee = () => {
  const { id } = useParams(); //nominee id
  const ref = useRef();
  const [isloading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const [errors, setErrors] = useState();
  const [userInput, setUserInput] = useState([]);
  const [ready,setReady]=useState(false);

  useEffect(() => {
    axios
      .get(`/api/v2/nominees/${id}`)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setUserInput(res.data.data);
          setReady(!ready)
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
        if (err?.response?.data?.status === 400) {
          const msg =
            err?.response?.data?.error_msg?.award ||
            "This award has already ended, kindly contact our support team for assistance/support";
          Swal.fire("Failed", msg, "info").then(()=>{navigate('*')});
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    axios.get(`/api/v2/awards/${userInput?.award_id}`).then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setCategories(res.data?.data?.categories);
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.category_id === "") {
      window.alert("Please Select Nominee Category");
    } else {
      setLoading(true);
      var data = new FormData();
      data.append("nominee_code", userInput.nominee_code);
      data.append("nominee_status", userInput.nominee_status);
      data.append("full_name", userInput.full_name);
      userInput?.stage_name &&  data.append("stage_name", userInput.stage_name);
      data.append("gender", userInput.gender);
      userInput?.phone && data.append("phone", userInput.phone);
      data.append("award_id", userInput.award_id);
      data.append("category_id", userInput.category_id);
      selectedImage && data.append("image", selectedImage);

      axios
        .post(`/api/v2/nominees/update/${id}`, data)
        .then((res) => {
          if (res.data.status === 200 && res.data.success === true) {
            setLoading(false);
            let msg = res.data.msg;
            Swal.fire({
              title: "Success",
              text: msg,
              icon: "success",
              confirmButtonColor: "#011535",
              confirmButtonText: "Okay",
            }).then(() => {
              navigate(`/dashboard/award/${userInput.award_id}/nominees`);
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          setLoading(false);
          if (err.response.status === 422) {
            setErrors(err?.response?.data?.errors);
          }
          if (err.response.status === 401) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="page-wrapper">
      <div className="content container">
        <div className="page-header"></div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="card card-shadow">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">
                    <h3>Update Nominee Details</h3>
                  </div>
                  <div className="p-2">
                    <Link to={`/dashboard/award/${userInput?.award_id}/nominees`} className="btn btn-primary">
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row formtype">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Full Name *</label>
                              <input
                                className="form-control"
                                type="text"
                                value={userInput?.full_name}
                                name="full_name"
                                onChange={handleUserInput}
                                required
                              />

                              <small className="text-danger">
                                {errors?.full_name}
                              </small>
                            </div>
                          </div>

                          <div className="col-md-6 ">
                            <div className="form-group">
                              <label>Select Category</label>
                              <select
                                className="form-control"
                                value={userInput?.category_id}
                                name="category_id"
                                onChange={handleUserInput}
                              >
                                <option value={""}>--Select Category--</option>
                                {categories?.map((item, index) => (
                                  <option
                                    key={index}
                                    className="text-capitalize"
                                    value={item?.id}
                                  >
                                    {item?.title}
                                  </option>
                                ))}
                              </select>
                              <div className="text-danger">
                                {errors?.category_id}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="form-group">
                              <label>Gender*</label>
                              <select
                                className="form-control"
                                ref={ref}
                                id="sel2"
                                name="gender"
                                value={userInput?.gender}
                                onChange={handleUserInput}
                                required
                              >
                                <option value="">--Select Gender--</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                              <small className="text-danger">
                                {errors?.gender}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="form-group">
                              <label>Stage Name (optional)</label>
                              <input
                                className="form-control"
                                type="text"
                                value={userInput?.stage_name}
                                name="stage_name"
                                onChange={handleUserInput}
                              />

                              <small className="text-danger">
                                {errors?.stage_name}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Phone Number (Optional)</label>
                              <input
                                type="text"
                                className="form-control datetimepicker"
                                name="phone"
                                value={userInput?.phone}
                                onChange={handleUserInput}
                              />
                              <small className="text-danger">
                                {errors?.phone}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Nominee Status</label>
                              <select
                                className="form-control"
                                required
                                value={userInput?.nominee_status}
                                name={"nominee_status"}
                                onChange={handleUserInput}
                              >
                                <option value="active">Active</option>
                                <option value="blocked">Blocked</option>
                              </select>
                              <small className="text-danger">
                                {errors?.nominee_status}
                              </small>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Nominee Code (Optional)</label>
                              <input
                                type="text"
                                name="nominee_code"
                                value={userInput?.nominee_code}
                                onChange={handleUserInput}
                                maxLength={4}
                                className="form-control"
                              />
                              <small className="text-danger">
                                {errors?.nominee_code}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="form-group">
                              <label>Upload Image (optional)</label>
                              <div className=" mb-3">
                                <input
                                  type="file"
                                  name="image"
                                  className="form-control"
                                  onChange={(e) =>
                                    setSelectedImage(e.target.files[0])
                                  }
                                />
                              </div>
                              <div className="text-danger">{errors?.image}</div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                          {selectedImage ? (
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="uploaded-file"
                                width="50%"
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_BACKEND_URL}/${userInput?.image}`}
                                alt="uploaded-file"
                                width="50%"
                              />
                            )}
                          </div>
                          <Button
                            buttonText={"Update Nominee Details"}
                            isloading={isloading}
                            cssClasses="btn btn-primary bg-default buttonedit1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNominee;
