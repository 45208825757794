import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";
import useFetch from "../../../../components/customHooks/useFetch";
import { getCurrentDate } from "../../../../components/helpers/getCurrentDate";

const AwardWithdrawal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [responseErrors, setErrors] = useState({});
  const [userInput, setUserInput] = useState({
    network: "",
    amount: 0,
    from: getCurrentDate("-"),
    to: getCurrentDate("-"),
    total_amount: 0,
    withdrawal_charge: 0,
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "amount") {
      const inputValue = Number(e.target.value);
      const twoPercent = 0.02 * inputValue;
      // const totalCharge = twoPercent + inputValue;
      const totalCharge = inputValue;

      setUserInput((userInput) => ({
        ...userInput,
        total_amount: totalCharge,
        withdrawal_charge: twoPercent,
      }));
    }
  };

  const { data, errors } = useFetch(
    `/api/v2/transaction/award/${id}?from=${userInput?.from}&to=${userInput?.to}`
  );
  if (errors?.data?.status === 400) {
    Swal.fire("Not Found", "Award Not Found", "info").then((_) => {
      navigate(-2);
    });
  }

  const handleWithdrawal = (e) => {
    e.preventDefault();
    setErrors(null);
    Swal.fire({
      title: "Question",
      text: `Are you sure to proceed on with withdrawal amount of GHS ${Number(
        userInput?.amount
      ).toFixed(2)} to momo number ${data?.phone}?`,
      icon: "question",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showDenyButton: true,
      denyButtonText: "No",
    }).then((response) => {
      if (response.isConfirmed) {
        setIsLoading(true);
        axios
          .post("/api/v2/transaction/withdraw", {
            network: userInput?.network,
            amount: userInput?.total_amount,
            award_id: id,
          })
          .then((res) => {
            setIsLoading(false);
            if (res?.data?.status === 200) {
              Swal.fire(
                "Processing",
                "Withdrawal Transaction is Being processed",
                "info"
              ).then((_) => {
                navigate(-1);
              });
            }
          })
          .catch((error) => {
            console.log(error?.response);
            setIsLoading(false);
            if (error?.response?.data?.status === 400) {
              setErrors(error?.response?.data?.error_msg);
            }
          });
      }
    });
  };

  return (
    <div className="page-wrapper">
      <div className="content container">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-6 mb-5 mt-4">
            <div className="card">
              <div className="card-header">
                <h3 className="text-center">Wallet Withdrawal</h3>
              </div>
              <div className="card-body p-4">
                <div className="form-group">
                  <div className="form-group text-center">
                    <label htmlFor="">
                      Momo Number: <b>{data?.phone}</b>
                    </label>{" "}
                    <br />
                    <label htmlFor="">
                      Award Balance: <b>GHS {data?.award_balance}</b>
                    </label>
                  </div>
                </div>
                <form onSubmit={handleWithdrawal}>
                  <div className="">
                    <label>Network</label>
                    <select
                      className="form-control"
                      required
                      // id="sel2"
                      name="network"
                      value={userInput?.network}
                      onChange={handleUserInput}
                    >
                      <option value="">Choose Network</option>
                      <option value="MTN-GH">MTN-GH</option>
                      <option value="VODAFONE-GH">VODAFONE-GH</option>
                      <option value="TIGO-GH">TIGO-GH</option>
                      <option value="AIRTEL-GH">AIRTEL-GH</option>
                    </select>
                    <small className="text-danger">
                      {responseErrors?.network}
                    </small>
                    <br />
                  </div>
                  <div className="form-group">
                    <label htmlFor="amount">Amount(Processing Fee (0%))</label>{" "}
                    <br />
                    <input
                      type="number"
                      name="amount"
                      value={userInput?.amount}
                      onChange={handleUserInput}
                      min="200"
                      required
                      max={Number(data?.award_balance)}
                      step="any"
                      placeholder="0.00"
                      className="form-control"
                    />
                    {responseErrors?.amount && (
                      <>
                        <small className="text-danger">
                          {responseErrors?.amount}
                        </small>
                        <br />
                      </>
                    )}
                    {responseErrors?.award_id && (
                      <>
                        <small className="text-danger">
                          {responseErrors?.award_id}
                        </small>
                        <br />
                      </>
                    )}
                    {userInput?.total_amount>data?.award_balance && (
                      <>
                        <small className="text-danger">
                          Total withdrawal Amount can't be greater than your wallet balance GHS ({data?.award_balance})
                        </small>
                        <br />
                      </>
                    )}
                    {userInput?.total_amount<Number(200) && (
                      <>
                        <small className="text-danger">
                          Total withdrawal Amount must not be less than GHS (200.00)
                        </small>
                        <br />
                      </>
                    )}
                    <small className="text-secondary">
                      Processing Fee (0.00)
                    </small>
                    <br />
                    <small className="text-secondary">
                      Total Withdrawal Amount (
                      {Number(userInput?.total_amount).toFixed(2)})
                    </small>
                  </div>

                  {userInput?.total_amount >= 200 ? (
                    userInput?.total_amount <= Number(data?.award_balance) ? (
                      <Button
                        cssClasses={"btn btn-primary btn-block-w100"}
                        buttonText={`Withdraw GHS ${Number(userInput?.total_amount||0).toFixed(2)}`}
                        isloading={isloading}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </form>
                <div className="mt-3">
                  <h5 className="text-center">
                    Withdrawal Instructions
                    <hr />
                  </h5>
                  <ul>
                    <li>Minimum Withdrawal Balance is GHS 200.00</li>
                    <li>
                      Withdrawals are sent to the momo number used during
                      registration ({data?.phone})
                    </li>
                    <li>
                      Withdrawals Amount must be less than or equal to award
                      balance
                    </li>
                    <li>Withdrawals can take up to 24hours to be recieved</li>
                    <li>
                      Contact 0558571228 if you have issues making withdrawal
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardWithdrawal;
