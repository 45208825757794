import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Nav from "../partials/Nav";
import Timer from "./components/Timer";
import DirectionButton from "./components/DirectionButton";
import TicketCart from "./components/TicketCart";
import useFetch from "../../../components/customHooks/useFetch";
import ShareEventModal from "./components/ShareEventModal";
import RetrieveTicket from "./components/RetrieveTicket";
import Footer from "../partials/Footer";
import LoadingPage from "../../LoadingPage";
import convertDate from "../../../components/helpers/ConverDate";
import Button from "../../../components/Button";
import TicketPurchasForm from "./components/TicketPurchasForm";
import PaymentModal from "./components/PaymentModal";
import Map from "../../../components/Map";
import HelmetData from "../../../components/HelmetData";
import { isValidPhoneNumber } from "react-phone-number-input";
import routes from "../../../components/helpers/routes";


function TicketPayment() {
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [momoNumber, setMomoNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("momo");
  const { id } = useParams();
  const [errors, setErrors] = useState();
  const [ticketCart, setTicketCart] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();
  const totalTicketCost = ticketCart
    ?.map((ticket) => ticket?.amount)
    .reduce((a, b) => a + b, 0);
  const {
    data: event,
    isloaded,
    errors: loadingError,
  } = useFetch(`/api/v2/guest/ticket/${id}`);
  if (loadingError?.status === 400) {
    const message = loadingError?.data?.error_msg;
    Swal.fire({
      title: "Failed",
      text: message,
      icon: "info",
      confirmButtonColor: "#011535",
      confirmButtonText: "Okay",
    }).then((_) => {
      navigate("*");
    });
  }
  const [userInput, setUserInput] = useState({
    name: "",
    phone: "",
    email: "",
    quatity: "",
    ticket_id: "",
    payment_mode: "",
    momo_number: "",
    network: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));

    if (errors?.param) {
      setErrors((errors) => ({
        ...errors,
        [e.target.name]: "",
      }));
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
  
 
  const handleAddTicketToCart = (e) => {
    e.preventDefault();
  
    const { name, email, ticket_id, quantity } = userInput;
  
    if (!isValidPhoneNumber(phoneNumber)) {
      window.alert("Please enter a valid phone number");
    } else if (email.trim() && !isValidEmail(email)) {
      window.alert("Please enter a valid email address");
    } else if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      window.alert("Please enter a valid phone number");
    } else {
      const selectedTicket = event.tickets.find(
        (ticket) => Number(ticket.id) === Number(ticket_id)
      );
      const price = selectedTicket?.price ?? 0;
      const amount = price * quantity;
      const service_charge = (1 / 100) * amount;
  
      const newTicket = {
        id: ticketCart.length + 1,
        name,
        phone:phoneNumber,
        email,
        quantity,
        ticket_id,
        amount,
        service_charge: service_charge.toFixed(2),
      };
  
      setTicketCart([...ticketCart, newTicket]);
      setPhoneNumber("");
      setUserInput({
        name: "",
        phone: "",
        email: "",
        quantity: "",
        ticket_id: "",
      });
    }
  };

  const removeItemFromCart = (id) => {
    const filteredData = ticketCart.filter(
      (item) => Number(item.id) !== Number(id)
    );
    setTicketCart(filteredData);
  };

  const handlePayment = (e) => {
    e.preventDefault();
    if (
      (momoNumber === "233" || momoNumber === "" || momoNumber.length < 12) &&
      paymentMethod === "momo"
    ) {
      window.alert("please Enter a valid phone number");
    } else {
      setLoading(true);
      axios
        .post("/api/v2/guest/ticket/payment", {
          payment_mode: paymentMethod,
          momo_number: momoNumber || "",
          network: userInput?.network || "",
          event_code: id,
          tickets: ticketCart,
        })
        .then((res) => {
          setLoading(false);
          if (paymentMethod === "momo" || res?.data?.status === 200) {
            Swal.fire({
              title: "Approval Required",
              icon: "info",
              text: "To Complete Payment:You'll Recieve A Prompt To Enter Your Momo Pin, if MTN Prompt Delays, Dial *170#, Select Option 6, And Then Option 3 To Approve This Transaction.",
            }).then((_) => {
              navigate(routes.TICKET_PAGE);
            });
          }

          if (paymentMethod === "card") {
            window.location.replace(res?.data?.data?.authorization_url);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err?.response);
          if (err?.response?.status === 400) {
            let message = err?.response?.data?.error_msg;
            Swal.fire({
              title: "Failed",
              text: message,
              icon: "info",
              confirmButtonColor: "#011535",
              confirmButtonText: "Okay",
            });
          } else if (err?.response?.status === 422) {
            let message = err?.response?.data?.message;
            Swal.fire({
              title: "Failed",
              text: message,
              icon: "info",
              confirmButtonColor: "#011535",
              confirmButtonText: "Okay",
            });
          }
        });
    }
  };

  return isloaded ? (
    <>
      <HelmetData
        title={event?.title}
        imageSrc={`${process.env.REACT_APP_BACKEND_URL}/${event?.image}`}
        description={event?.description}
        url={`https://opencastgh.com/event/${event?.code}`}
      />
      <Nav />
      <div className="award-div1 d-flex">
        <div className="award-img-bg"></div>
        <div className="award-img-title2">
          <h1 className="text-white text-capitalize">{event?.title}</h1>
        </div>
      </div>
      <div className="advert-margin">
        <div className="col-lg-12">
          <div className="row row-mg-top mb-lg-3 mb-1">
            <div className="col-lg-4 col-md-4"></div>
            <div className="col-lg-8 col-md-8 col-12">
              <div className="row justify-content-between">
                {/* <Timer date={event?.start_date}/> */}
                <Timer date={event?.start_date} enddate={event?.end_date} />
                <div className="col-md-7 col-lg-7">
                  <div className="row my-2">
                    <div className="col-lg-6 col-md-6 col-6">
                      <DirectionButton
                        event={event}
                        btnClass={"btn-outline-light"}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <a
                        data-toggle="modal"
                        href="#share-modal"
                        className="btn btn-outline-light w-100 card-border-radius text-nowrap"
                      >
                        Share This Event
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-shadow card-border-radius card-overflow des-h">
                    <div className="card-body">
                      <img
                        src={`${process.env.REACT_APP_BACKEND_URL}/${event?.image}`}
                        alt="arrow"
                        className="nom-height"
                      />
                      <div className="col-md-12 col-lg-12 col-12 mt-3">
                        <a
                          data-toggle="modal"
                          href="#retrieve-modal"
                          className="btn btn-primary w-100"
                        >
                          Retrieve Your Ticket Code
                        </a>
                      </div>
                      <div className="col-md-12 col-lg-12 col-12 mt-2">
                        <div className="card-title">
                          <div className="mb-3">
                            <h4>
                              Event Date :{" "}
                              <span>{convertDate(event?.start_date)}</span>
                            </h4>
                          </div>
                          <div className="mb-2 d-flex">
                            <h4>
                              Description: <span>{event?.description}</span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 d-none d-md-block">
                  <div className="card card-shadow card-border-radius card-overflow p-2 card-height">
                    <div className="card-body ">
                      <Map lat={Number(event?.lat)} lng={Number(event?.long)} />
                      <div className="mt-4">
                        <DirectionButton
                          event={event}
                          btnClass={"btn-outline-primary"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-12">
              <TicketPurchasForm
                userInput={userInput}
                handleUserInput={handleUserInput}
                isloading={isloading}
                addTicketToCart={handleAddTicketToCart}
                errors={errors}
                tickets={event?.tickets}
                setLoading={setLoading}
                ticketCart={ticketCart}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    {ticketCart?.length > 0 && (
                      <h4 className="mt-2 total-font">
                        Total: GHS {totalTicketCost?.toFixed(2)}
                      </h4>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  {!isloading ? (
                    ticketCart?.length > 0 && (
                      <a
                        data-toggle="modal"
                        href="#payment-modal"
                        className="btn btn-outline-primary float-end btn-size"
                      >
                        Proceed To Payment
                      </a>
                    )
                  ) : (
                    <Button
                      cssClasses={"btn btn-outline-primary float-end btn-size"}
                      isloading={isloading}
                    />
                  )}
                </div>
              </div>
              <TicketCart
                tickets={ticketCart}
                removeItemFromCart={removeItemFromCart}
              />
            </div>
            <div className="col-12 d-block d-md-none">
              <div className="card card-shadow card-border-radius card-overflow p-2 card-height">
                <div className="card-body ">
                  <Map lat={Number(event?.lat)} lng={Number(event?.long)} />
                  <div className="mt-4">
                    <button
                      onClick={() =>
                        (window.location.href = `https://maps.google.com/maps?q=${event?.lat},${event?.long}`)
                      }
                      target={"_blank"}
                      className="btn btn-outline-primary w-100"
                    >
                      Get Directions
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <ShareEventModal event={event} />

            <RetrieveTicket />
            <PaymentModal
              momoNumber={momoNumber}
              setMomoNumber={setMomoNumber}
              setLoading={setLoading}
              userInput={userInput}
              handleUserInput={handleUserInput}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              handlePayment={handlePayment}
              isloading={isloading}
            />
          </div>
        </div>
      </div>

      <Footer />
    </>
  ) : (
    <LoadingPage />
  );
}

export default TicketPayment;
