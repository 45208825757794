import { useState } from "react"
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import routes from "../../../components/helpers/routes";

const Nav = () => {
    const [clicked, setClicked] = useState(false);
    const handleClick = () =>{
        setClicked(!clicked)
    }
    const [navbar, setNavbar] = useState(false);

    const changeBackground =()=>{
        if (window.scrollY >= 400) {
            setNavbar(true)
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener("scroll", changeBackground)

    const showContactInfo = () => {
        Swal.fire({
          title: "Contact Information",
          html: `
                  <p><strong>Name:</strong>Samasoft Technologies</p>
                  <p><strong>Email:</strong> info@opencastgh.com</p>
                  <p><strong>Website:</strong> https://samasoftech.net</p>
                  <p><strong>Phone:</strong> (+233) 558-571228 / (+233) 554-922935</p>
                  <hr />
                  <p><strong>Follow us on:</strong></p>
                  <div classname="d-flex flex-row justify-content-center">
                    <a href="https://www.facebook.com/profile.php?id=100083505109917&mibextid=ZbWKwL" target="_blank">
                        <i className="fa fa-facebook-square"></i> Facebook
                    </a>
                    
                    <a href="https://www.instagram.com/opencastgh" target="_blank">
                        <i className="fa fa-instagram"></i> Instagram
                    </a>
                    
                    <a href="https://twitter.com/SAMZY90234896" target="_blank">
                        <i className="fa fa-twitter-square"></i>&nbsp; Twitter
                    </a>
                  </div>
                `,
          showCancelButton: true,
          confirmButtonText: "Close",
        });
      };

    return (
        <nav className={navbar ? 'nav-bar fixed-top active' : 'nav-bar fixed-top'}>
            <a href={()=>{}} className="my-auto"><img src="/assets/images/logo2.png" alt="Icon"/></a>
            <div>
                <ul id="nav-bar-links" className={clicked ? "#nav-bar-links active my-auto" : "#nav-bar-link my-auto"}>
                    <li className="active"><Link to="/">Home</Link></li>
                    <li><Link to="/nominations">Nominations</Link></li>
                    <li><Link to={routes.AWARDS_PAGE}>Awards</Link></li>
                    <li><Link to={routes.TICKET_PAGE}>Tickets</Link></li>
                    <li><Link to={routes.GUEST_RESULTS_PAGE}>Results</Link></li>
                    <li><a href={()=>{}} onClick={showContactInfo} className='text-nowrap'>Contact Us</a></li>
                    <li><Link to={routes.LOGIN}>Login</Link></li>
                </ul>
            </div>
            <div className="mobile" onClick={handleClick}>
                <i id="bar" className={clicked ? "fas fa-times":"fas fa-bars"}></i>
            </div>
        </nav>
    )
}
export default Nav