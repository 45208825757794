import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePostFetch from "../../../../../../components/customHooks/usePostFetch";
import Loader from "../../../../../../components/helpers/Loader";
import Card from "../../../../partials/Card";
import SvgIcons from "../../../../partials/SvgIcons";
import AttendanceTable from "./partials/AttendanceTable";

const ManageAttendance = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [reloadTable, setReloadTable] = useState(false);
  const { data: attendees, isloaded } = usePostFetch(
    `/api/v2/events/${id}/attendees`,
    "",
    reloadTable
  );

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="col-lg-12 d-flex justify-content-between mt-5">
            <div className="">
              <h3 className="card-title mt-2 text-uppercase">
                {attendees?.event?.title} Dashboard
              </h3>
            </div>
            <div className="px-2">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-secondary float-right"
              >
                Back
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <Card
            title="Total Attendees"
            value={attendees?.total_attendees || "0"}
            svg={SvgIcons.awards}
          />
          <Card
            title="Attendees Verified"
            value={attendees?.total_verified || "0"}
            svg={SvgIcons.tickects}
          />
          <Card
            title="Pending Verification"
            value={attendees?.pending_verification || "0"}
            svg={SvgIcons.wallet}
          />
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table ">
              <div className="card-body booking_card">
                {isloaded ? (
                  <div className="table-responsive">
                    <AttendanceTable
                      attendees={attendees?.event?.attendees}
                      reloadTable={reloadTable}
                      setReloadTable={setReloadTable}
                    />
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAttendance;
