import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./styles/authDesktop.css";
import "./styles/authMobile.css";
import Spinners from "../../components/Spinners";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

const Login = () => {
  const navigate = useNavigate();

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      axios
        .get("api/user")
        .then((res) => {
          navigate("/dashboard");
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  }, [navigate]);

  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
    errors: [],
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let data = {
      email: userInput.email,
      password: userInput.password,
    };
    axios
      .post("/api/v2/auth/login", data)
      .then((res) => {
        localStorage.setItem("token", res?.data?.data?.token);
        localStorage.setItem("code", res?.data?.data?.user?.code);
        localStorage.setItem(
          "associate_code",
          res?.data?.data?.user?.associate_code
        );
        localStorage.setItem("role", res?.data?.data?.user?.role);
        localStorage.setItem("name", res?.data?.data?.user?.name);
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        if (
          err.response.data.status === 400 &&
          err.response.data.success === false
        ) {
          setUserInput((userInput) => ({
            ...userInput,
            errors: err?.response?.data?.error_msg,
          }));
        }
      });
  };

  return (
    <div className="container container-position">
      <div className="row d-flex">
        <div className="col-lg-6 pad-right">
          <div className="illustration">
            <div className="illust-text">
              <h4>
                Opencast<span>GH</span>
              </h4>
            </div>
            <div className="illust-img">
              <img
                src="/assets/img/C.png"
                alt="opencastgh"
                className="img-fluid"
              />
            </div>
            <div className="illus-img">
              <img
                src="/assets/img/service.png"
                alt="opencastgh"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 pad-left">
          <div className="container-wrapper">
            <form onSubmit={handleSubmit}>
              <div className="p-lg-4 d-flex flex-column h-100 justify-content-center">
                <h1 className="text-center my-5">Login</h1>
                <div className="right d-flex flex-row position-relative">
                  <input
                    type="email"
                    className="flex-fill email"
                    name="email"
                    value={userInput.email}
                    onChange={handleUserInput}
                    placeholder="Email"
                    required
                    autoComplete="email"
                  />
                  <i className="far fa-envelope email position-absolute"></i>
                </div>
                <span className="text-danger text-center mt-1" role="alert">
                  {userInput?.errors?.email}
                </span>
                <div className="left d-flex flex-row position-relative mt-4">
                  <input
                    type={type}
                    className="flex-fill password"
                    name="password"
                    value={userInput?.password}
                    onChange={handleUserInput}
                    required
                    autoComplete="current-password"
                    placeholder="Password"
                  />
                  <span onClick={handleToggle}>
                    <Icon
                      icon={icon}
                      size={18}
                      className="view-password-icon1"
                    />
                  </span>
                  <i className="fas fa-lock password position-absolute"></i>
                </div>
                <span className="text-danger text-center mt-0" role="alert">
                  {userInput?.errors?.password}
                </span>
                <div className="d-flex flex-row justify-content-center mt-4">
                  <button
                    className="submit"
                    type={!loading ? "submit" : "button"}
                  >
                    {!loading ? (
                      "Submit"
                    ) : (
                      <>{Spinners.rotatingLines} Please Wait</>
                    )}
                  </button>
                </div>
                <div className="d-flex flex-row justify-content-center mt-5">
                  <Link to="/signup" className="action w-50 text-center">
                    {" "}
                    Sign Up Now
                  </Link>
                </div>
                <div className="d-flex flex-row justify-content-center mt-2">
                  <Link
                    to="/forgot-password"
                    className="action w-50 text-center"
                  >
                    Forgot Password
                  </Link>
                </div>
                <div className="d-flex flex-row justify-content-center mt-2">
                  <a
                    href="https://opencastgh.com"
                    className="action w-50 text-center"
                  >
                    Back To Homepage
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
