import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SkeletonPlaceholder from "../../../../components/SkeletonPlaceholder";
import EventCard from "./components/EventCard";

const OngoingEvents = ({ events, isloaded }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 600,
    arrows: false,
  };

  const sliderRef = useRef(null);

  return (
    <div className="col-lg-12">
      <div className="advert-margin mb-md-3 mt-md-5 mt-3">
        <div className="row">
          <div className="d-flex justify-content-between mb-2">
            <div className="text-margin-start12">
              <h3>Featured Events</h3>
            </div>
            <div>
              <img
                src="/assets/images/arrow-left1.png"
                alt="arrow"
                onClick={() => sliderRef.current.slickPrev()}
                className="cur-pointer cur-width"
              />
              <img
                src="/assets/images/arrow-right1.png"
                alt="arrow"
                onClick={() => sliderRef.current.slickNext()}
                className="ms-3 cur-pointer cur-width"
              />
            </div>
          </div>
        </div>
        <section className="slider variable">
          <div className="row">
            {isloaded ? (
              <Slider ref={sliderRef} {...settings}>
                {events?.map((event, i) => {
                  return (
                    <div
                      className="col-custom-2 col-md-3 col-6"
                      key={i}
                      id="custom-col"
                    >
                      <Link to={`/event/${event?.code}`} key={i}>
                        <div className="card-h-2-width">
                          <EventCard event={event} />
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              Array(5)
                .fill("")
                .map((_, i) => (
                  <div
                    className="col-custom-2 col-md-3 col-6"
                    key={i}
                    id="custom-col"
                  >
                    <SkeletonPlaceholder />
                  </div>
                ))
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
export default OngoingEvents;
