import axios from "axios";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";

const ResetVerificationForm = ({ phone, setCurrent,setToken }) => {
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const box1 = useRef("");
  const box2 = useRef("");
  const box3 = useRef("");
  const box4 = useRef("");
  const box5 = useRef("");
  const box6 = useRef("");
  

  useEffect(() => {
    box1.current.focus();
  }, []);

  const [code, setCode] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
  });

  useEffect(() => {
    setVerificationCode(
      code.one + code.two + code.three + code.four + code.five + code.six
    );
  }, [code]);

  const handleNextFocus = (e) => {
    setErrorMessage("");
    setCode((code) => ({
      ...code,
      [e.target.id]: e.target.value.toString(),
    }));

    if (e.target.id === "one" && e.target.value !== "") {
      box2.current.focus();
    }
    if (e.target.id === "two" && e.target.value !== "") {
      box3.current.focus();
    }
    if (e.target.id === "three" && e.target.value !== "") {
      box4.current.focus();
    }
    if (e.target.id === "four" && e.target.value !== "") {
      box5.current.focus();
    }
    if (e.target.id === "five" && e.target.value !== "") {
      box6.current.focus();
    }
  };

  const handleCodeVerification = (e) => {
    setProcessing(true);
    if (verificationCode.length !== 6) {
      setProcessing(false);
      setErrorMessage("Invalid Verification Code Entered");
    } else {
      axios
        .post(`/api/v2/auth/verify-phone`, {
          phone: phone,
          code: verificationCode,
        })
        .then((res) => {
            setToken(res?.data?.data?.token);
            if(res?.data?.status===200){
                setCurrent(3);
                setProcessing(false);
            }
        })
        .catch((err) => {
          setProcessing(false);
          console.log(err.response.data);
          setErrorMessage("Invalid Verification Code Entered");
        });
    }
  };

  return (
    <div className="col-lg-12">
      <div className="mb-5">
        <Link to="/login">
          <img src="/assets/images/arrow-left1.png" loading="lazy" alt="arrow" />
        </Link>
      </div>
      <div>
        <h1 className="mb-5">Enter Verfication Code</h1>
      </div>
      <div>
        <h4 className="rec-text">
          We've sent a 6 digit verification code to your phone
        </h4>
      </div>
      <div className="font-enter">
        <h4 className="rec-text">
          Please enter the code below to verify it's you
        </h4>
      </div>
      <div className="form-group mt-4 d-flex">
        <input
          type="text"
          id="one"
          className="form-control textbox-size1"
          min={0}
          max={9}
          maxLength={1}
          ref={box1}
          value={code.one}
          onChange={handleNextFocus}
        />
        <input
          type="text"
          id="two"
          className="form-control textbox-size1 ms-2"
          maxLength="1"
          ref={box2}
          value={code.two}
          onChange={handleNextFocus}
        />
        <input
          type="text"
          id="three"
          className="form-control textbox-size1 ms-2"
          maxLength="1"
          ref={box3}
          value={code.three}
          onChange={handleNextFocus}
        />
        <input
          type="text"
          id="four"
          className="form-control textbox-size1 ms-2"
          maxLength="1"
          ref={box4}
          value={code.four}
          onChange={handleNextFocus}
        />
        <input
          type="text"
          id="five"
          className="form-control textbox-size1 ms-2"
          maxLength="1"
          ref={box5}
          value={code.five}
          onChange={handleNextFocus}
        />
        <input
          type="text"
          id="six"
          className="form-control textbox-size1 ms-2"
          maxLength="1"
          ref={box6}
          value={code.six}
          onChange={handleNextFocus}
        />
      </div>
      <small className="text-danger">{errorMessage}</small>
      <div className="row mt-5 p-0">
        <div className="d-flex justify-content-center">
          <Button
            buttonText={"Continue"}
            cssClasses={"submit text-center"}
            callback={handleCodeVerification}
            isloading={processing}
          />
        </div>
        {/* <div className="col-lg-6">
                    <div className="float-end">
                        <Button cssClasses={'submit'} isloading={isSubmitting} buttonText='Resend Code' callback={requestVerification} />
                    </div>
                </div> */}
        <div className="d-flex flex-row justify-content-center mt-4">
          <Link to="/login">Login Instead</Link>
        </div>
      </div>
    </div>
  );
};
export default ResetVerificationForm;
