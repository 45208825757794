import React from "react";

const EventCard = ({ event }) => {
  return (
    <div className="card card-h-2 me-3">
      <div className="card-img-cover2">
        <img
          src={`${process.env.REACT_APP_BACKEND_URL}/${event?.image}`}
          alt="award"
        />
      </div>
      <div className="award-title-text mt-md-2 mt-3 text-center" title="title">
        <h4 className="text-capitalize">{event?.title}</h4>
      </div>
      <div className="award-title-text1 mt-0 text-center">
        <h4>Active</h4>
      </div>
    </div>
  );
};

export default EventCard;
