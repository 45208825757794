import axios from "axios";
import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../../components/Button";
import useFetch from "../../../../../components/customHooks/useFetch";
import NewMap from "../../../../../components/NewMap";

const CreateEvent = () => {
  const navigate = useNavigate();
  const { data: categories } = useFetch("/api/v2/category/all");
  const [isloading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userInput, setUserInput] = useState({
    title: "",
    start_date: "",
    end_date: "",
    description: "",
    price_per_vote: "",
    venue: "",
    category: "",
    errors: [],
  });
  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    var hour = ("0" + date.getUTCHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    return `${date.getFullYear()}-${mnth}-${day} ${hour}:${minutes}:00`;
  }

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [location, setlocation] = useState({
    lng: "",
    lat: "",
    address: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("title", userInput.title);
    data.append("start_date", convertDate(userInput.start_date));
    data.append("end_date", convertDate(userInput.end_date));
    data.append("location", location?.address);
    data.append("lat", location?.lat);
    data.append("long", location?.lng);
    data.append("description", userInput.description);
    data.append("image", selectedImage);
    data.append("venue", userInput?.venue);
    data.append("category_id", userInput?.category);
    axios
      .post("/api/v2/events/create", data)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setLoading(false);
          let msg = res.data.msg;
          Swal.fire({
            title: "Success",
            text: msg,
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          }).then(() => {
            navigate(-1);
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        if (err.response.status === 422) {
          setUserInput((userInput) => ({
            ...userInput,
            errors: err?.response?.data?.errors,
          }));
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  const getMapLocation = (lngParam, latParam, address) => {
    setlocation((location) => ({
      ...location,
      lng: lngParam,
      lat: latParam,
      address: address,
    }));
  };

  return (
    <div className="page-wrapper">
      <div className="content container">
        <div className="page-header">
          <div className="row align-items-center"></div>
        </div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="card">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">
                    <h3>New Event Setup</h3>
                  </div>
                  <div className="p-2">
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-primary"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row mx-2">
                  <div className="col-lg-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row formtype">
                        <div className="col-md-12">
                          <div className="form-group">
                            <span>Event Title</span>
                            <input
                              className="form-control"
                              required
                              onChange={handleUserInput}
                              value={userInput.title}
                              name="title"
                              type="text"
                              placeholder="Event Title"
                            />
                            <small className="text-danger">
                              {userInput?.errors?.title}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <span>Event category</span>
                            <select
                              name="category"
                              value={userInput?.category}
                              onChange={handleUserInput}
                              className="form-control"
                            >
                              <option value="">
                                ---Select Event Category---
                              </option>
                              {categories?.map((category, index) => (
                                <option
                                  value={category?.id}
                                  key={index}
                                  className="text-capitalize"
                                >
                                  {category?.title}
                                </option>
                              ))}
                            </select>
                            <small className="text-danger">
                              {userInput?.errors?.category}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <span>Event Location</span>

                            <a data-toggle="modal" href="#location-modal">
                              <div className="form-group">
                                <input
                                  className="form-control frame-textboxes"
                                  type="text"
                                  placeholder="Event Location"
                                  value={location?.address}
                                  readOnly
                                  name="location"
                                  required
                                />
                                <small className="text-danger">
                                  {userInput?.errors?.address}
                                </small>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <span>Event Venue</span>
                            <div className="form-group">
                              <input
                                className="form-control frame-textboxes"
                                type="text"
                                placeholder="Event Venue"
                                value={userInput?.venue}
                                onChange={handleUserInput}
                                name="venue"
                                required
                              />
                              <small className="text-danger">
                                {userInput?.errors?.venue}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <span>Start Date</span>
                            <div className="">
                              <input
                                type="datetime-local"
                                name="start_date"
                                value={userInput.start_date}
                                onChange={handleUserInput}
                                required
                                className="form-control "
                                min="<?php echo date('Y-m-d')?>"
                              />
                              <small className="text-danger">
                                {userInput?.errors?.start_date}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <span>End Date</span>
                            <div className="">
                              <input
                                type="datetime-local"
                                name="end_date"
                                value={userInput?.end_date}
                                onChange={handleUserInput}
                                min="<?php echo date('Y-m-d')?>"
                                required
                                className="form-control "
                              />
                              <small className="text-danger">
                                {userInput?.errors?.end_date}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <span>Event Description</span>
                            <textarea
                              name="description"
                              value={userInput?.description}
                              onChange={handleUserInput}
                              id=""
                              cols="30"
                              rows="10"
                              className="form-control textarea-size"
                              placeholder="Event description"
                            ></textarea>
                            <small className="text-danger">
                              {userInput?.errors?.description}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <span>Upload Event Flyer</span>
                            <div className="">
                              <input
                                type="file"
                                name="image"
                                required
                                className="form-control"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                              />
                              <small className="text-danger">
                                {userInput?.errors?.image}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          {selectedImage && (
                            <div
                              style={{
                                border: "1px solid black",
                                maxWidth: "40%",
                                maxHeight: "150%",
                              }}
                            >
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="uploaded-file"
                                width="100%"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="modal fade"
                        id="location-modal"
                        aria-hidden="true"
                        role="dialog"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-lg"
                          role="document"
                        >
                          <div className="modal-content moa">
                            <div className="modal-body modal-padding">
                              <NewMap getMapLocation={getMapLocation} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <Button
                          buttonText="Create Event"
                          isloading={isloading}
                          cssClasses="btn btn-primary form-control text-white buttonedit mt-2"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
