import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Advertisement = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 1500,
    nextArrow: (
      <img
        src="/assets/images/arrow-right1.png"
        alt="arrow"
        className="arrow-right-pos slick-next"
      />
    ),
    prevArrow: (
      <img
        src="/assets/images/arrow-left1.png"
        alt="arrow"
        className="arrow-left-pos slick-next"
      />
    ),
  };

  return (
    <div className="col-lg-12 col-12">
      <div className="row advert-margin">
        <div className="mt-3 mb-5">
          <section className="slider variable">
            <Slider {...settings}>
              <SlickImage imgSrc="/assets/images/Banner5.webp" />
              <SlickImage imgSrc="/assets/images/AD1.webp" />
              <SlickImage imgSrc="/assets/images/advertise.webp" />
              <SlickImage imgSrc="/assets/images/TDBN.webp" />
            </Slider>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;

const SlickImage = ({ imgSrc }) => {
  return (
    <div>
      <img src={imgSrc} loading="lazy" width="100%" alt="slick" className="sli-img" />
    </div>
  );
};
