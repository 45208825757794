import React, { useEffect, useMemo, useState } from "react";
import useCountdown from "../../../../components/customHooks/useCountDown";

const Timer = ({ date, enddate }) => {
  const [timerDate,setTimerDate]=useState(date);
  const [days, hours, minutes, seconds] = useCountdown(timerDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const today = new Date();
  const start_date =useMemo(()=>{return new Date(date)},[date]);
  const end_date =useMemo(()=>{return new Date(enddate)},[enddate]);

  useEffect(() => {
    if (start_date <= today) {
      setTimerDate(end_date);
    }
  }, [seconds,start_date, end_date,today]);

  if (today > end_date) {
    return (
      <div className="col-md-5 col-lg-5 col-12 ">
        <div className="text-center text-danger">
          <h2>Event has Ended</h2>
        </div>
      </div>
    );
  }

  if (start_date > today) {
    return (
      <div className="col-md-5 col-lg-5 col-12 ">
        <div className="d-flex text-center justify-content-between timer-font">
          <TimerValue value={days} description={"Days"} />
          <div>
            <h4 className="text-white">:</h4>
          </div>
          <TimerValue value={hours} description={"Hours"} />
          <div>
            <h4 className="text-white">:</h4>
          </div>
          <TimerValue value={minutes} description={"Minutes"} />
          <div>
            <h4 className="text-white">:</h4>
          </div>
          <TimerValue value={seconds} description={"Seconds"} />
        </div>
      </div>
    );
  }

  // start_date <= today
  if(start_date <= today){
    return (
      <div className="col-md-5 col-lg-5 col-12 ">
        <div className="text-center text-warning">
          <h2>Event has Started</h2>
        </div>
      </div>
    );
  }
};

export default Timer;

const TimerValue = ({ value, description }) => {
  return (
    <div className="d-block">
      <h4 className="text-white">{value}</h4>
      <h5 className="text-white">{description}</h5>
    </div>
  );
};
