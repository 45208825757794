import { useNavigate, useParams } from "react-router-dom";
import usePostFetch from "../../../../../../components/customHooks/usePostFetch";
import { format } from "date-fns";
import { useState } from "react";
import Pagination from "../../../../../../components/Pagination";
import SvgIcons from "../../../../partials/SvgIcons";
import Card from "../../../../partials/Card";

const ViewTransactions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: transactions,
  } = usePostFetch(`/api/v2/events/${id}/transactions`);
  var curr = new Date();
  curr.setDate(curr.getDate() + 3);
  var date = curr.toISOString().substring(0, 10);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = transactions?.transactions?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2 text-uppercase">
                  {transactions?.award?.title || "Loading..."}
                </h4>{" "}
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-primary float-right veiwbutton"
                >
                  Back
                </button>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <Card
            title="Total Payment"
            value={transactions?.total_revenue || "0"}
            svg={SvgIcons.wallet}
          />
          <Card
            title="Total Withdrawals"
            value={transactions?.total_withdrawals || "0"}
            svg={SvgIcons.wallet}
          />
          <Card
            title="Total Balance"
            value={transactions?.event_balance || "0"}
            svg={SvgIcons.wallet}
          />
        </div>

        {transactions?.allow_withdrawal && (
          <div className="container mb-2">
            <div className="d-flex  justify-content-end align-items-right">
              <a
                href="{{ route('dashboard.withdrawal.index') }}"
                className="btn btn-success pull-end"
              >
                Make Withdrawal
              </a>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-hearder">
                <form className="mx-4 mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="">From</label>
                      <input
                        type="date"
                        defaultValue={
                          new Date(date).toISOString().split("T")[0]
                        }
                        className="form-control"
                        required
                        name="from"
                      />

                      {/* <div className="text-danger">Error Message</div> */}
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="">To</label>
                      <input
                        type="date"
                        defaultValue={
                          new Date(date).toISOString().split("T")[0]
                        }
                        className="form-control"
                        required
                        name="to"
                      />
                    </div>
                    <div className="col-md-2 mt-4">
                      <button className="btn btn-success mt-2" type="submit">
                        Load
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-body booking_card">
                <div className="table-responsive">
                  <table
                    id="example"
                    className="datatable table table-stripped table table-hover table-center mb-0"
                  >
                    <thead>
                      <tr>
                        <th>Ref#</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Payment Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts?.map((trans, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-uppercase">
                              {trans?.ref_code}
                            </td>
                            <td className="text-uppercase">
                              {trans?.payment_amount}
                            </td>
                            <td> {trans?.payment_status} </td>
                            <td className="text-capitalize">
                              {" "}
                              {trans?.transaction_type}
                            </td>
                            <td>
                              {" "}
                              {format(
                                new Date(trans?.updated_at),
                                "do MMM, yyyy h:m aaa"
                              )}{" "}
                            </td>
                            <td> {trans?.momo_number || "N/A"} </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagin-pos">
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={transactions?.transactions?.length}
                    paginate={paginate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTransactions;
