import React, { useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../../../components/customHooks/useFetch";
import Spinners from "../../../../components/Spinners";
import LoadingPage from "../../../LoadingPage";
import AwardCard from "./components/AwardCard";

const Awards = () => {
  const [reload, setReload] = useState(false);
  const { data: awards, isloaded } = useFetch("/api/v2/awards/all", reload);

  return isloaded ? (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2">Manage Awards</h4>
                <Link
                  to="/dashboard/award/create"
                  className="btn btn-primary float-right veiwbutton"
                >
                  Add New Award
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {isloaded ? (
            <div className="col-sm-12">
              {awards?.length > 0 ? (
                <>
                  <AwardInstructions />
                  <section className="pricing py-5">
                    <h2
                      className="text-white text-center"
                      style={{ marginTop: "-3%" }}
                    >
                      Available Awards
                    </h2>

                    <div className="container">
                      <div className="row mt-5">
                        {awards?.map((award, index) => (
                          <AwardCard
                            key={index}
                            award={award}
                            reload={reload}
                            setReload={setReload}
                          />
                        ))}
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <NoAwards />
              )}
            </div>
          ) : (
            <div className="bg-default">
              <h2 className="text-white text-center">
                {Spinners.rotatingLines} Loading... Please Wait...
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <LoadingPage />
  );
};

const NoAwards = () => {
  return (
    <section className=" py-3">
      <div className="text-center">
        <h2 className="mt-3">No Awards Available</h2>
        <div className="mt-3 text-center">
          <Link to="/dashboard/award/create" className="btn btn-primary">
            Create New Award
          </Link>
        </div>
      </div>
    </section>
  );
};

const AwardInstructions = () => {
  return (
    <div
      className="alert alert-success alert-dismissible fade show"
      role="alert"
    >
      <h4 className="alert-heading">Please Note!</h4>
      <p>
        <ul>
          <li>
            If your award is pending, kindly contact 0558571228 for review and
            approval
          </li>
          <li>
            For an award to go live for voting, kindly click on <b>Go Live</b>
          </li>
          <li>
            To end voting, kindly click on <b>End Voting</b>
          </li>
        </ul>
      </p>
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default Awards;
