import axios from "axios";
import React from "react";
import { Routes, Route } from "react-router-dom";
import routes from "./components/helpers/routes";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import Awards from "./pages/dashboard/admin/awards/Awards";
import CreateAward from "./pages/dashboard/admin/awards/CreateAward";
import EditAward from "./pages/dashboard/admin/awards/EditAward";
import Categories from "./pages/dashboard/admin/categories/Categories";
import CreateCategory from "./pages/dashboard/admin/categories/CreateCategory";
import EditCategory from "./pages/dashboard/admin/categories/EditCategory";
import Home from "./pages/dashboard/admin/Home";
import AddNominee from "./pages/dashboard/admin/nominees/AddNominee";
import AllNominees from "./pages/dashboard/admin/nominees/AllNominees";
import EditNominee from "./pages/dashboard/admin/nominees/EditNominee";
import NomineesPage from "./pages/dashboard/admin/nominees/NomineesPage";
import DashboardLayout from "./pages/dashboard/partials/DashboardLayout";
import AwardsPage from "./pages/guest/home/award/AwardsPage";
import AwardCategory from "./pages/guest/home/award/AwardCategory";
// import AwardCategory from "./pages/guest/award/AwardCategory";
import HomePage from "./pages/guest/home/HomePage";
import VotePayment from "./pages/guest/home/award/VotePayment";
import NominationsPage from "./pages/guest/nomination/NominationPage";
import NominationsForm from "./pages/guest/nomination/NominationForm";
import TransactionAwards from "./pages/dashboard/admin/transactions/TransactionAwards";
import AwardTransactions from "./pages/dashboard/admin/transactions/AwardTransactions";
import ResultAwards from "./pages/dashboard/admin/results/ResultsAwards";
import ResultCategories from "./pages/dashboard/admin/results/ResultCategories";
import Results from "./pages/dashboard/admin/results/Results";
import ManageEvents from "./pages/dashboard/admin/ticketing/events/ManageEvents";
import CreateEvent from "./pages/dashboard/admin/ticketing/events/CreateEvent";
import ManageTickets from "./pages/dashboard/admin/ticketing/events/tickets/ManageTickets";
import CreateTicket from "./pages/dashboard/admin/ticketing/events/tickets/CreateTicket";
import EditTicket from "./pages/dashboard/admin/ticketing/events/tickets/EditTicket";
import EditEvent from "./pages/dashboard/admin/ticketing/events/EditEvent";
import LivePreview from "./pages/LivePreview";
import TicketingPage from "./pages/guest/home/ticketing/TicketingPage";
import ManageAttendance from "./pages/dashboard/admin/ticketing/events/tickets/ManageAttendance";
import ViewTransactions from "./pages/dashboard/admin/ticketing/events/tickets/ViewTransactions";
import ManageUsers from "./pages/dashboard/admin/ticketing/events/tickets/ManageUsers";
import CreateUsers from "./pages/dashboard/admin/ticketing/events/tickets/CreateUser";
import AttendeeTicket from "./pages/guest/ticketingSingleEvent/AttendeeTicket";
import LoadingPage from "./pages/LoadingPage";
import ErrorPage from "./pages/ErrorPage";
import TicketPayment from "./pages/guest/ticketingSingleEvent/TicketPayment";
import HelmetData from "./components/HelmetData";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResultsPage from "./pages/guest/results/ResultsPage";
import GuestResultCategories from "./pages/guest/results/GuestResultCategories";
import GuestResults from "./pages/guest/results/GuestResults";
import AwardWithdrawal from "./pages/dashboard/admin/withdrawal/AwardWithdrawal";
import BulkVotingPage from "./pages/dashboard/admin/bulk-voting/BulkVotingPage";
import AllBulkVotes from "./pages/dashboard/admin/bulk-voting/AllBulkVotes";
import AddBulkVoting from "./pages/dashboard/admin/bulk-voting/AddBulkVoting";
import Privacy from "./pages/others/Privacy";
import EditBulkVote from "./pages/dashboard/admin/bulk-voting/EditBulkVote";
import SuccessPage from "./pages/SuccessPage";
import FailedPage from "./pages/FailedPage";

function App() {
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  // axios.defaults.baseURL = "http://127.0.0.1:8000";
  // axios.defaults.baseURL = "https://api.opencastgh.com";
  axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;

  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return (
    <div>
      <HelmetData
        title={"OpenCastGH"}
        imageSrc="/logo192.png"
        description={
          "OpenCastGH an online awards voting and event ticketing platform"
        }
        url={`https://opencastgh.com/`}
      />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes?.SIGNUP} element={<SignUp />} />

        <Route path={routes.AWARDS_PAGE} element={<AwardsPage />} />
        <Route
          path={routes.GUEST_AWARD_CATEGORIES}
          element={<AwardCategory />}
        />
        <Route path={routes.GUEST_VOTE_PAYMENT} element={<VotePayment />} />
        <Route path={routes.GUEST_RESULTS_PAGE} element={<ResultsPage />} />
        <Route
          path={routes.GUEST_RESULTS_CATEGORIES}
          element={<GuestResultCategories />}
        />
        <Route path={routes.GUEST_RESULTS} element={<GuestResults />} />

        <Route path={routes.NOMINATION_PAGE} element={<NominationsPage />} />
        <Route path={routes.NOMINATION_FORM} element={<NominationsForm />} />

        <Route path={routes.TICKET_PAGE} element={<TicketingPage />} />
        <Route path={routes.TICKET_PAYMENT} element={<TicketPayment />} />
        <Route path={routes.ATTENDEE_TICKET} element={<AttendeeTicket />} />

        <Route path={routes.PRIVACY} element={<Privacy />} />

        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path={routes.DASHBOARD} element={<Home />} />
          <Route path={routes.MANAGE_AWARDS} element={<Awards />} />
          <Route path={routes.CREATE_AWARDS} element={<CreateAward />} />
          <Route path={routes.EDIT_AWARDS} element={<EditAward />} />
          <Route path={routes.VIEW_AWARD_CATEGORIES} element={<Categories />} />
          <Route
            path={routes.CREATE_AWARD_CATEGORY}
            element={<CreateCategory />}
          />
          <Route
            path={routes.ADMIN_AWARD_BULKVOTING_SCREEN}
            element={<BulkVotingPage />}
          />
          <Route
            path={routes.ADMIN_MANAGE_BULKVOTING}
            element={<AllBulkVotes />}
          />
          <Route path={routes.ADMIN_ADD_BULKVOTE} element={<AddBulkVoting />} />
          <Route path={routes.ADMIN_EDIT_BULKVOTE} element={<EditBulkVote />} />

          <Route path={routes.EDIT_AWARD_CATEGORY} element={<EditCategory />} />
          <Route path={routes.NOMINEES_SCREEN} element={<NomineesPage />} />
          <Route path={routes.MANAGE_NOMINEES} element={<AllNominees />} />
          <Route path={routes.ADD_NOMINEES} element={<AddNominee />} />
          <Route path={routes.EDIT_NOMINEE} element={<EditNominee />} />
          <Route
            path={routes.TRANSACTION_AWARDS}
            element={<TransactionAwards />}
          />
          <Route
            path={routes.AWARD_TRANSACTIONS}
            element={<AwardTransactions />}
          />
          <Route path={routes.AWARD_WITHDRAWAL} element={<AwardWithdrawal />} />
          <Route path={routes.RESULTS_AWARDS} element={<ResultAwards />} />
          <Route
            path={routes.RESULTS_CATEGORIES}
            element={<ResultCategories />}
          />
          <Route path={routes.RESULTS} element={<Results />} />
          <Route path={routes.EVENTS_PAGE} element={<ManageEvents />} />
          <Route path={routes.CREATE_EVENT} element={<CreateEvent />} />
          <Route path={routes.EDIT_EVENT} element={<EditEvent />} />
          <Route path={routes.MANAGE_TICKETS} element={<ManageTickets />} />
          <Route
            path={routes.MANAGE_ATTENDANCE}
            element={<ManageAttendance />}
          />
          <Route
            path={routes.VIEW_EVENT_TRANSACTIONS}
            element={<ViewTransactions />}
          />
          <Route path={routes.MANAGE_USERS} element={<ManageUsers />} />
          <Route path={routes.CREATE_USER} element={<CreateUsers />} />
          <Route path={routes.CREATE_TICKET} element={<CreateTicket />} />
          <Route path={routes.EDIT_TICKET} element={<EditTicket />} />
        </Route>
        <Route path={routes.EVENT_PREVIEW_ADMIN} element={<LivePreview />} />
        <Route path={routes.LOADING} element={<LoadingPage />} />
        <Route path={routes.PAYMENT_SUCCESS} element={<SuccessPage />} />
        <Route path={routes.PAYMENT_FAILED} element={<FailedPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
