import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../../../components/Button";

const CreateUsers = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isloading, setLoading] = useState(false);
  const [errors, setErrors] = useState();

  const [manageEvent, setManageEvent] = useState(false);
  const [manageTickets, setManageTickets] = useState(false);
  const [manageAttendance, setManageAttendance] = useState(false);
  const [manageUsers, setManageUsers] = useState(false);
  const [manageTransactions, setManageTransactions] = useState(false);
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    phone: "",
    event_id: id,
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    setLoading(true);
    let manageEventValue;
    let manageTicketValue;
    let manageUsersValue;
    let manageTransactionsValue;
    let manageAttendanceValue;
    if (manageEvent === true) {
      manageEventValue = "1";
    } else {
      manageEventValue = "0";
    }
    if (manageTickets === true) {
      manageTicketValue = "1";
    } else {
      manageTicketValue = "0";
    }
    if (manageUsers === true) {
      manageUsersValue = "1";
    } else {
      manageUsersValue = "0";
    }
    if (manageTransactions === true) {
      manageTransactionsValue = "1";
    } else {
      manageTransactionsValue = "0";
    }
    if (manageAttendance === true) {
      manageAttendanceValue = "1";
    } else {
      manageAttendanceValue = "0";
    }
    e.preventDefault();
    const data = {
      name: userInput?.name,
      email: userInput?.email,
      phone: userInput?.phone,
      event_id: id,
      manage_event: manageEventValue,
      manage_ticket: manageTicketValue,
      manage_attendance: manageAttendanceValue,
      manage_transactions: manageTransactionsValue,
      manage_users: manageUsersValue,
    };
    axios
      .post("/api/v2/affiliate/create", data)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setLoading(false);
          let msg = res.data.msg;
          Swal.fire({
            title: "Success",
            text: msg,
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          }).then(() => {
            navigate(`/dashboard/event/${id}/manage-users`);
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        if (err.response.status === 422) {
          setErrors(err?.response?.data?.errors);
        }
        if (err?.response?.data?.status === 400) {
          Swal.fire(
            "Info",
            err?.response?.data?.error_msg || "An error occured",
            "info"
          );
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };
  return (
    <div className="page-wrapper">
      <div className="content container">
        <div className="page-header"></div>

        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="p-2 h4">Create New User</div>
                    <div className="p-2">
                      <button
                        onClick={() => navigate(-1)}
                        className="btn btn-primary"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="container mt-3">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row formtype">
                          <div className="col-md-12">
                            <div className="form-group">
                              <span>Fullname</span>
                              <input
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Fullname"
                                value={userInput?.name}
                                onChange={handleUserInput}
                              />
                              <small className="text-danger">
                                {errors?.name}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <span>Email</span>
                              <div className="">
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control "
                                  placeholder="email"
                                  value={userInput?.email}
                                  onChange={handleUserInput}
                                />
                                 <small className="text-danger">
                                {errors?.email}
                              </small>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <span>Phone</span>
                              <input
                                type="number"
                                name="phone"
                                placeholder="telephone"
                                className="form-control"
                                value={userInput?.phone}
                                onChange={handleUserInput}
                              />
                               <small className="text-danger">
                                {errors?.phone}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ml-1">
                      <h5>Privileges</h5>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              name="manage_event"
                              id="manage_event"
                              value="manage_event"
                              checked={manageEvent}
                              onChange={() => setManageEvent(!manageEvent)}
                            />
                            <label htmlFor="manage_event">
                              <h6 className="mt-3 ml-2">Manage Event</h6>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center">
                            <input
                              checked={manageTickets}
                              type="checkbox"
                              name="manage_ticket"
                              id="manage_ticket"
                              value="manage_ticket"
                              onChange={() => setManageTickets(!manageTickets)}
                            />
                            <label htmlFor="manage_ticket">
                              <h6 className="mt-3 ml-2">Manage Tickets</h6>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center">
                            <input
                              checked={manageAttendance}
                              type="checkbox"
                              name="manage_attendance"
                              id="manage_attendance"
                              value="manage_attendance"
                              onChange={() =>
                                setManageAttendance(!manageAttendance)
                              }
                            />
                            <label htmlFor="manage_attendance">
                              <h6 className="mt-3 ml-2">Manage Attendance</h6>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="d-flex radio1 align-items-center">
                            <input
                              checked={manageUsers}
                              type="checkbox"
                              name="manage_users"
                              id="manage_users"
                              value="manage_users"
                              onChange={() => setManageUsers(!manageUsers)}
                            />
                            <label htmlFor="manage_users">
                              <h6 className="mt-3 ml-2">Manage Users</h6>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center">
                            <input
                              checked={manageTransactions}
                              type="checkbox"
                              name="manage_transactions"
                              id="manage_transactions"
                              value="manage_transactions"
                              onChange={() =>
                                setManageTransactions(!manageTransactions)
                              }
                            />
                            <label htmlFor="manage_transactions">
                              <h6 className="mt-3 ml-2">Manage Transactions</h6>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Button
                      buttonText="Create User"
                      cssClasses={
                        "btn btn-primary mb-3 form-control text-white"
                      }
                      isloading={isloading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateUsers;
