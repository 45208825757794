import React from "react";
import Button from "../../../../components/Button";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PaymentModal = ({ momoNumber, setMomoNumber, userInput, handleUserInput, paymentMethod, setPaymentMethod, handlePayment, isloading }) => {


    return (
        <div
            className="modal fade"
            id="payment-modal"
            aria-hidden="true"
            role="dialog"
        >
            <div
                className="modal-dialog modal-dialog-centered"
                role="document"
            >
                <div className="modal-content moa">
                    <div className="modal-body">
                        <form onSubmit={handlePayment}>
                            <div className="share-content">
                                <div className="mb-md-4 mt-md-5 mt-2 text-center">
                                    <h4>Select Payment Option</h4>
                                    <hr />
                                </div>
                                <div className="mb-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="Direct">
                                                <div className="d-flex">
                                                    <input
                                                        type="radio"
                                                        name="phone"
                                                        value="momo"
                                                        id="Direct"
                                                        defaultChecked
                                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                                    />
                                                    <h3 className="my-auto text-nowrap ms-2">Direct Momo Payment</h3>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="email1" className="ms-md-4">
                                                <div className="d-flex">
                                                    <input
                                                        type="radio"
                                                        name="phone"
                                                        value="card"
                                                        id="email1"
                                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                                    />
                                                    <h3 className="my-auto ms-2 text-nowrap">Alternate Payment</h3>
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                {paymentMethod === "momo" && (
                                    <div className="container justify-content-left d-flex">
                                        <small className="text-left text-left-f">
                                            Direct Payment allows you to make payment directly from your
                                            momo wallet (Local Payments Only)
                                        </small>
                                    </div>
                                )}
                                {paymentMethod === "card" && (
                                    <div className="container justify-content-left d-flex">
                                        <small className="text-left text-left-f">
                                            Alternate Payment allows you make payment via Credit
                                            Card,Master Card and Mobile Money (for Local and International Payments)
                                        </small>

                                    </div>
                                )}
                                {paymentMethod === "momo" && (
                                    <>
                                        <div className="mb-3 mt-3">
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    required
                                                    id="sel2"
                                                    name="network"
                                                    value={userInput?.network}
                                                    onChange={handleUserInput}
                                                >
                                                    <option value="">---Select Network---</option>
                                                    <option value="MTN-GH">MTN-GH</option>
                                                    <option value="VODAFONE-GH">VODAFONE-GH</option>
                                                    <option value="TIGO-GH">TIGO-GH</option>
                                                    <option value="AIRTEL-GH">AIRTEL-GH</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <div className="text-left">Enter Mobile Money Number</div>
                                                <PhoneInput
                                                    country={"gh"}
                                                    onlyCountries={['gh']}
                                                    value={momoNumber}
                                                    onChange={setMomoNumber}
                                                    countryCodeEditable={false}
                                                    required={true}
                                                    className=""
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="mt-3">
                                    <Button
                                        buttonText={"Make Payment"}
                                        isloading={isloading}
                                        cssClasses="btn btn-primary bg-default buttonedit1 w-100"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentModal;
