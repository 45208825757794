import React, { useState } from "react";
import Button from "../../../../../../../components/Button";
import Pagination from "../../../../../../../components/Pagination";
import VerifyTicket from "./VerifyTicket";

export default function AttendanceTable({
  attendees,
  reloadTable,
  setReloadTable,
}) {

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = attendees?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // eslint-disable-next-line array-callback-return
  const filteredList = currentPosts?.filter((item) => {
    if (search === "") {
      return item;
    } else if (
      item?.name?.toLowerCase().includes(search.toLowerCase()) ||
      item?.phone?.toLowerCase().includes(search.toLowerCase())||
      item?.email?.toLowerCase().includes(search.toLowerCase())||
      item?.ticket_status?.toLowerCase().includes(search.toLowerCase())||
      item?.ticket?.title?.toLowerCase().includes(search.toLowerCase())
    ) {
      return item;
    }
  });

  return (
    <>
      <div className="card-header">
        <h5>Registered Attendees</h5>
        <div className="d-flex flex-row justify-content-between">
          <div className="w-50 mb-2">
            <input
              type="text"
              className="form-control text-center"
              placeholder="Search Attendee"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="p-2 d-flex">
            <div className="px-2">
              <a
                data-toggle="modal"
                href="#verify-modal"
                className="btn btn-primary bg-default w-100"
              >
                Verify Ticket
              </a>
            </div>
            <VerifyTicket reloadTable={reloadTable} setReloadTable={setReloadTable} />
          </div>
        </div>
      </div>

      <table
        id="example"
        className="datatable table-stripped table table-hover table-center mb-0"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Ticket Type</th>
            <th>Status</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredList?.map((attendee, index) => (
            <TableRows attendee={attendee} key={index} />
          ))}
        </tbody>
      </table>
      <div className="pagin-pos">
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={attendees?.length}
          paginate={paginate}
        />
      </div>
    </>
  );
}

const TableRows = ({ attendee }) => {
  return (
    <tr>
      <td className="text-capitalize">{attendee?.name}</td>
      <td className="text-capitalize">{attendee?.phone}</td>
      <td className="text-lowercase text-center">{attendee?.email || "N/A"}</td>
      <td className="text-center text-capitalize">{attendee?.ticket?.title}</td>
      <td>
        <div className="actions text-center">
          <span
            className={`btn btn-sm ${
              attendee?.ticket_status === "verified" && "bg-success-light"
            } 
        ${
          attendee?.ticket_status === "unverified" && "bg-danger-light"
        } mr-2 text-center text-capitalize`}
          >
            {attendee?.ticket_status}
          </span>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-end justify-content-end">
          <div>
            <Button
              buttonText={
                <span>
                  <i className="fas fa-envelope"></i> Resend Code
                </span>
              }
              cssClasses={"btn btn-outline-info"}
              callback={() => {}}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};
