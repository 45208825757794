import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";

const CreateAward = () => {
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userInput, setUserInput] = useState({
    title: "",
    start_date: "",
    end_date: "",
    award_prefix: "",
    price_per_vote: "",
    errors: [],
  });
  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2)
     var hour=('0'+date.getUTCHours()).slice(-2)
     var minutes=('0'+date.getMinutes()).slice(-2)
    return `${date.getFullYear()}-${mnth}-${day} ${hour}:${minutes}:00`
  }

  
  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("title", userInput.title);
    data.append("start_date",convertDate(userInput.start_date));
    data.append("end_date",convertDate(userInput.end_date));
    data.append("award_prefix", userInput.award_prefix);
    data.append("price_per_vote", userInput.price_per_vote);
    data.append("image", selectedImage);
    axios
      .post("/api/v2/awards/create", data)
      .then((res) => {
        if(res.data.status===200 && res.data.success===true){
          setLoading(false);
          let msg=res.data.msg;
          Swal.fire({
            title:'Success',
            text:msg,
            icon:'success',
            confirmButtonColor:'#011535',
            confirmButtonText:'Okay'
          }).then(()=>{
            navigate('/dashboard/awards')
          })
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        if (err.response.status === 422) {
          setUserInput((userInput) => ({
            ...userInput,
            errors: err?.response?.data?.errors,
          }));
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  return (
    <div className="page-wrapper">
      <div className="content container">
        <div className="page-header">
          <div className="row align-items-center"></div>
        </div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="card">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">
                    <h3>New Award Setup</h3>
                  </div>
                  <div className="p-2">
                    <Link to="/dashboard/awards" className="btn btn-primary">
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row mx-2">
                  <div className="col-lg-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row formtype">
                        <div className="col-md-12">
                          <div className="form-group">
                            <span>Award Title</span>
                            <input
                              className="form-control"
                              required
                              onChange={handleUserInput}
                              value={userInput.title}
                              name="title"
                              type="text"
                              placeholder="Award Title"
                            />
                            <small className="text-danger">
                              {userInput?.errors?.title}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <span>Start Date</span>
                            <div className="">
                              <input
                                type="datetime-local"
                                name="start_date"
                                value={userInput.start_date}
                                onChange={handleUserInput}
                                required
                                className="form-control "
                                min={Date.now()}
                              />
                              <small className="text-danger">
                                {userInput?.errors?.start_date}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <span>End Date</span>
                            <div className="">
                              <input
                                type="datetime-local"
                                name="end_date"
                                value={userInput?.end_date}
                                onChange={handleUserInput}
                                min="<?php echo date('Y-m-d')?>"
                                required
                                className="form-control "
                              />
                              <small className="text-danger">
                                {userInput?.errors?.end_date}
                              </small>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <span>Nomination Code Prefix</span>
                            <div className="">
                              <input
                                type="text"
                                name="award_prefix"
                                required
                                className="form-control"
                                value={userInput?.award_prefix}
                                onChange={handleUserInput}
                                maxLength="4"
                                placeholder="Eg. GMB (max-characters:4)"
                              />

                              <small className="text-danger">
                                {userInput?.errors?.award_prefix}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <span>Price Per Vote (GHC)</span>
                            <div className="">
                              <input
                                type="number"
                                name="price_per_vote"
                                required
                                className="form-control"
                                value={userInput?.price_per_vote}
                                onChange={handleUserInput}
                                maxLength="4"
                                step="any"
                                placeholder="min: 0.5"
                                min="0.5"
                                max="100"
                              />
                              <small className="text-danger">
                                {userInput?.errors?.price_per_vote}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <span>Upload Event Flyer</span>
                            <div className="">
                              <input
                                type="file"
                                name="image"
                                required
                                className="form-control"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                              />
                              <small className="text-danger">
                                {userInput?.errors?.image}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          {selectedImage && (
                            <div
                              style={{
                                border: "1px solid black",
                                maxWidth: "40%",
                                maxHeight: "150%",
                              }}
                            >
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="uploaded-file"
                                width="100%"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <Button
                          buttonText="Create Award"
                          isloading={isloading}
                          cssClasses="btn btn-primary form-control text-white buttonedit mt-2"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAward;
