import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../components/Button";
import Swal from "sweetalert2";
import axios from "axios";

const TableRows = ({ category, award_id, reloadTable, setReloadTable }) => {

  const navigate = useNavigate();
  const [isDeleting, setDeleting] = useState(false);
  const [isActivating,setActivating]=useState(false)

  const handleDelete = () => {
    Swal.fire({
      title: 'Question',
      icon: 'question',
      text: 'Are you sure to block this category? Blocking will prevent contestants in this category to be voted for',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      confirmButtonColor: "#011535",
    }).then(action => {
      if (action.isConfirmed) {
        setDeleting(true);
        axios.post(`/api/v2/categories/delete/${category?.id}`)
          .then(res => {
            if (res.data.success === true && res.data.status === 200) {
              Swal.fire({
                title: 'Success',
                text: 'Category has been blocked successfully',
                timer: 2000,
                icon: 'info',
                confirmButtonColor: "#011535",
              }).then(_ => {
                setDeleting(false);
                setReloadTable(!reloadTable);
              })
            }
          })
      }
    })
  }

  const handleActivate = () => {
    Swal.fire({
      title: 'Question',
      icon: 'question',
      text: 'Are you sure to activate this category?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      confirmButtonColor: "#011535",
    }).then(action => {
      if (action.isConfirmed) {
        setActivating(true);
        axios.post(`/api/v2/categories/activate/${category?.id}`)
          .then(res => {
            if (res.data.success === true && res.data.status === 200) {
              Swal.fire({
                title: 'Success',
                text: 'Category has been activated successfully',
                timer: 2000,
                icon: 'info',
                confirmButtonColor: "#011535",
              }).then(_ => {
                setActivating(false);
                setReloadTable(!reloadTable);
              })
            }
          })
      }
    })
  }

  return (
    <tr>
      <td className="text-capitalize">{category?.title}</td>
      <td className="text-capitalize text-center">{category?.gender}</td>
      <td className="text-center">{category?.capacity}</td>
      <td className="text-center">{category?.capacity}</td>
      <td>{category?.price}</td>
      <td>
        <div className="actions">
          {category?.is_active === "active" && (
            <span className="btn btn-sm bg-success-light mr-2">Active</span>
          )}
          {category?.is_active === "blocked" && (
            <span className="btn btn-sm bg-danger-light mr-2">Blocked</span>
          )}
        </div>
      </td>
      <td>
        <div className="d-flex align-items-end justify-content-end">
          <div>
            <Button
              buttonText={
                <span>
                  <i className="fas fa-pencil-alt"></i>
                </span>
              }
              cssClasses={"btn btn-outline-info"}
              callback={() => navigate(`/dashboard/award/${award_id}/category/${category?.id}/edit`)}
            />
          </div>
          {category?.is_active==='active'&&
          <div className="ml-2">
            <Button
              buttonText={
                <span>
                  <i className="fas fa-trash-alt"></i>
                </span>
              }
              cssClasses={"btn btn-danger"}
              callback={handleDelete}
              isloading={isDeleting}
            />
          </div>
          }
          {category?.is_active==='blocked'&&
          <div className="ml-2">
            <Button
              buttonText={
                <span>
                  <i className="fe fe-check"></i>
                </span>
              }
              cssClasses={"btn btn-success"}
              callback={handleActivate}
              isloading={isActivating}
            />
          </div>
          }
        </div>
      </td>
    </tr>
  );
};

export default TableRows;
