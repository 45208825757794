import React from "react";
import { Helmet } from "react-helmet";

const HelmetData = ({ title, description, imageSrc, url }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title || "OpenCastGH"} />
        <meta
          name="description"
          content={
            description ||
            "OpenCastGH - A platform for running awards voting and selling of Event Tickets"
          }
        />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description||"OpenCastGH - A platform for running awards voting and selling of Event Tickets"} />
        {imageSrc && <meta property="og:image" content={imageSrc ||'/logo192.png'} />}
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description || "OpenCastGH - A platform for running awards voting and selling of Event Tickets"} />
        {imageSrc && <meta property="twitter:image" content={imageSrc ||'/logo192.png'} />}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="whatsapp:title" content={title} />
        <meta property="whatsapp:description" content={description || "OpenCastGH - A platform for running awards voting and selling of Event Tickets"} />
        {imageSrc && <meta property="whatsapp:image" content={imageSrc ||'/logo192.png'} />}
        {/* Add other metadata tags as needed */}
      </Helmet>

      {/* Your page content goes here */}
    </>
  );
};

export default HelmetData;
