import React from "react";
import Button from "../../../../components/Button";
import Footer from "../../partials/Footer";
import Nav from "../../partials/Nav";
import Advertisement from "../components/Advertisement"
import EventCategory from "./EventCategory";
import Events from "./Events";
import MoreEvents from "./MoreEvents";
import FeaturedEvents from "./FeaturedEvents";
import useFetch from "../../../../components/customHooks/useFetch";

const TicketingPage = () => {
    const {
        data: events,
        isloaded
    } = useFetch("/api/v2/guest/ticket/all");
    return (
        <>
            <Nav />
            <div className="ticket-div">
            </div>
            <EventCategory />
            <div className="advert-margin">
                <div className="col-lg-12">
                    <div className="search-cover">
                        <input
                            type="text"
                            className="form-control rounded-pill search-text"
                            placeholder="Search for event here..."
                        />
                        <i className="fas fa-search search-icon"></i>
                    </div>
                </div>
            </div>
            <FeaturedEvents events={events} isloaded={isloaded} />
            <div className="col-md-12 mb-4">
                <div className="row advert-margin justify-content-center align-items-center">
                    <div className="col-md-6">
                        <img src="assets/images/ticketing.webp" loading="lazy" alt="ticket" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <div className="title-font">
                            <h1>All the tools you need</h1>
                        </div>
                        <div>
                            <div className="bord-line"></div>
                        </div>
                        <div className="title-font">
                            <h4>Say goodbye to service tiers and get access to a full set of
                                ticketing features! No contracts, no commitments -
                                create branded event pages, easily manage your event, and
                                develop advertising campaigns in just minutes.
                            </h4>
                        </div>
                        <div className="float-start mt-md-4">
                            <Button
                                buttonText={"Call Us Now"}
                                cssClasses="btn btn-outline-primary float-end btn-size"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Events events={events} isloaded={isloaded} />
            <Advertisement />
            <MoreEvents events={events} isloaded={isloaded} />

            <Footer />
        </>
    )
}
export default TicketingPage