import React from "react";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChart = ({results}) => {
  const nominees=results?.map((nominee)=>{
    if(nominee?.stage_name!==null){
     return `${nominee?.stage_name?.toUpperCase()} (${nominee?.nominee_code})`
    }else{
      return `${nominee?.full_name?.toUpperCase()} (${nominee?.nominee_code})`
    }
  });

  const votes=results?.map(result=>result?.votes?.count||0);
  const data = {
    labels: nominees,
    datasets: [
      {
        label: "Contestants Vote Count",
        data: votes,
        backgroundColor: ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)", "rgba(240, 83, 55,0.5)"],
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Contestants Vote Count",
      },
    },
  };
  return (
    <div className="w-100">
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarChart;
