import React, { useState } from "react";
import Pagination from "../../../../components/Pagination";

const TicketCart = ({ tickets, removeItemFromCart }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = tickets?.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="card card-shadow card-border-radius ticket-table-h">
      <div className="card-body">
        <div className="col-md-12">
          <div className="table-responsive mt-2">
            <table className="table table-hover table-center" id="example">
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="text-center text">Telephone</th>
                  <th className="text-center">Email</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts?.map((ticket, i) => (
                  <tr key={i}>
                    <td className="text-nowrap">
                      <div>{ticket?.name}</div>
                    </td>
                    <td className="text-center">{ticket?.phone}</td>
                    <td className="text-nowrap text-center">{ticket?.email}</td>
                    <td className="text-center">{ticket?.quantity}</td>
                    <td className="text-center">{ticket?.amount}</td>
                    <td>
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => removeItemFromCart(ticket?.id)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="pagin-pos pagin-pos-mobile">
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={tickets.length}
            paginate={paginate}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketCart;
