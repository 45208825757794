import React from "react";
import { Link } from "react-router-dom";
import useFetch from "../../../../components/customHooks/useFetch";
import Loader from "../../../../components/helpers/Loader";
import BulkVoteCard from "./components/BulkVoteCard";

const BulkVotingPage = () => {

  const { data: awards, isloaded } = useFetch("/api/v2/awards/all");

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2">
                  Manage Bulk Votes
                </h4>
                <Link
                  to="/dashboard/award/create"
                  className="btn btn-primary float-right veiwbutton"
                >
                  Add New Award
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {isloaded ? (
            <div className="col-sm-12">
              {awards?.length > 0 ? (
                <>
                  <section className="pricing py-5">
                    <h2
                      className="text-white text-center"
                      style={{ marginTop: "-3%" }}
                    >
                      Select Award to Manage Bulk Votes
                    </h2>

                    <div className="container">
                      <div className="row mt-5">
                        {awards?.map((award, index) => (
                          <BulkVoteCard key={index} award={award} />
                        ))}
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <NoAwards />
              )}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

const NoAwards = () => {
  return (
    <section className=" py-3">
      <div className="text-center">
        <h2 className="mt-3">No Awards Available</h2>
        <div className="mt-3 text-center">
          <Link to="/dashboard/award/create" className="btn btn-primary">
            Create New Award
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BulkVotingPage;
