import { Link } from "react-router-dom";
import Slider from "react-slick";
import CardItem from "../../components/CardItem";
import routes from "../../../../components/helpers/routes";

const FeaturedAwards = ({ awards }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 600,
    nextArrow: (
      <img
        src="/assets/images/arrow-right1.png"
        alt="arrow"
        className="arrow-right-pos slick-next"
      />
    ),
    prevArrow: (
      <img
        src="/assets/images/arrow-left1.png"
        alt="arrow"
        className="arrow-left-pos slick-next"
      />
    ),
  };

  return (
    <div className="col-lg-12">
      <div className="row advert-margin ">
        <div className="col-lg-12 d-flex justify-content-between mb-2">
          <div className="text-margin-start12">
            <h3>Featured Awards</h3>
          </div>
          <div>
            <Link to={routes.AWARDS_PAGE} className="btn btn-outline-primary1 rounded-pill view-text">
              View more
            </Link>
          </div>
        </div>
        <div className="col-lg-12">
          <section className="slider variable">
            <div className="row">
              <Slider {...settings}>
                {awards
                  // eslint-disable-next-line array-callback-return
                  ?.filter((item) => {
                    const today = new Date();
                    const end_date = new Date(item?.end_date);
                    if (
                      item?.is_featured === "true" &&
                      item?.status === "voting" &&
                      !(today >= end_date)
                    ) {
                      return item;
                    }
                  })
                  .map((award, i) => (
                    <div className="col-custom-2 col-md-3 col-6" key={i} id="custom-col">
                      <Link to={`/award/${award?.id}/categories`} key={award?.id}>
                        <div className="card-h-2-width">
                          <CardItem image={award?.image} title={award?.title} />
                        </div>
                      </Link>
                    </div>
                  ))}
              </Slider>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default FeaturedAwards;
