import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../../../../components/customHooks/useFetch";
import usePostFetch from "../../../../../../components/customHooks/usePostFetch";
import Loader from "../../../../../../components/helpers/Loader";
import Card from "../../../../partials/Card";
import SvgIcons from "../../../../partials/SvgIcons";
import TicketTable from "./partials/TicketTable";

const ManageTickets = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [reloadTable, setReloadTable] = useState(false);
  const { data, isloaded, errors } = useFetch(
    `/api/v2/ticket/event/${id}/tickets`,
    reloadTable
  );

  const { data: statistics } = usePostFetch(`/api/v2/events/${id}/statistics`);
  if (errors?.status === 400) {
    Swal.fire({
      title: "404",
      text: "Event Not Found",
      // timer: 2000,
      icon: "info",
      confirmButtonColor: "#011535",
    }).then((_) => {
      navigate("*");
    });
  }

  

  useEffect(() => {
    if (statistics) {
      localStorage.setItem("is_event_owner", statistics?.roles?.is_event_owner);
      localStorage.setItem("manage_event", statistics?.roles?.manage_event);
      localStorage.setItem("manage_ticket", statistics?.roles?.manage_ticket);
      localStorage.setItem(
        "manage_attendance",
        statistics?.roles?.manage_attendance
      );
      localStorage.setItem(
        "manage_transactions",
        statistics?.roles?.manage_transactions
      );
      localStorage.setItem("manage_users", statistics?.roles?.manage_users);
    }
  }, [statistics]);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="col-lg-12 mt-5">
            <div className="row">
              <div className="col-lg-10">
                <div className="">
                  <h3 className="card-title text-uppercase">
                    {data?.title} Dashboard
                  </h3>
                </div>
              </div>
              <div className="col-lg-2 float-end">
                <div>
                  <Link to="/dashboard/events" className="btn btn-secondary">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {Number(localStorage.getItem("manage_ticket")) === Number(1) && (
            <>
              <Card
                title="Tickets Sold"
                value={statistics?.tickets_sold || "0"}
                svg={SvgIcons.awards}
              />
              <Card
                title="Verified Tickets"
                value={statistics?.verified_tickets || "0"}
                svg={SvgIcons.tickects}
              />
              <Card
                title="Pending Verification"
                value={statistics?.unverified_tickets || "0"}
                svg={SvgIcons.wallet}
              />
            </>
          )}
          {Number(localStorage.getItem("manage_transactions")) ===
            Number(1) && (
            <>
              <Card
                title="Total Revenue"
                value={statistics?.total_revenue || "0"}
                svg={SvgIcons.awards}
              />
              <Card
                title="Withdrawals"
                value={statistics?.total_withdrawals || "0"}
                svg={SvgIcons.tickects}
              />
              <Card
                title="Event Balance"
                value={statistics?.event_balance || "0"}
                svg={SvgIcons.wallet}
              />
            </>
          )}
        </div>

        <nav className="nav nav-pills nav-fill mb-4">
          {Number(localStorage.getItem("manage_users")) === Number(1) && (
            <Link
              to={`/dashboard/event/${data?.id}/attendance`}
              className="nav-link btn btn-primary m-2"
            >
              Manage Attendance
            </Link>
          )}
          {Number(localStorage.getItem("manage_transactions")) ===
            Number(1) && (
            <Link
              to={`/dashboard/event/${data?.id}/view-transaction`}
              className="nav-link btn btn-primary m-2"
            >
              View Transactions
            </Link>
          )}
          {Number(localStorage.getItem("manage_users")) === Number(1) && (
            <Link
              to={`/dashboard/event/${data?.id}/manage-users`}
              className="nav-link btn btn-primary m-2"
              href="#"
            >
              Manage Users
            </Link>
          )}
          {/* <a className="nav-link btn btn-primary m-2">Live Preview</a> */}
        </nav>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table ">
              <div className="card-body booking_card">
                {isloaded ? (
                  <div className="table-responsive">
                    <TicketTable
                      event={data}
                      reloadTable={reloadTable}
                      setReloadTable={setReloadTable}
                    />

                    {data?.tickets?.length === 0 && (
                      <div className="mt-5">
                        <h3 className="text-center">No Category Available</h3>
                        <div className="text-center mt-3">
                          <Link
                            to={`/dashboard/event/${data?.id}/ticket/create`}
                            className="btn btn-primary veiwbutton"
                          >
                            Create New Ticket
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTickets;
