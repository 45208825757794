import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../../../../components/Button";
import axios from "axios";
import Swal from "sweetalert2";
import convertDate from "../../../../../../components/helpers/ConverDate";

const CreateTicket = () => {
    const { id } = useParams(); // this id is the Event id
    const navigate = useNavigate();
    const [isloading, setLoading] = useState();
    const [userInput, setUserInput] = useState({
        title: "",
        slots: 0,
        price: 0,
        date: '',
        description: '',
        errors: [],
    });

    const handleUserInput = (e) => {
        setUserInput((userInput) => ({
            ...userInput,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const data = {
            title: userInput?.title,
            slots_available: userInput.slots,
            price: userInput.price,
            date: convertDate(userInput.date),
            description: userInput.description,
            event_id: id
        };
        axios
            .post(`/api/v2/ticket/create/event/${id}`, data)
            .then((res) => {
                if (res.data.status === 200 && res.data.success === true) {
                    setLoading(false);
                    
                    Swal.fire({
                        title: "Success",
                        text: 'Ticket has been created successfully',
                        icon: "success",
                        confirmButtonColor: "#011535",
                        confirmButtonText: "Okay",
                    }).then(() => {
                        navigate(-1);
                    });
                }
            })
            .catch((err) => {
                console.log(err.response);
                setLoading(false);
                if (err.response.status === 422 || err.response.status === 400 ) {
                    setUserInput((userInput) => ({
                        ...userInput,
                        errors: err?.response?.data?.error_msg||err?.response?.data?.errors,
                    }));
                }
                if (err.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                }
            });
    };


    return (
        <div className="page-wrapper">
            <div className="content container">
                <div className="page-header">
                    {/* <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title mt-5 text-center">Add New Categories</h3>
            </div>
          </div> */}
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-2 h4">Create New Ticket</div>
                                        <div className="p-2">
                                            <Link
                                                to={`/dashboard/event/${id}/tickets`}
                                                className="btn btn-primary"
                                            >
                                                Back
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="container mt-3">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row formtype">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <span>Ticket Title</span>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                value={userInput?.title}
                                                                name="title"
                                                                onChange={handleUserInput}
                                                                placeholder="Ticket Title/Type eg. Regular"
                                                                required
                                                            />
                                                            <small className="text-danger">{userInput?.errors?.title}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <span>Event Date</span>
                                                            <div className="">
                                                                <input
                                                                    type="datetime-local"
                                                                    name="date"
                                                                    value={userInput.date}
                                                                    onChange={handleUserInput}
                                                                    required
                                                                    className="form-control "
                                                                />
                                                                <small className="text-danger">
                                                                    {userInput?.errors?.date }
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <span>Available Slots</span>
                                                            <input
                                                                type="number"
                                                                name="slots"
                                                                required
                                                                placeholder="Slots Available for this category"
                                                                className="form-control"
                                                                id="usr"
                                                                min={1}
                                                                value={userInput?.slots}
                                                                onChange={handleUserInput}
                                                            />
                                                            <small className="text-danger">{userInput?.errors?.slots_available}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <span>Price</span>
                                                            <input
                                                                type="number"
                                                                required
                                                                name="price"
                                                                value={userInput?.price}
                                                                onChange={handleUserInput}
                                                                min="1"
                                                                placeholder="set to 0 if nomination is free"
                                                                className="form-control"
                                                                id="usr"
                                                            />

                                                            <small className="text-danger">{userInput?.errors?.price}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <span>Event Description</span>
                                                            <textarea
                                                                name="description"
                                                                value={userInput?.description}
                                                                onChange={handleUserInput}
                                                                id=""
                                                                minLength={15}
                                                                cols="30"
                                                                rows="10"
                                                                className="form-control textarea-size"
                                                                placeholder="Event description"
                                                            ></textarea>
                                                            <small className="text-danger">
                                                                {userInput?.errors?.description}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            buttonText="Create Ticket"
                                            isloading={isloading}
                                            cssClasses={
                                                "btn btn-primary mb-3 form-control text-white"
                                            }
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CreateTicket;
