import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const EventsCard = ({ event, setReload, reload }) => {
  const handleEventState = (status) => {
    axios
      .post("/api/v2/events/update-status", { id: event?.id, status: status })
      .then((res) => {
        if (res?.status === 200) {
          Swal.fire("Info", res?.data?.msg, "info");
          setReload(!reload);
        }
      })
      .catch((err) => {
        Swal.fire("Update Failed", "Error Updating Event State", "error");
      });
  };

  return (
    <div className="col-lg-3">
      <div className="card mb-5 mb-lg-3">
        <AwardStatusActions
          status={event?.status}
          handleEventState={handleEventState}
        />
        <div className="card-body">
          <figure>
            <img
              className="img-thumbnail"
              width="100%"
              src={`${process.env.REACT_APP_BACKEND_URL}/${event?.image}`}
              alt="Event"
            />
          </figure>
          <div className="container">
            <div className="row mb-3">
              <div className="col-md-12">
                <div
                  className="h5 text-center text-capitalize textElipse"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={event?.title}
                >
                  {event?.title}
                </div>
              </div>
            </div>
          </div>
          <Link
            to={`/dashboard/event/${event?.id}/tickets`}
            className="btn btn-block btn-primary text-uppercase"
          >
            Event Dashboard
          </Link>

          <Link
            to={`/dashboard/event/${event?.id}/edit`}
            className="btn btn-block btn-primary text-uppercase text-nowrap"
          >
            Edit event
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EventsCard;

const AwardStatusActions = ({ status, handleEventState }) => {
  return (
    <>
      {status === "pending" && (
        <div className="card colk bg-warning text-white">
          <div className="card-body">Pending Approval</div>
        </div>
      )}

      {status === "active" && (
        <div className="card colk bg-danger text-white">
          <div className="card-body">
            <button
              onClick={() => handleEventState("ended")}
              className="text-white btn bg-danger border-0 btn-outline-danger"
            >
              End Event
            </button>
          </div>
        </div>
      )}

      {status === "ended" && (
        <div className="card colk bg-success text-white">
          <div className="card-body">
            <button
              onClick={(e) => handleEventState("active")}
              className="text-white btn bg-success border-0 btn-outline-success"
            >
              Re-activate
            </button>
          </div>
        </div>
      )}
      {status === "blocked" && (
        <div className="card colk bg-danger text-white">
          <div className="card-body">
            <div className="card-body">Blocked</div>
          </div>
        </div>
      )}
    </>
  );
};
