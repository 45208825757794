import { useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import Button from "../../../components/Button";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
const NewPasswordForm = ({ setCurrent, phone,token }) => {
  const navigate = useNavigate();
  const [userInput,handleUserInput]=useState({
    password:'',
    password_confirmation:'',
})
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [icon1, setIcon1] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const handleToggle1 = () => {
    if (type1 === "password") {
      setIcon1(eye);
      setType1("text");
    } else {
      setIcon1(eyeOff);
      setType1("password");
    }
  };

  const [errors, setError] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleOnChange = (e) => {
    setError((error) => ({
      ...error,
      [e.target.name]: "",
    }));
    handleUserInput(userInput=>({
        ...userInput,[e.target.name]:e.target.value
    }));
  };

  const [isProcessing, setProcessing] = useState(false);

  const submitForm = (e) => {
    setProcessing(true);
    e.preventDefault();

    if (userInput?.password !== userInput?.password_confirmation) {
      setError((error) => ({
        ...error,
        password: "Passwords do not match",
      }));
      setProcessing(false);
    } else {

      axios
        .post("/api/v2/auth/reset-password", {
            phone: phone,
            password: userInput?.password,
            password_confirmation: userInput?.password,
            token: token,
          })
        .then((res) => {
          navigate("/login");
          setProcessing(false)
          if (res.data.success === true && res.data.status === 200) {
            Swal.fire({
              title: "success",
              text: "Password has been changed successfully",
              icon: "success",
              confirmButtonText: "Okay",
              confirmButtonColor: "#00b17d",
            }).then((_) => {
              navigate("/login");
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 400) {
            Swal.fire({
              title: "Error",
              text: err.response.data.error_msg,
              icon: "error",
              button: "OK",
              confirmButtonColor: "#00b17d",
            });
          }
        });
    }
  };
  return (
    <>
      <div className="mb-5">
        <Link to="/login">
          <img src="/assets/images/arrow-left1.png" loading="lazy" alt="arrow"  />
        </Link>
      </div>
      <div>
        <h1 className="text-left mb-5">Forgot Password?</h1>
      </div>
      <div>
        <h4 className="rec-text">
          Recover your Password by entering your phone number
        </h4>
      </div>
      <form>
        <div className="form-group mt-4">
          <div className="eye-pos">
            <input
              type={type}
              className="form-control flex-fill password"
              placeholder="Enter your password"
              value={userInput?.password}
              name="password"
              onChange={handleOnChange}
              required
            />
            <span onClick={handleToggle}>
              <Icon icon={icon} size={18} className="view-password-icon1" />
            </span>
          </div>
          <small className="text-danger">{errors?.password}</small>
        </div>
        <div className="form-group mt-4">
          <div className="eye-pos">
            <input
              type={type1}
              className="form-control flex-fill password"
              placeholder="Verify Password"
              value={userInput?.password_confirmation}
              name="password_confirmation"
              onChange={handleOnChange}
              required
            />
            <span onClick={handleToggle1}>
              <Icon icon={icon1} size={18} className="view-password-icon1" />
            </span>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center mt-4">
          <Button
            cssClasses={"submit"}
            isloading={isProcessing}
            buttonText="Continue"
            callback={submitForm}
          />
        </div>
        <div className="d-flex flex-row justify-content-center mt-4">
          <Link to="/login">Login Instead</Link>
        </div>
      </form>
    </>
  );
};
export default NewPasswordForm;
