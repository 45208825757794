import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
export default function UsersTable({ users,reload,setReload }) {
  const { id } = useParams(); //Event ID
  const [search, setSearch] = useState("");

  const deleteUser = (e, index, owner) => {
    console.log(owner)
    if (owner === "1") {
      Swal.fire({
        title: "Error",
        text: "Event Owner can't be deleted",
        icon: "error",
        confirmButtonColor: "#011535",
        confirmButtonText: "Okay",
        timer: 2000,
      });
    } else {
      Swal.fire({
        title: "Question",
        text: "This action removes all privileges assigned to user and deletes user's account, Do you want to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#011535",
        cancelButtonText: "No",
      }).then((action) => {
        if (action.isConfirmed) {
          axios
            .post(`/api/v2/affiliate/delete?event_id=${id}&user_id=${index}`)
            .then((res) => {
              let msg = res.data.msg;
              Swal.fire({
                title: "Deleted",
                text: msg,
                icon: "success",
                confirmButtonColor: "#011535",
                confirmButtonText: "Okay",
                timer: 2000,
              }).then((_) => {
                setReload(!reload);
              });
            });
        }
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-content-center">
        <div className="w-50 mb-2">
          <input
            type="text"
            className="form-control text-center"
            placeholder="Search User"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <table
        id="example"
        className="datatable table-stripped table table-hover table-center mb-0"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Telephone</th>
            <th>Email</th>
            <th>Status</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users?.filter(item => item?.user?.name?.toLowerCase().includes(search.toLowerCase()) || item?.user?.email?.toLowerCase().includes(search.toLowerCase()))?.map((user, index, owner) => (
            <tr key={[index, owner]}>
              <td className="text-capitalize">{user?.user?.name}</td>
              <td>{user?.user?.phone}</td>
              <td>{user?.user?.email}</td>
              <td className="text-center">
                {user?.user?.status.toLowerCase() === "active" && (
                  <div className="bg-success-light">Active</div>
                )}
              </td>
              <td>
                <div className="d-flex justify-content-center">
                  <div className="ml-2">
                    <button
                      onClick={(e) =>
                        deleteUser(
                          e,
                          (index = user?.user_id),
                          (owner = user?.is_event_owner)
                        )
                      }
                      className="btn btn-sm btn-danger mr-2"
                    >
                      <span className="fas fa-trash" title="Delete"></span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
