import React from "react";
import { useNavigate } from "react-router-dom";

const FailedPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="container error-cover">
        <div>
          <img
            src="/assets/images/failed.webp"
            alt="Error"
            className="img-fluid"
          />
        </div>
        <div>
          <h4>
            PAYMENT TRANSACTION <span>FAILED</span>
          </h4>
        </div>
        <div className="d-flex justify-content-center">
          <div className="p-2">
            <button
              type="button"
              onClick={() => navigate('/')}
              className="btn btn-outline-primary"
            >
              Go to HomePage
            </button>
          </div>
          <div className="p-2">
            <button
              type="button"
              onClick={() => navigate(-2)}
              className="btn btn-outline-primary"
            >
              Go Back 
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default FailedPage;
