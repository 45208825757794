import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../partials/Footer";
import Nav from "../../partials/Nav";
import Swal from "sweetalert2";
import AwardCategoryCard from "../../components/AwardCategoryCard";
import Slider from "react-slick";
import Nominees from "./Nominees";
import useFetch from "../../../../components/customHooks/useFetch";
import HelmetData from "../../../../components/HelmetData";

const AwardCategory = () => {
  const navigate = useNavigate();
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 600,
    arrows: false,
  };
  const sliderRef = useRef(null);

  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const {
    data: award,
    isloaded,
    errors,
  } = useFetch(`api/v2/guest/award/${id}`);

  if (errors) {
    Swal.fire({
      title: "Error",
      text: "Award Not Found",
      icon: "error",
    }).then((_) => {
      navigate("/awards");
    });
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
  }, []);

  // eslint-disable-next-line array-callback-return
  const categories = award?.categories?.filter((category) => {
    if (searchTerm === "") {
      return category;
    } else if (
      category?.title
        .toLocaleLowerCase()
        .includes(searchTerm.toString().toLocaleLowerCase())
    ) {
      return category;
    }
  });

  return (
    <>
      <HelmetData
        title={award?.title || "OpenCastGh"}
        imageSrc="/logo192.png"
        description={
          "OpenCastGH an online awards voting and event ticketing platform"
        }
        url={`https://opencastgh.com/award/${id}/categories`}
      />

      <Nav />
      <div className="award-div d-flex">
        <div className="award-img-bg">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${award?.image}`}
            className="img-thumbnail"
            alt="award"
          />
        </div>
        <div className="award-img-title">
          <h1 className="text-white text-capitalize" title="Award Title">
            {award?.title || "Loading, Please Wait..."}
          </h1>
        </div>
      </div>
      <div className="advert-margin search-margin">
        <div className="col-lg-12">
          <div className="text-center">
            <h1>
              <i>Select Category / Contestant</i>
            </h1>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="search-cover">
            <input
              type="text"
              className="form-control rounded-pill search-text"
              placeholder="Search by Category, Nominee Name or Code"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value.toLocaleLowerCase());
                setSelectedCategory("");
              }}
            />
            <i className="fas fa-search search-icon"></i>
          </div>
        </div>
      </div>
      <div className="advert-margin mt-md-4 mt-2">
        {categories?.length > 0 ? (
          <>
            <div className="col-lg-12">
              <div className="row">
                <div className="d-md-flex justify-content-between">
                  <div className="text-margin-start12 text-center">
                    <h3>
                      Available Categories (Click on a category to see it's
                      Nominees)
                    </h3>
                  </div>
                  <div className="float-end">
                    <img
                      src="/assets/images/arrow-left1.png"
                      alt="arrow"
                      onClick={() => sliderRef.current.slickPrev()}
                      className="cur-pointer cur-width"
                    />
                    <img
                      src="/assets/images/arrow-right1.png"
                      alt="arrow"
                      onClick={() => sliderRef.current.slickNext()}
                      className="ms-3 cur-pointer cur-width"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <section className="slider variable">
                <Slider ref={sliderRef} {...settings}>
                  {categories?.map((category, i) => {
                    return (
                      <AwardCategoryCard
                        key={i}
                        title={category?.title}
                        image={award?.image}
                        status={"Click to view Contestants"}
                        id={category?.id}
                        setSelectedCategory={setSelectedCategory}
                      />
                    );
                  })}
                </Slider>
              </section>
            </div>
          </>
        ) : (
          <></>
        )}
        <Nominees
          isloaded={isloaded}
          award={award}
          searchTerm={searchTerm}
          selectedCategory={selectedCategory}
        />
      </div>
      <Footer />
    </>
  );
};
export default AwardCategory;
