import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/Button";

const EditBulkVote = () => {
  const { id } = useParams(); // Bulk Vote ID
  const { award_id } = useParams(); // Award ID
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [errors, setErrors] = useState();

  useEffect(() => {
    axios
      .get(`/api/v2/bulk-vote/${id}/award/${award_id}`)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setUserInput(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`/api/v2/bulk-vote/${id}/update`, {
        award_id: award_id,
        status: userInput.status,
        votes: userInput.votes,
        amount: userInput?.amount,
      })
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          let msg = res.data.msg;
          Swal.fire({
            title: "Success",
            text: msg,
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          }).then(() => {
            navigate(`/dashboard/award/${userInput?.award_id}/bulk-voting`);
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 422) {
          setErrors(err?.response?.data?.errors);
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      })
      .finally(() => setLoading(false));
  };

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="page-wrapper">
      <div className="content container">
        <div className="page-header"></div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="card card-shadow">
              <div className="card-header">
                <div className="d-flex justify-content-between ">
                  <h3>Update Bulk Votes</h3>
                  <div>
                    <Link
                      to={`/dashboard/award/${userInput?.award_id}/bulk-voting`}
                      className="btn btn-primary float-right veiwbutton"
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row formtype">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Number of Votes *</label>
                              <input
                                className="form-control"
                                type="text"
                                value={userInput?.votes}
                                name="votes"
                                onChange={handleUserInput}
                                required
                              />

                              <small className="text-danger">
                                {errors?.votes}
                              </small>
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <label>Amount</label>
                              <input
                                className="form-control"
                                type="number"
                                value={userInput?.amount}
                                name="amount"
                                onChange={handleUserInput}
                                required
                              />

                              <small className="text-danger">
                                {errors?.amount}
                              </small>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Status</label>
                              <select
                                className="form-control"
                                required
                                value={userInput.status}
                                name={"status"}
                                onChange={handleUserInput}
                              >
                                <option value="active">Active</option>
                                <option value="blocked">Blocked</option>
                              </select>
                              <small className="text-danger">
                                {errors?.status}
                              </small>
                            </div>
                          </div>
                          <Button
                            buttonText={"Update Bulk Vote"}
                            isloading={isloading}
                            cssClasses="btn btn-primary bg-default buttonedit1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditBulkVote;
