import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import NewPasswordForm from "./forgotPasswordComponents/NewPasswordForm";
import ResetVerificationForm from "./forgotPasswordComponents/ResetVerifcationForm";
import "./styles/authDesktop.css";
import "./styles/authMobile.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
const ForgotPassword = () => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [current, setCurrent] = useState(1);
  const [token,setToken]=useState('');

  const requestVerificationCode = (e) => {
    e.preventDefault();
    setProcessing(true);
    if (!phone || phone === "") {
      setError("Please enter your phone number");
    } else {
      axios
        .post("/api/v2/auth/request-verification-code", {
          phone: phone,
        })
        .then((res) => {
          setProcessing(false);
          if (res?.data?.status === 200) {
            setCurrent(2);
          }
        })
        .catch((err) => {
          console.log(err.response);
          setProcessing(false);
          if (err?.response?.data?.message ||err?.response?.data?.error_msg) {
            setError(err?.response?.data?.message ||err?.response?.data?.error_msg);
          } else {
            setError("Error sending verification code");
          }
        });
    }
  };

  useEffect(() => {
    setError("");
  }, [phone]);

  return (
    <div className="container container-position">
      <div className="row d-flex">
        <div className="col-lg-6 pad-right">
          <div className="illustration">
            <div className="illust-img">
              <img
                src="/assets/images/C.webp"
                alt="opencastgh"
                className="img-fluid"
              />
            </div>
            <div className="illus-img">
              <img
                src="/assets/img/service.png"
                alt="opencastgh"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6 pad-left">
          <div className="container-wrapper">
            {current === 1 && (
              <div className="p-lg-4 d-flex flex-column h-100 justify-content-center">
                <div className="mb-2">
                  <Link to="/login">
                    <img src="/assets/images/arrow-left1.png" loading="lazy" alt="arrow"  />
                  </Link>
                </div>
                <h1 className="text-center my-5">Forgot Password?</h1>
                <h4 className="rec-text text-center">
                  Enter your account's phone number to reset password
                </h4>
                <div className="left d-flex flex-row position-relative mt-4">
                  <PhoneInput
                    country="GH"
                    defaultCountry="GH"
                    countries={["GH"]}
                    value={phone}
                    onChange={setPhone}
                    className="form-control phone-input"
                    placeholder="phone"
                    error={
                      phone
                        ? isPossiblePhoneNumber(phone)
                          ? ""
                          : "Invalid phone number"
                        : "Phone number required"
                    }
                  />
                </div>
                <div className="text-danger">{error}</div>
                <div className="d-flex flex-row justify-content-center mt-4">
                  <Button
                    cssClasses={"submit"}
                    isloading={processing}
                    buttonText="Continue"
                    callback={requestVerificationCode}
                  />
                </div>
                <div className="d-flex flex-row justify-content-center mt-4">
                  <Link to="/login">Login Instead</Link>
                </div>
              </div>
            )}
            {current === 2 && (
              <ResetVerificationForm setCurrent={setCurrent} phone={phone} setToken={setToken}/>
            )}
            {current === 3 && (
             
                <NewPasswordForm
                  setCurrent={setCurrent}
                  phone={phone}
                  token={token}
                />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
