import React from "react";
import Button from "../../../../components/Button";
import { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export default function TicketPurchasForm({
  tickets,
  userInput,
  handleUserInput,
  addTicketToCart,
  errors,
  isloading,
  ticketCart,
  phoneNumber,
  setPhoneNumber,
}) {
  const [ticketDescription, setTicketDescription] = useState("");

  return (
    <div className="card card-shadow card-border-radius ticket-h">
      <div className="card-body">
        <form
          onSubmit={(e) => {
            addTicketToCart(e);
            setTicketDescription("");
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row formtype">
                  <div className="col-md-12">
                    <div className="form-group mt-2">
                      <label>Full Name *</label>
                      <input
                        className="form-control"
                        type="text"
                        value={userInput?.name}
                        name="name"
                        onChange={handleUserInput}
                        required
                      />
                      <small className="text-danger">{errors?.name}</small>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Receipient's Phone Number *</label>
                      <PhoneInput
                        country="GH"
                        defaultCountry="GH"
                        // countries={["GH"]}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        className="form-control text-left"
                      />
                      <small className="text-danger">{errors?.phone}</small>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Receipient's Email (Optional)</label>
                      <input
                        className="form-control"
                        type="email"
                        value={userInput?.email}
                        onChange={handleUserInput}
                        name="email"
                      />
                      <small className="text-danger">{errors?.email}</small>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Select Ticket</label>
                      <select
                        className="form-control"
                        // id="sel2"
                        name="ticket_id"
                        value={userInput?.ticket_id}
                        onChange={(e) => {
                          handleUserInput(e);
                          setTicketDescription(
                            tickets?.find(
                              (ticket) =>
                                Number(ticket?.id) === Number(e.target.value)
                            )?.description
                          );
                        }}
                        required
                      >
                        <option value="">--Select Ticket--</option>
                        {tickets
                          ?.filter(
                            (ticket) =>
                              ticket?.status?.toLowerCase() === "active"
                          )
                          ?.map((ticket, index) => (
                            <option
                              key={index}
                              value={ticket?.id}
                              className="text-capitalize"
                            >
                              {ticket?.title} <b>(GHS {ticket?.price})</b>
                            </option>
                          ))}
                      </select>
                      <small className="text-danger">{errors?.ticket_id}</small>
                      <div className="mt-2">{ticketDescription}</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Quantity</label>
                      <input
                        className="form-control"
                        type="number"
                        value={userInput?.quantity}
                        onChange={handleUserInput}
                        name="quantity"
                        min={1}
                      />
                      <small className="text-danger">{errors?.quantity}</small>
                    </div>
                  </div>
                  <div className="col-md-12 mb-4 mt-lg-3">
                    <Button
                      buttonText={"ADD"}
                      cssClasses="btn btn-primary bg-default buttonedit1 w-100"
                    />
                  </div>
                  <div className="col-12 d-md-none">
                    {isloading ? (
                      <Button
                        cssClasses="btn btn-outline-primary float-end btn-size"
                        isloading={isloading}
                      />
                    ) : (
                      ticketCart?.length > 0 && (
                        <a
                          data-toggle="modal"
                          href="#payment-modal"
                          className="btn btn-outline-primary float-end btn-size w-100"
                        >
                          Proceed To Payment
                        </a>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
