import React, { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../../../../../components/Pagination";
import TableRows from "./TableRows";

export default function TicketTable({ event, reloadTable, setReloadTable }) {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = event?.tickets?.slice(indexOfFirstPost, indexOfLastPost);



  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="card-header-padding">
        <h5>Available Tickets</h5>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="w-100 mb-2">
                <input
                  type="text"
                  className="form-control text-center"
                  placeholder="Search Ticket"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex justify-content-between mt-1">
                <div className="w-100">
                  <Link
                    to={`/dashboard/event/${event?.id}/ticket/create`}
                    className="btn btn-primary veiwbutton w-100"
                  >
                    Add New Ticket
                  </Link>
                </div>
                <div className="w-100">
                  <Link
                    to={`/dashboard/event/${event?.code}`}
                    target={"_blank"}
                    className="btn btn-outline-success veiwbutton w-100 ms-2"
                  >
                    <span>
                      <i className="fas fa-eye px-2"></i>
                    </span>
                    Live Preview
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <table
        id="example"
        className="datatable table-stripped table table-hover table-center mb-0"
      >
        <thead>
          <tr>
            <th>Title</th>
            <th>Price</th>
            <th>Ticket Slots</th>
            <th>Slots Sold</th>
            <th>Slots Left</th>
            <th>Revenue</th>
            <th>Status</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts
            ?.filter((item) =>
              item?.title.toLowerCase().includes(search.toLowerCase())
            )
            ?.map((ticket, index) => (
              <TableRows
                ticket={ticket}
                event_id={event?.id}
                key={index}
                reloadTable={reloadTable}
                setReloadTable={setReloadTable}
              />
            ))}
        </tbody>
      </table>
      <div className="pagin-pos">
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={event?.tickets?.length}
          paginate={paginate}
        />
      </div>
    </>
  );
}
