import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../components/customHooks/useFetch";
import LoadingPage from "../../LoadingPage";
import CardItem from "../components/CardItem";
import Footer from "../partials/Footer";
import Nav from "../partials/Nav";

const ResultsPage = () => {
  // const { id } = useParams();
  useEffect(() => {
    window.location.replace("https://opencastgh.com/results");
  });
  const [searchTerm, setSearchTerm] = useState("");
  const {
    data: awards,
    isloaded,
    errors,
  } = useFetch("/api/v2/guest/awards/all");

  if (errors) {
    Swal.fire({
      title: "Error",
      text: "Failed to Load Awards",
      icon: "error",
    });
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
    setSearchTerm("");
  }, []);

  const filter = awards?.filter((award) => {
    const isVoting =
      award?.status === "voting" && award?.results === "published";
    const hasTitle = award?.title
      ?.toLocaleLowerCase()
      .includes(searchTerm?.toLocaleLowerCase());

    return isVoting && (searchTerm === "" || hasTitle);
  });

  return (
    <>
      <Nav />
      <div className="award-div d-flex">
        <div className="award-img-bg">
          {/* <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${award?.image}`}
            className="img-thumbnail"
            alt="award"
          /> */}
        </div>
        <div className="award-img-title">
          <h1 className="text-white text-capitalize">
            Ongoing Awards (Results)
          </h1>
        </div>
      </div>
      <div className="advert-margin mt-3">
        <div className="col-lg-12">
          <div className="text-center">
            <h3>
              <i>Search For Award</i>
            </h3>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="search-cover">
            <input
              type="text"
              className="form-control rounded-pill search-text"
              placeholder="Search for award here..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value.toLocaleLowerCase());
              }}
            />
            <i className="fas fa-search search-icon"></i>
          </div>
        </div>
      </div>

      {isloaded ? (
        <div className="container-fluid">
          <div className="mt-4">
            {filter?.length > 0 ? (
              <div className="row advert-margin">
                {filter?.map((award, i) => (
                  <div
                    className="col-custom-2 col-md-3"
                    id="custom-col"
                    key={i}
                  >
                    <Link to={`/results/award/${award?.id}/categories`}>
                      <CardItem image={award?.image} title={award?.title} />
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <div className="row">
                <h4 className="text-center">No Award is Available</h4>
              </div>
            )}
          </div>
        </div>
      ) : (
        <LoadingPage />
      )}

      <Footer />
    </>
  );
};
export default ResultsPage;
