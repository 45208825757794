import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../../partials/Footer";
import Nav from "../../partials/Nav";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import Button from "../../../../components/Button";
import Spinners from "../../../../components/Spinners";
import HelmetData from "../../../../components/HelmetData";

const VotePayment = () => {
  const navigate = useNavigate();
  const [nominee, setNominee] = useState();
  const { nominee_code } = useParams();
  const [price_per_vote, setPricePerVote] = useState();
  const [paymentMode, setPaymentMode] = useState("card");
  const [network, setNetwork] = useState("");
  const [errors, setErrors] = useState();
  const [momoNumber, setMomoNumber] = useState();
  const [number_of_votes, setNumberofVotes] = useState(0);
  const [amount, setAmount] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }

    setLoading(true);
    axios
      .get(`/api/v2/guest/nominee/${nominee_code}`)
      .then((res) => {
        if (res?.data?.status === 200 && res.data.success === true) {
          setNominee(res?.data?.data);
          setPricePerVote(res?.data?.data?.award?.price_per_vote);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 400) {
          // const msg = err?.response?.error_msg;
          Swal.fire({
            title: "Not Found",
            text: "Nominee/Contestant Not Available",
            icon: "info",
            confirmButtonColor: "#011535",
          }).then((_) => {
            navigate(-1);
          });
        }
      });
  }, [navigate, nominee_code]);

  useEffect(() => {
    const amount_payable = Number(number_of_votes) * Number(price_per_vote);
    setAmount(amount_payable);
  }, [number_of_votes, price_per_vote]);

  useEffect(() => {
    const votes = Number(amount) / Number(price_per_vote);
    setNumberofVotes(votes);
  }, [amount, price_per_vote]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(momoNumber)) {
      window.alert("Please Enter a valid phone number");
      return;
    }
    setSubmitting(true);

    const data = {
      payment_mode: paymentMode,
      award_id: nominee?.award?.id,
      nominee_id: nominee?.id,
      payment_amount: amount,
      network: network,
      momo_number: momoNumber,
    };
    axios
      .post("/api/v2/guest/vote-payment", data)
      .then((res) => {
        if (res?.data?.success === true && res?.data?.status === 200) {
          if (paymentMode === "momo") {
            navigate(`/award/${nominee?.award?.id}/categories`);
            Swal.fire({
              title: "Approval Required",
              icon: "info",
              text: "To Complete Payment:You'll Recieve A Prompt To Enter Your Pin To Approve This Transaction, if MTN Prompt Delays, Dial *170#, Select Option 6, And Then Option 3 To Approve This Transaction.",
            });
          }

          if (paymentMode === "card") {
            window.location.replace(res?.data?.data?.authorization_url);
          }
        }
      })
      .catch((err) => {
        console.log(err?.response);
        setSubmitting(false);
        if (
          err?.response?.status === 400 &&
          err?.response?.data?.success === false
        ) {
          setErrors(err?.response?.data?.error_msg);
        } else {
          Swal.fire({
            title: "Trasaction Failed",
            text: "Error Processing Payment, Please Try Again Later",
            icon: "error",
          });
        }
      });
  };

  return (
    <>
      <HelmetData
        title={
          nominee?.stage_name ||
          nominee?.full_name ||
          nominee?.award?.title ||
          "OpenCastGh"
        }
        imageSrc={`${process.env.REACT_APP_BACKEND_URL}/${
          nominee?.is_award_image === "true"
            ? nominee?.award?.image
            : nominee?.image
        }`}
        description="With your support, I can win this. Please vote for me!"
        url={`https://opencastgh.com/awards/${nominee?.nominee_code}`}
      />
      <Nav />
      {isloading === false ? (
        <>
          <div className="award-div d-flex">
            <div className="award-img-title">
              <h1 className="text-white text-capitalize" title="Award Title">
                {nominee?.award?.title}
              </h1>
            </div>
          </div>

          <div className="container mt-3">
            <div className="row card-margin-top">
              <div className="col-lg-4 col-md-4 mt-1">
                <div className="card card-shadow">
                  <div className="card-body">
                    {/* <figure> */}
                    <img
                      className="img-fluid img-thumbnail"
                      src={`${process.env.REACT_APP_BACKEND_URL}/${
                        nominee?.is_award_image === "true"
                          ? nominee?.award?.image
                          : nominee?.image
                      }`}
                      width="100%"
                      alt="Award"
                    />
                    {/* </figure> */}
                  </div>
                  <div className="mx-2 my-2">
                    <div className="mb-2">
                      Name :{" "}
                      <span>
                        <b className="text-capitalize">{nominee?.full_name}</b>
                      </span>
                    </div>
                    <div className="mb-2">
                      Stage Name :{" "}
                      <span>
                        <b className="text-uppercase">
                          {nominee?.stage_name || "N/A"}
                        </b>
                      </span>
                    </div>
                    <div className="mb-2">
                      Category :{" "}
                      <span>
                        <b>{nominee?.category?.title}</b>
                      </span>
                    </div>
                    <div className="mb-3">
                      Nominee Code :{" "}
                      <span>
                        <b className="text-uppercase">
                          {nominee?.nominee_code}
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-8 mt-1">
                <div className="content">
                  {nominee?.award?.status === "voting" ? (
                    <div className="card card-shadow">
                      <div className="card-header fill-font">
                        <h3>Fill Entries to Make Vote Payment</h3>
                      </div>
                      <div className="card-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">
                              <form onSubmit={handleSubmit}>
                                <div className="row formtype">
                                  <div className="col-md-12">
                                    <div className="col-md-12 mb-3">
                                      <div className="form-group">
                                        <label>Amount Per Vote</label>
                                        <input
                                          className="form-control"
                                          type="number"
                                          name="amount_per_vote"
                                          value={price_per_vote}
                                          required
                                          readOnly
                                        />
                                        <div className="text-danger">
                                          {errors?.price_per_vote}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <div className="form-group">
                                        <label>Enter Amount</label>
                                        <input
                                          className="form-control"
                                          type="number"
                                          step="any"
                                          name="amount"
                                          required
                                          value={amount}
                                          min={price_per_vote}
                                          onChange={(e) =>
                                            setAmount(e.target.value)
                                          }
                                        />
                                        <div className="text-danger">
                                          {errors?.payment_amount}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <div className="form-group">
                                        <label>Number of Votes</label>
                                        <input
                                          className="form-control"
                                          type="number"
                                          name="number_of_votes"
                                          value={number_of_votes}
                                          onChange={(e) =>
                                            setNumberofVotes(e.target.value)
                                          }
                                          required
                                          min={1}
                                        />
                                        <div className="text-danger">
                                          {errors?.number_of_votes}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div>
                                      <div className="col-md-12 mb-3">
                                        <div className="form-group">
                                          <label>Payment Mode</label>
                                          <select
                                            className="form-control"
                                            required
                                            name="payment_mode"
                                            value={paymentMode}
                                            onChange={(e) =>
                                              setPaymentMode(e.target.value)
                                            }
                                          >
                                            <option value="">
                                              --Choose Payment Channel--
                                            </option>
                                            {/* <option value="momo">
                                              Direct Payment(Momo)
                                            </option> */}
                                            <option value="card">
                                              Online Payment(MOMO,Card)
                                            </option>
                                          </select>
                                          {paymentMode === "momo" && (
                                            <small>
                                              Direct Payment allows you to make
                                              payment directly from your momo
                                              wallet
                                            </small>
                                          )}
                                          {paymentMode === "card" && (
                                            <small>
                                              Alternate Payment allows you make
                                              payment via Credit Card,Master
                                              Card or GhQR
                                            </small>
                                          )}
                                        </div>
                                      </div>
                                      {paymentMode === "momo" && (
                                        <>
                                          <div className="col-md-12 mb-3">
                                            <div className="form-group">
                                              <label>Network</label>
                                              <select
                                                className="form-control"
                                                required
                                                id="sel2"
                                                name="network"
                                                value={network}
                                                onChange={(e) =>
                                                  setNetwork(e.target.value)
                                                }
                                              >
                                                <option value="">
                                                  ---Choose Network---
                                                </option>
                                                <option value="MTN-GH">
                                                  MTN-GH
                                                </option>
                                                <option value="VODAFONE-GH">
                                                  VODAFONE-GH
                                                </option>
                                                <option value="TIGO-GH">
                                                  TIGO-GH
                                                </option>
                                                <option value="AIRTEL-GH">
                                                  AIRTEL-GH
                                                </option>
                                              </select>
                                            </div>
                                            <div className="text-danger">
                                              {errors?.network}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div className="col-md-12 mb-3">
                                        <div className="form-group">
                                          <label>Mobile Money Number</label>
                                          <PhoneInput
                                            country="GH"
                                            defaultCountry="GH"
                                            // countries={["GH"]}
                                            value={momoNumber}
                                            onChange={setMomoNumber}
                                            className="form-control"
                                            error={
                                              momoNumber
                                                ? isPossiblePhoneNumber(
                                                    momoNumber
                                                  )
                                                  ? ""
                                                  : "Invalid phone number"
                                                : "Phone number required"
                                            }
                                          />
                                          <div className="text-danger">
                                            {momoNumber
                                              ? isValidPhoneNumber(momoNumber)
                                                ? ""
                                                : "Invalid phone number"
                                              : "Phone number required"}
                                            {errors?.momo_number}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="container">
                                  <div className="row align-items-center justify-content-center">
                                    <Button
                                      cssClasses={"btn btn-primary  bg-default"}
                                      buttonText="Proceed to Payment"
                                      isloading={isSubmitting}
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="card card-shadow mt-5">
                      <div className="card-header">
                        <h3>This award isn't active/opened for voting</h3>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="card card-shadow mt-5 d-flex align-items-center justify-content-center">
          <div className="card-header">
            <h3 className="text-center">
              {Spinners.threeDots} <span>Loading... Please Wait...</span>
            </h3>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default VotePayment;
