import React,{useState,useEffect} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../../components/Button";
import axios from "axios";
import Swal from "sweetalert2";

export default function EditCategory() {
  const { award_id, category_id } = useParams();
  const [userInput, setUserInput] = useState([]);
  const navigate = useNavigate();
  const [isloading, setLoading] = useState();
  const [errors,setErrors]=useState();
  

  useEffect(()=>{
    axios.get(`/api/v2/categories/${category_id}`)
    .then(res=>{
      if(res.data.success===true && res.data.status===200){
        setUserInput(res.data.data);
      }
    }).catch(err=>{
      console.log(err.response)
    })
  },[category_id])

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      title: userInput?.title,
      capacity: userInput.capacity,
      gender: userInput.gender,
      price: userInput.price,
      category_id:category_id
    };
    axios
      .post("/api/v2/categories/update", data)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setLoading(false);
          let msg = res.data.msg;
          Swal.fire({
            title: "Success",
            text: msg,
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          }).then(() => {
            navigate(`/dashboard/award/${award_id}/categories`);
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        if (err.response.status === 400) {
          setErrors(err?.response?.data?.errors);
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

 

  return (
    <div className="page-wrapper">
      <div className="content container">
        <div className="page-header"></div>

        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="p-2 h4">Update Category Details</div>
                    <div className="p-2">
                      <Link
                        to={`/dashboard/award/${award_id}/categories`}
                        className="btn btn-primary"
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="container mt-3">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row formtype">
                          <div className="col-md-12">
                            <div className="form-group">
                              <span>Category Title</span>
                              <input
                                className="form-control"
                                type="text"
                                value={userInput?.title}
                                name="title"
                                onChange={handleUserInput}
                                placeholder="Category Title"
                                required
                              />
                              <small className="text-danger">
                                {errors?.title}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <span>Slots Available</span>
                              <input
                                type="number"
                                name="capacity"
                                required
                                placeholder="Slots Available for this category"
                                className="form-control"
                                id="usr"
                                min={1}
                                value={userInput?.capacity}
                                onChange={handleUserInput}
                              />
                              <small className="text-danger">
                                {errors?.capacity}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <span>Gender Type</span>
                              <select
                                className="form-control"
                                id="sel2"
                                name="gender"
                                value={userInput?.gender}
                                onChange={handleUserInput}
                                required
                              >
                                <option value="">--Select Gender--</option>
                                <option value="all">All</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                              <small className="text-danger">
                                {errors?.gender}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <span>Price for Nomination</span>
                              <input
                                type="number"
                                required
                                name="price"
                                value={userInput?.price}
                                onChange={handleUserInput}
                                min="0"
                                placeholder="set to 0 if nomination is free"
                                className="form-control"
                                id="usr"
                              />

                              <small className="text-danger">
                                {errors?.price}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Button
                      buttonText="Create Category"
                      isloading={isloading}
                      cssClasses={
                        "btn btn-primary mb-3 form-control text-white"
                      }
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
