const Services = () => {
  return (
    <div className="banner-bottom-bg">
      <div className="container container-index">
        <div className="text-center featured-event featured-even">
          <h1>
            OpenCastGh... <i>Do it the smart way !</i>
          </h1>
        </div>
        <div className="row justify-content-center margin-left-row center-items my-5">
          <div className="col-lg-4 col-md-3 col-12">
            <div className="text-position-center">
              <div className="featured-events-cover">
                <img
                  src="/assets/images/Award.webp"
                  alt="Award"
                  className="img-responsive img1"
                />
              </div>
              <div className="text-margin-start1">
                <h3>Awards</h3>
              </div>
              <div className="text-display">
                <h5>
                  Run your awards voting easily{" "}
                  <span>with us.We provide you with a</span> USSD platform for
                  voting, SMS <span>for vote confirmation, and a</span> portal
                  that allows you to <span> monitor your votes and</span>{" "}
                  payments
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-3 col-12">
            <div className="text-position-center">
              <div className="featured-events-cover">
                <img
                  src="/assets/images/Donation.webp"
                  alt="donation"
                  className="img-responsive img2"
                />
              </div>
              <div className="text-margin-start1">
                <h3>USSD</h3>
              </div>
              <div className="text-display">
                <h5>
                  with our USSD Short Code, You{" "}
                  <span>can easily vote for your</span>
                  favorite contestant, or{" "}
                  <span>purchase tickets for your </span>
                  cherished events without{" "}
                  <span>worrying about internet or </span> smart phone, just
                  dial{" "}
                  <span>
                    <b>*920*104#</b>
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-3 col-12">
            <div className="text-position-center">
              <div className="featured-events-cover">
                <img
                  src="/assets/images/ticket.webp"
                  alt="ticket"
                  className="img-responsive img3"
                />
              </div>
              <div className="text-margin-start2">
                <h3>Ticketing</h3>
              </div>
              <div className="text-display">
                <h5>
                  Are you an event organizer,
                  <span> looking for a smart way to sell</span>
                  your event tickets? Then,
                  <span> we are here for you. With </span>
                  OpenCastGh, selling of <span>event tickets now </span>easier.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
