import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const PaymentChannels = () => {
    const settings = {
        infinite: true,
        autoplay: true,
        variableWidth: true,
        speed: 700,
        nextArrow: (
            <img
                src="/assets/images/arrow-right1.webp"
                alt="arrow"
                className="arrow-right-pos slick-next"
            />
        ),
        prevArrow: (
            <img
                src="/assets/images/arrow-left1.webp"
                alt="arrow"
                className="arrow-left-pos slick-next"
            />
        ),
    };

    return (
        <div className="col-lg-12">
            <div className="row advert-margin mt-lg-5 mt-3">
                <div className="col-lg-12 mb-lg-2">
                    <div className="text-center featured-event2">
                        <h1><i>Our Payment Channels</i></h1>
                    </div>
                </div>
                <div className="col-lg-12">
                    <section className="slider variable">
                        <Slider {...settings}>
                                <div className="channels">
                                    <img src="/assets/images/p1.webp" loading="lazy" alt="payment channel" />
                                </div>
                                <div className="channels">
                                    <img src="/assets/images/p2.webp" loading="lazy" alt="payment channel"/>
                                </div>
                                <div className="channels">
                                    <img src="/assets/images/p3.webp" loading="lazy" alt="payment channel"/>
                                </div>
                                <div className="channels">
                                    <img src="/assets/images/p4.webp" loading="lazy" alt="payment channel"/>
                                </div>
                                <div className="channels">
                                    <img src="/assets/images/p5.webp" loading="lazy" alt="payment channel"/>
                                </div>
                                <div className="channels">
                                    <img src="/assets/images/p6.webp" loading="lazy" alt="payment channel"/>
                                </div>
                                <div className="channels">
                                    <img src="/assets/images/p7.webp" loading="lazy" alt="payment channel"/>
                                </div>
                        </Slider>
                    </section>
                </div>
            </div>
        </div>
    )
}
export default PaymentChannels;