import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


const Footer = () => {
  const showContactInfo = () => {
    Swal.fire({
      title: "Contact Information",
      html: `
              <p><strong>Name:</strong>Samasoft Technologies</p>
              <p><strong>Email:</strong> info@opencastgh.com</p>
              <p><strong>Website:</strong> https://samasoftech.net</p>
              <p><strong>Phone:</strong> (+233) 558-571228 / (+233) 554-922935</p>
              <hr />
              <p><strong>Follow us on:</strong></p>
              <div classname="d-flex flex-row justify-content-center">
                <a href="https://www.facebook.com/profile.php?id=100083505109917&mibextid=ZbWKwL" target="_blank">
                    <i className="fa fa-facebook-square"></i> Facebook
                </a>
                
                <a href="https://www.instagram.com/opencastgh" target="_blank">
                    <i className="fa fa-instagram"></i> Instagram
                </a>
                
                <a href="https://twitter.com/SAMZY90234896" target="_blank">
                    <i className="fa fa-twitter-square"></i>&nbsp; Twitter
                </a>
              </div>
            `,
      showCancelButton: true,
      confirmButtonText: "Close",
    });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row padding-row advert-margin footer-mg-top">
          <div className="col-md-3 footer-icon">
            <img src="/assets/images/logo1.png" alt="icon" loading="lazy" />
            <div className="footer-text mt-3">
              Opencast<span>GH</span> is a multipurpose platform which provides users with the smart way of running their awards voting and selling of event tickets.
            </div>
            <div className="mt-3 subtitle2 text-left">Get the app!</div>
            <div className="d-flex mt-3">
              <div className='footer-icon-img'>
                <a href={()=>{}} target="_blank" rel="noreferrer" >
                  <img src="/assets/images/ios.png" loading="lazy" alt="Icon" />
                </a>
              </div>
              <div className='footer-icon-img ms-2'>
                <a href={()=>{}} target="_blank" rel="noreferrer" >
                  <img src="/assets/images/andriod.png" loading="lazy" alt="icon"/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <h3 className="footer-text-title mt-small">Want to know more ?</h3>

            <ul className="footer-links text-left1">
              {/* <li>
                <a href="#">FAQs</a>
              </li> */}
              <li>
                <a href={()=>{}} onClick={showContactInfo}>Contact Us</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h3 className="footer-text-title">More About Us</h3>

            <ul className="footer-links text-left1">
              {/* <li>
                <a href="#">Terms of Sale</a>
              </li> */}
              <li>
                <Link to="/terms-of-use">Terms of Service</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              {/* <li>
                <a href="#">Promotion Terms</a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-2">
            <h3 className="footer-text-title">Social Links</h3>

            <ul className="footer-links text-left1">
              {/* <li>
                <a href="#">Blog</a>
              </li> */}
              <li>
                <a href="https://www.facebook.com/profile.php?id=100083505109917&mibextid=ZbWKwL" target="_blank" rel="noreferrer noopener">Facebook</a>
              </li>
              <li>
                <a href="https://www.instagram.com/opencastgh" target="_blank" rel="noreferrer noopener">Instagram</a>
              </li>
              <li>
                <a href="https://twitter.com/SAMZY90234896" target="_blank" rel="noreferrer noopener">Twitter</a>
              </li>
              {/* <li>
                <a href="#">LinkedIn</a>
              </li> */}
              {/* <li>
                <a href="#">Twitter</a>
              </li> */}
              {/* <li>
                <a href="#">Youtube</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-4 footer">
        <div className="row">
          <div className="text-center bottom-font">
            &copy;2020 All Rights Reserved by Samasoft Technologies
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
