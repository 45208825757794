import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const AwardCard = ({ award, reload, setReload }) => {
  return (
    <div className="col-lg-3 col-md-4">
      <div className="card mb-5 mb-lg-3">
        <AwardStatusActions
          status={award?.status}
          id={award?.id}
          reload={reload}
          setReload={setReload}
        />
        <div className="card-body">
          <figure>
            <img
              className="img-thumbnail"
              width="100%"
              src={`${process.env.REACT_APP_BACKEND_URL}/${award?.image}`}
              alt="Event"
            />
          </figure>
          <div className="container">
            <div className="row mb-3">
              <div className="col-md-12">
                <div
                  className="h5 text-center text-capitalize textElipse"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={award?.title}
                >
                  {award?.title}
                </div>
                <div className="p-2 row">
                  {award?.status !== "ended" && (
                    <Link
                      to={`/dashboard/award/${award?.id}/edit`}
                      className="btn mt-2  btn-primary text-uppercase text-nowrap"
                    >
                      Edit Award
                    </Link>
                  )}
                  <Link
                    to={`/dashboard/award/${award?.id}/categories`}
                    className="btn mt-2  btn-primary text-uppercase text-nowrap"
                  >
                    Manage Categories
                  </Link>
                  <Link
                    to={`/dashboard/award/${award?.id}/nominees`}
                    className="btn mt-2  btn-primary text-uppercase text-nowrap"
                  >
                    Manage Nominees
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardCard;

const AwardStatusActions = ({ status, id, reload, setReload }) => {
  const handleGoLive = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Question",
      text: "This action will open this award event for voting, Do you want to proceed on with this action?",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showDenyButton: true,
      denyButtonText: "No",
      confirmButtonColor: "#011535",
      icon: "question",
    }).then((feedback) => {
      if (feedback.isConfirmed) {
        axios
          .post("/api/v2/awards/go-live", { award_id: id })
          .then((res) => {
            if (res?.data?.status === 200) {
              setReload(!reload);
              Swal.fire("Success", "Award is live for voting", "success");
            }
          })
          .catch((err) => {
            setReload(!reload);
            const msg =
              err?.response?.data?.error_msg?.award ||
              "Award failed to go live,Kindly contact 0558571228 for support/assistance";
            Swal.fire("Failed", msg, "info");
          });
      }
    });
  };

  const handleEndVoting = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Question",
      text: "This action will close this award event for voting, Do you want to proceed on with this action?",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showDenyButton: true,
      denyButtonText: "No",
      confirmButtonColor: "#011535",
      icon: "question",
    }).then((feedback) => {
      if (feedback.isConfirmed) {
        axios
          .post("/api/v2/awards/end-award", { award_id: id })
          .then((res) => {
            if (res?.data?.status === 200) {
              setReload(!reload);
              Swal.fire(
                "Success",
                "Award has been closed for voting",
                "success"
              );
            }
          })
          .catch((err) => {
            setReload(!reload);
            const msg =
              err?.response?.data?.error_msg ||
              "Award failed to end,Kindly contact 0558571228 for support/assistance";
            Swal.fire("Failed", msg, "info");
          });
      }
    });
  };

  return (
    <>
      {status === "pending" && (
        <div className="card colk bg-danger text-white">
          <div className="card-body">Pending Approval</div>
        </div>
      )}

      {status === "active" && (
        <div className="card colk bg-success text-white">
          <button
            className="btn  btn-success bg-success border-0 card-body text-white"
            onClick={handleGoLive}
          >
            Go Live
          </button>
        </div>
      )}

      {status === "nominations" && (
        <div className="card colk bg-success text-white">
          <button
            className="btn  btn-success bg-success border-0 card-body text-white"
            onClick={handleGoLive}
          >
            Go Live
          </button>
        </div>
      )}
      {status === "voting" && (
        <div className="card colk bg-danger text-white">
          <div className="card-body">
            <button
              className="btn  btn-danger bg-danger border-0 card-body text-white"
              onClick={handleEndVoting}
            >
              End Voting
            </button>
          </div>
        </div>
      )}

      {status === "ended" && (
        <div className="card colk bg-success text-white">
          <button
            className="btn  btn-success bg-success border-0 card-body text-white"
            onClick={handleGoLive}
          >
            Re-activate voting
          </button>
        </div>
      )}
    </>
  );
};
