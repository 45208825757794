import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../../components/customHooks/useFetch";
import Loader from "../../../../components/helpers/Loader";
import CategoryTable from "./partials/CategoryTable";

const Categories = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [reloadTable, setReloadTable] = useState(false);
  const { data, isloaded, errors } = useFetch(
    `/api/v2/awards/${id}`,
    reloadTable
  );

  if (errors?.data?.status === 400) {
    const msg =
      errors?.data?.error_msg?.award ||
      "This award has already ended, kindly contact our support team for assistance/support";
    Swal.fire("Failed", msg, "info").then(() => {
      navigate(-1);
    });
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2 text-capitalize">
                  {data?.title}
                </h4>
                <Link
                  to={`/dashboard/award/${data?.id}/category/create`}
                  className="btn btn-primary float-right veiwbutton"
                >
                  Create New Category
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table ">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">
                    <h4>Available Categories</h4>
                  </div>
                  <div className="p-2">
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-secondary float-right"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body booking_card">
                {isloaded ? (
                  <div className="table-responsive">
                    <CategoryTable
                      categories={data?.categories}
                      award_id={id}
                      reloadTable={reloadTable}
                      setReloadTable={setReloadTable}
                    />

                    {data?.categories?.length === 0 && (
                      <div className="mt-5">
                        <h3 className="text-center">No Category Available</h3>
                        <div className="text-center mt-3">
                          <Link
                            to={`/dashboard/award/${data?.id}/category/create`}
                            className="btn btn-primary veiwbutton"
                          >
                            Create New Category
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
