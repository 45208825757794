import React from "react";

const CardItem = ({ image, title, status }) => {
  return (
    <div className="card card-h-2 me-3">
      <div className="card-img-cover2">
        <img
          src={`${process.env.REACT_APP_BACKEND_URL}/${image}`}
          alt="award"
        />
      </div>
      <div className="award-title-text mt-md-2 mt-3 text-center" title={title}>
        <h4>{title}</h4>
      </div>
      <div className="award-title-text1 mt-0 text-center">
        <h4>{status}</h4>
      </div>
      {/* <div className="award-title-text1 mt-1" title="Award Title">
          <h4>Award Title</h4>
        </div> */}
    </div>
  );
};

CardItem.defaultProps = {
  image: "assets/images/Banner5.jpg",
  title: "Award Title",
  status: "Active",
  className: "card-h-10"
};
export default CardItem;
