import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
const ExcelFile = ({ categories }) => {
    const fileType = "xlsx"
    const exportToCSV = () => {
        const ws = XLSX.utils.json_to_sheet(categories);
        const wb = { Sheets: {data:ws}, SheetNames: ["data"] }
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "AwardCategory.xlsx")
    }
    return (
        <button className="btn btn-primary" onClick={exportToCSV}>Export as Excel</button>
    )
}
export default ExcelFile;