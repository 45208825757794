import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../../components/customHooks/useFetch";
import Spinners from "../../../../components/Spinners";
import Multiselect from "multiselect-react-dropdown";

const ResultCategories = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const {
    data: award,
    isloaded,
    errors,
  } = useFetch(`/api/v2/awards/${id}/results/categories`);

  useEffect(() => {
    if (award) {
      setCategories(award?.categories);
    }
  }, [award]);

  errors?.data?.error_msg &&
    Swal.fire({
      title: "Failed",
      text: "Failed to load available categories",
      icon: "error",
    }).then((_) => {
      navigate(-1);
    });

  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCategorySelected = (selectedList, selectedItem) => {
    var items = selectedList?.map((item) => item.title.toLowerCase());
    setSelectedCategories(items);
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2">
                  Results Award Categories
                </h4>
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-primary float-right veiwbutton"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {isloaded ? (
            <div className="col-sm-12">
              {award?.categories?.length > 0 ? (
                <>
                  <section className="pricing py-5">
                    <h2
                      className="text-white text-lg-left text-sm-center text-center ms-4"
                      style={{ marginTop: "-3%" }}
                    >
                      Results Overview
                    </h2>
                    <p><small className="text-white ms-4">Click to View Results to see contestants with Zero(0) Votes</small></p>
                    <div className="container px-4 mb-3">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <Multiselect
                            options={categories}
                            className="multiselect bg-white"
                            displayValue="title"
                            placeholder="Filter By Category"
                            emptyRecordMsg="Category List Empty"
                            closeOnSelect={true}
                            onSelect={handleCategorySelected}
                            onRemove={(selectedList,selectedItem)=>{
                              var items=selectedCategories.filter((item)=>item.toLowerCase()!==selectedItem.title.toLowerCase()).map(item=>item)
                             setSelectedCategories(items);
                            }}
                          />
                        </div>
                        
                      </div>
                    </div>

                    <div className="container">
                      <div className="card px-2">
                        <div className="card-body table-responsive">
                          <table
                            width={"100%"}
                            className="table mt-2 table-bordered"
                          >
                            <thead>
                              <th className="text-center" colSpan={2}>
                                Categories
                              </th>
                              {/* <th></th> */}
                              <th>Action</th>
                            </thead>
                            <tbody>
                              {award?.categories
                                ?.filter((item) => {
                                  if (
                                    selectedCategories.length === 0 ||
                                    selectedCategories.includes(
                                      item?.title?.toLowerCase()
                                    )
                                  ) {
                                    return true;
                                  }
                                  return false;
                                })
                                ?.map((category, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td colSpan={2} className="text-center">
                                        <h5 className="text-primary">
                                          {category?.title}
                                        </h5>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/dashboard/award/${award?.id}/category/${category?.id}/results`}
                                          className="btn btn-block btn-primary text-uppercase"
                                        >
                                          View Results
                                        </Link>
                                      </td>
                                    </tr>
                                    {category?.votes?.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="text-uppercase">
                                            {item?.nominee_code}
                                          </td>
                                          <td className="text-capitalize">
                                            {item?.nominee?.stage_name ||
                                              item?.nominee?.full_name}
                                          </td>
                                          <td className="text-center">
                                            {" "}
                                            {item?.count || 0}{" "}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </React.Fragment>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <NoAwards />
              )}
            </div>
          ) : (
            <div className="bg-default">
              <h2 className="text-white text-center">
                {Spinners.rotatingLines} Loading... Please Wait...
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NoAwards = () => {
  return (
    <section className=" py-3">
      <div className="text-center">
        <h2 className="mt-3">No Category Available</h2>
        <div className="mt-3 text-center">
          {/* <Link to="/dashboard/award/create" className="btn btn-primary">
            Create New Award
          </Link> */}
        </div>
      </div>
    </section>
  );
};

export default ResultCategories;
