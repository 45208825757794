import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import Button from "../../../../../../../components/Button";

function VerifyTicket({ reloadTable, setReloadTable }) {
  const [ticketId, setTicketId] = useState("");
  const [isloading, setLoading] = useState(false);
  const [desc, setDesc] = useState("");

  const handleTicketVerification = (e) => {
    e.preventDefault();
    setLoading(true);
    setDesc("Verifying Ticket...Please Wait...");
    axios
      .post("/api/v2/ticket/verify/id_number", { ticket_number: ticketId })
      .then((res) => {
        if (res?.data?.status === 200) {
          setDesc("Verify Next Ticket");
          setLoading(false);
          setTicketId("");
          setReloadTable(!reloadTable);
          Swal.fire({
            title: "Verified",
            text: "Ticket Verification Successful",
            icon: "success",
            timer: 5000,
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          });
          
        }
      })
      .catch((err) => {
        setDesc("");
        setLoading(false);
        if (err?.response?.status === 422 || err?.response?.status === 400) {
          setDesc(
            err?.response?.data?.message || err?.response?.data?.error_msg
          );
          const msg =
            err?.response?.data?.message || err?.response?.data?.error_msg;
          Swal.fire({
            title: "Failed",
            text: msg,
            icon: "info",
            timer: 5000,
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          }).then((_) => {
            setTicketId("");
          });
        }
      });
  };
  return (
    <div
      className="modal fade"
      id="verify-modal"
      aria-hidden="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content moa">
          <div className="modal-body">
            <div className="share-content">
              <div className="mb-4 mt-5 mx-lg-5 text-center">
                <h4>Enter Ticket ID Number</h4>
                <hr />
              </div>
              <form onSubmit={handleTicketVerification}>
                <div className="mx-lg-5 mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Ticket Code Here"
                    value={ticketId}
                    onChange={(e) => setTicketId(e.target.value)}
                    required
                  />
                  <span className="text-primary mt-2 d-flex justify-content-center">
                    {desc}
                  </span>
                </div>
                <div className="mx-lg-5 mt-3">
                  <Button
                    buttonText={"Verify"}
                    isloading={isloading}
                    cssClasses="btn btn-primary bg-default buttonedit1 w-100"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyTicket;
