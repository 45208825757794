import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import routes from "../../../../components/helpers/routes";
import Swal from "sweetalert2";
import useFetch from "../../../../components/customHooks/useFetch";
import Loader from "../../../../components/helpers/Loader";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'

const AllBulkVotes = () => {
  const { id } = useParams(); //Award ID
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const {
    data: votes,
    isloaded,
    errors,
  } = useFetch(`/api/v2/bulk-vote/award/${id}/all`, reload);
  if (errors?.data?.status === 400) {
    const msg =
      errors?.data?.error_msg?.award ||
      "This award has already ended, kindly contact our support team for assistance/support";
    Swal.fire("Failed", msg, "info").then(() => { navigate("*") });
  }

  const deleteBulkVote = (e,index) => {
    Swal.fire({
      title: "Question",
      text: "This action deletes bulk votes, Do you want to proceed?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#011535",
      cancelButtonText: "No",
    }).then((action) => {
      if (action.isConfirmed) {
        axios.post(`/api/v2/bulk-vote/${index}/award/${id}/delete`).then((res) => {
          let msg = res.data.msg;
          Swal.fire({
            title: "Deleted",
            text: msg,
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
            timer: 2000,
          }).then((_) => {
            setReload(!reload);
          });
        });
      }
    });
  };


  


  const fileType = "xlsx"
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(votes);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Awardvotes.xlsx")
  }


  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2">Bulk Vote List</h4>
                <Link
                  to={routes.ADMIN_AWARD_BULKVOTING_SCREEN}
                  className="btn btn-primary float-right veiwbutton"
                >
                  Back
                </Link>
                <Link
                  to={`/dashboard/award/${id}/bulk-voting/add`}
                  className="btn btn-primary float-right veiwbutton mr-3"
                >
                  Add Bulk Votes
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">

            <div className="card card-table">
              <div className="d-flex justify-content-between align-content-center px-4 pt-4">
                <div className="w-50 mb-2">
                  
                </div>
                <div>
                  <button className="btn btn-primary" onClick={exportToCSV}>Export as Excel</button>
                </div>
              </div>

              <div className="card-body booking_card">
                <div className="table-responsive">
                  {isloaded ? (
                    <>
                      <table
                        id="nominee-table"
                        className="datatable table table-stripped table table-hover table-center mb-0"
                      >
                        <thead>
                          <tr>
                            <th className="text-center">Number of Votes</th>
                            <th className="text-center">Amount</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {votes?.length > 0 ? (
                            votes?.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center">
                                  {item?.votes}
                                </td>
                                <td className="text-center">
                                  {item?.amount || "N/A"}
                                </td>
                                <td className="text-capitalize">
                                  {item?.status}
                                </td>
                                <td>
                                  <div className="actions text-left">
                                    <Link
                                      to={`/dashboard/award/${id}/bulk-voting/${item?.id}/edit`}
                                      className="btn btn-sm btn-info mr-2"
                                    >
                                      <span
                                        className="fas fa-pen"
                                        title="Edit Details"
                                      ></span>
                                    </Link>
                                      <button
                                        onClick={(e) => deleteBulkVote(e, index = item?.id)}
                                        className="btn btn-sm btn-danger mr-2"
                                      >
                                        <span
                                          className="fas fa-trash"
                                          title="Delete"
                                        ></span>
                                      </button>
                                    
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5} className='text-center'>
                                <h2 className="mt-3 text-center">No Bulk Vote Available</h2>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBulkVotes;
