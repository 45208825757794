import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import "./styles/authDesktop.css";
import "./styles/authMobile.css";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const SignUp = () => {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "admin",
    errors: [],
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  function handleUserInput(e) {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = {
      name: userInput?.name,
      email: userInput?.email,
      phone: phone,
      password: userInput?.password,
      role: userInput?.role,
    };

    axios
      .post("/api/v2/auth/signup", data)
      .then((res) => {
        if (res?.data?.status === 200 && res?.data?.success === true) {
          Swal.fire({
            title: "Success",
            text: "Your Account Sign up is Successful and Pending Approval, Kindly Call/Whatsapp 0558571228 to speed up approval process. Thank You",
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          }).then((_) => {
            navigate("/login");
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setSubmitting(false);
        if (
          err.response.data.status === 400 &&
          err.response.data.success === false
        ) {
          setUserInput((userInput) => ({
            ...userInput,
            errors: err?.response?.data?.error_msg,
          }));
        }
      });
  };
  const [phone, setPhone] = useState();

  return (
    <div className="container container-position">
      <div className="row d-flex">
        <div className="col-lg-6 pad-right">
          <div className="illustration">
            <div className="illust-text">
              <h4>
                Opencast<span>GH</span>
              </h4>
            </div>
            <div className="illust-img">
              <img
                src="/assets/images/C.webp"
                alt="opencastgh"
                loading="lazy"
                className="img-fluid"
              />
            </div>
            <div className="illus-img">
              <img
                src="/assets/img/service.png"
                alt="opencastgh"
                loading="lazy"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6 pad-left">
          <div className="container-wrapper">
            <form onSubmit={handleSubmit}>
              <div className="p-lg-4 d-flex flex-column h-100 justify-content-center">
                <h1 className="text-center my-5">Sign Up</h1>
                <div className="right d-flex flex-row position-relative mb-3">
                  <input
                    type="text"
                    name="name"
                    value={userInput?.name}
                    onChange={handleUserInput}
                    className="flex-fill email"
                    required
                    placeholder="Full Name"
                    autoComplete="name"
                    autoFocus
                  />
                  <i className="far fa-user email position-absolute"></i>
                </div>
                <span className="text-danger text-center mt-1" role="alert">
                  {userInput?.errors?.name}
                </span>

                <div className="right d-flex flex-row position-relative">
                  <input
                    type="email"
                    className="flex-fill email"
                    name="email"
                    value={userInput?.email}
                    onChange={handleUserInput}
                    placeholder="Email"
                    required
                  />
                  <i className="far fa-envelope email position-absolute"></i>
                </div>
                <span className="text-danger text-center mt-1" role="alert">
                  {userInput?.errors?.email}
                </span>

                <div className="left d-flex flex-row position-relative mt-4">
                  <PhoneInput
                    country="GH"
                    defaultCountry="GH"
                    countries={["GH"]}
                    value={phone}
                    onChange={setPhone}
                    className="form-control phone-input"
                    placeholder="phone"
                    error={
                      phone
                        ? isPossiblePhoneNumber(phone)
                          ? ""
                          : "Invalid phone number"
                        : "Phone number required"
                    }
                  />
                  {/* <input
                    type="tel"
                    className="flex-fill password"
                    name="phone"
                    value={userInput?.phone}
                    onChange={handleUserInput}
                    required
                    autoComplete="current-password"
                    placeholder="phone"
                  />
                  <i className="fas fa-phone password position-absolute"></i> */}
                </div>
                <span className="text-danger mt-0" role="alert">
                  {userInput?.errors?.phone}
                </span>

                <div className="left d-flex flex-row position-relative mt-4">
                  <input
                    type={type}
                    className="flex-fill password"
                    name="password"
                    value={userInput?.password}
                    onChange={handleUserInput}
                    required
                    autoComplete="current-password"
                    placeholder="Password"
                  />
                  <span onClick={handleToggle}>
                    <Icon
                      icon={icon}
                      size={18}
                      className="view-password-icon1"
                    />
                  </span>
                  <i className="fas fa-lock password position-absolute"></i>
                </div>
                <span className="text-danger mt-0" role="alert">
                  {userInput?.errors?.password}
                </span>

                <div className="d-flex flex-row justify-content-center mt-4">
                  <Button
                    cssClasses={"submit"}
                    isloading={isSubmitting}
                    buttonText="Sign Up"
                  />
                </div>

                <div className="d-flex flex-row justify-content-center mt-5">
                  <Link to="/login" className="action w-50 text-center">
                    Login Instead
                  </Link>
                </div>
                <div className="d-flex flex-row justify-content-center mt-3">
                  <Link
                    to="https://opencastgh.com"
                    className="action w-50 text-center"
                  >
                    Back To Homepage
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
