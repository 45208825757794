import React, { useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../../../../components/customHooks/useFetch";
import Spinners from "../../../../../components/Spinners";
import EventsCard from "../components/EventsCard";

const ManageEvents = () => {
  const [reload,setReload]=useState(false);
  const { data: events, isloaded } = useFetch("/api/v2/events/all",reload);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2">Manage Events</h4>
                <Link
                  to="/dashboard/event/create"
                  className="btn btn-primary float-right veiwbutton"
                >
                  Add New Event
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {isloaded ? (
            <div className="col-sm-12">
              {events?.length > 0 ? (
                <>
                  <AwardInstructions />
                  <section className="pricing py-5">
                    <h2
                      className="text-white text-center"
                      style={{ marginTop: "-3%" }}
                    >
                      Available Events
                    </h2>

                    <div className="container">
                      <div className="row mt-5">
                        {events?.map((event, index) => (
                          <EventsCard key={index} event={event} setReload={setReload} reload={reload}/>
                        ))}
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <NoAwards />
              )}
            </div>
          ) : (
            <div className="bg-default">
              <h2 className="text-white text-center">
                {Spinners.rotatingLines} Loading... Please Wait...
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NoAwards = () => {
  return (
    <section className=" py-3">
      <div className="text-center">
        <h2 className="mt-3">No Event Available</h2>
        <div className="mt-3 text-center">
          <Link to="/dashboard/event/create" className="btn btn-primary">
            Create New Event
          </Link>
        </div>
      </div>
    </section>
  );
};

const AwardInstructions = () => {
  return (
    <div
      className="alert alert-success alert-dismissible fade show"
      role="alert"
    >
      <h4 className="alert-heading">Please Note!</h4>
      <p>
        For an award to go <b>live for voting</b>,The award has to be approved
        and with various categories added.
        <br /> Click on the <b>Go Live</b> button shown on the top-left of the
        Award
      </p>
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default ManageEvents;
