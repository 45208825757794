import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import HelmetData from "../../../../components/HelmetData";

function ShareEventModal({ event }) {
  const url = `https://opencastgh.com/event/${event?.code}`;
  const quote = `Hello, ${event?.title} starts soon, get your ticket now, and let's rock together`;
  const title = event?.title?.toUpperCase();
  const image=`${process.env.REACT_APP_BACKEND_URL}/${event?.image}`
  
  return (
    <div>
    <HelmetData title={event?.title||'OpenCastGh'} imageSrc={image||'/logo192.png'} description={'Let\'s make memories, buy the ticket now!'} url={url}/>
      <div
        className="modal fade"
        id="share-modal"
        aria-hidden="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content moa">
            <div className="modal-body">
              <div className="share-content">
                <div className="mb-4">
                  <h4 className="text-center">Sharing is caring</h4>
                </div>
                <div className="mb-4 mx-lg-5">
                  <h3 className="text-center">
                    We think it would be more fun to go for this event with your
                    friends. Let them know about this event by sharing this link
                    with them
                  </h3>
                </div>
                <div className="mx-lg-5 mb-4 d-flex justify-content-center">
                  <h5>Share this on your favourite social networks</h5>
                </div>
                <hr />
                <div className="d-block mx-lg-5">
                  <div className="d-flex mb-4 justify-content-center">
                    <FacebookShareButton
                      url={url}
                      quote={quote}
                      title={title}
                      hashtag="#opencastgh"
                    >
                      <FacebookIcon size={36} />
                    </FacebookShareButton>
                    <EmailShareButton
                      url={url}
                      quote={quote}
                      title={title}
                      hashtag="#opencastgh"
                      className="mx-2"
                    >
                      <EmailIcon size={36} />
                    </EmailShareButton>
                    <WhatsappShareButton
                      url={url}
                      quote={quote}
                      title={title}
                      hashtag="#opencastgh"
                      className="mx-2"
                    >
                      <WhatsappIcon size={36} />
                    </WhatsappShareButton>
                    <TelegramShareButton
                      url={url}
                      quote={quote}
                      title={title}
                      hashtag="#opencastgh"
                      className="mx-2"
                    >
                      <TelegramIcon size={36} />
                    </TelegramShareButton>
                    <TwitterShareButton
                      url={url}
                      quote={quote}
                      title={title}
                      hashtag="#opencastgh"
                      className="mx-2"
                    >
                      <TwitterIcon size={36} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={url}
                      quote={quote}
                      title={title}
                      hashtag="#opencastgh"
                      className="mx-2"
                    >
                      <LinkedinIcon size={36} />
                    </LinkedinShareButton>
                  </div>
                  <div>
                    <h3 className="mb-3 text-center">or copy link</h3>
                    <hr />
                  </div>
                </div>
                <div className="mx-lg-5 mt-2">
                  <input type="text" readOnly value={url} className="form-control" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default ShareEventModal;
