import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "../../../components/customHooks/useFetch";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";
import LoadingPage from "../../LoadingPage";
import CardItem from "../components/CardItem";

const GuestResultCategories = () => {
  const navigate = useNavigate()
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState();

  const {data:award,isloaded,errors}=useFetch(`api/v2/guest/award/${id}`);

  if(errors){
    Swal.fire({
      title: "Error",
      text: "Award Not Found",
      icon: "error",
    }).then(_ => {
      navigate('/awards');
    });
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }

    // setSelectedCategory('');
    setSearchTerm('');
  }, [id]);


  // const categories = award?.categories?.filter((category) => {
  //   if (searchTerm === "") {
  //     return category;
  //   }
  //   else if (
  //     category?.title
  //       .toLocaleLowerCase()
  //       .includes(searchTerm.toString().toLocaleLowerCase())
  //   ) {
  //     return category;
  //   }
  // });

  return (
    <>
      <Nav />
      <div className="award-div d-flex">
        <div className="award-img-bg">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${award?.image}`}
            className="img-thumbnail"
            alt="award"
          />
        </div>
        <div className="award-img-title">
          <h1 className="text-white text-capitalize" title="Award Title">
            {award?.title || "Loading, Please Wait..."}
          </h1>
        </div>
      </div>
      <div className="advert-margin search-margin">
        <div className="col-lg-12">
          <div className="text-center">
            <h1>
              <i>Select Category To View Results</i>
            </h1>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="search-cover">
            <input
              type="text"
              className="form-control rounded-pill search-text"
              placeholder="Search by Category"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value.toLocaleLowerCase())
                // setSelectedCategory('');
              }
              }
            />
            <i className="fas fa-search search-icon"></i>
          </div>
        </div>
      </div>
      <div className="">
        <div className="advert-margin mt-md-4 mt-2">
         
          {/* <Nominees isloaded={isloaded} award={award} searchTerm={searchTerm} selectedCategory={selectedCategory} /> */}
          {
            isloaded?
            <div className="container-fluid">
              <div className="d-flex justify-content-between mb-2">
                <div className="text-margin-start12">
                  <h3>Available Categories</h3>
                </div>
              </div>
                {award?.categories?.length > 0 ? (
                  <div className="row">
                    {award?.categories?.map((category, i) => (
                      <div
                        className="col-custom-2 col-md-3"
                        id="custom-col"
                        key={i}
                      >
                        <Link to={`/results/${category?.id}`}>
                          <CardItem
                            image={award?.image}
                            title={category?.title}
                            status={''}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="row">
                    <h4>No Nominee/Contestant Available</h4>
                  </div>
                )}
            </div>
            :<LoadingPage/>
          }
        </div>
      </div>
      <Footer />
    </>
  );
};
export default GuestResultCategories;
