const   routes ={
  LOGIN: "/login",
  SIGNUP: "/signup",
  DASHBOARD: "/dashboard",
  FORGOT_PASSWORD:"/forgot-password",

  MANAGE_AWARDS: "/dashboard/awards",
  CREATE_AWARDS: "/dashboard/award/create",
  EDIT_AWARDS: "/dashboard/award/:id/edit",

  VIEW_AWARD_CATEGORIES: "/dashboard/award/:id/categories",
  CREATE_AWARD_CATEGORY: "/dashboard/award/:id/category/create",
  EDIT_AWARD_CATEGORY: "/dashboard/award/:award_id/category/:category_id/edit",

  NOMINEES_SCREEN: "/dashboard/nominees",
  MANAGE_NOMINEES: "/dashboard/award/:id/nominees",
  ADD_NOMINEES: "/dashboard/award/:id/nominee/add",
  EDIT_NOMINEE: "/dashboard/nominees/:id",
  
  ADMIN_AWARD_BULKVOTING_SCREEN: "/dashboard/awards/bulk-voting",
  ADMIN_MANAGE_BULKVOTING: "/dashboard/award/:id/bulk-voting",
  ADMIN_ADD_BULKVOTE: "/dashboard/award/:id/bulk-voting/add",
  ADMIN_EDIT_BULKVOTE: "/dashboard/award/:award_id/bulk-voting/:id/edit",
  
  TRANSACTION_AWARDS: "/dashboard/transaction/awards",
  AWARD_TRANSACTIONS: "/dashboard/award/:id/transactions",
  AWARD_WITHDRAWAL:'/dashboard/award/:id/withdrawal',

  RESULTS_AWARDS: "/dashboard/awards/results",
  RESULTS_CATEGORIES: "/dashboard/award/:id/results/categories",
  RESULTS: "/dashboard/award/:award_id/category/:category_id/results",

  EVENTS_PAGE:"/dashboard/events",
  CREATE_EVENT:"/dashboard/event/create",
  EDIT_EVENT:"/dashboard/event/:id/edit",
  EVENT_PREVIEW_ADMIN:'/dashboard/event/:id',
  VIEW_EVENT_TRANSACTIONS:"/dashboard/event/:id/view-transaction",

  MANAGE_TICKETS: "/dashboard/event/:id/tickets",
  CREATE_TICKET: "/dashboard/event/:id/ticket/create",
  EDIT_TICKET: "/dashboard/event/:event_id/ticket/:ticket_id/edit",
  
  MANAGE_ATTENDANCE:"/dashboard/event/:id/attendance",
  MANAGE_USERS: "/dashboard/event/:id/manage-users",
  CREATE_USER: "/dashboard/event/:id/create-user",

  LOADING:"/loading-page",
  
  // Guests
  AWARDS_PAGE:'/awards',
  GUEST_AWARD_CATEGORIES:"/award/:id/categories",
  GUEST_VOTE_PAYMENT:"/awards/:nominee_code",

  //Results
  GUEST_RESULTS_PAGE:'/results',
  GUEST_RESULTS_CATEGORIES:'/results/award/:id/categories',
  GUEST_RESULTS:'/results/:category_id',

  NOMINATION_PAGE:"/nominations",
  NOMINATION_FORM:"/nominations/:id",
  TICKET_PAGE:"/tickets",
  ATTENDEE_TICKET:'/ticket/:id',
  TICKET_PAYMENT:'/event/:id',

  PRIVACY:'/privacy',
  PAYMENT_SUCCESS:'/payment-success',
  PAYMENT_FAILED:'/payment-failed'
};

export default routes;