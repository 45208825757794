import { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import SkeletonPlaceholder from "../../../../components/SkeletonPlaceholder";

import EventCard from "./components/EventCard";

const MoreEvents = ({ events, isloaded }) => {
  const [, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return isloaded ? (
    events?.length > 0 ? (
      <div className="col-lg-12">
        <div className="row advert-margin">
          <div className="d-flex justify-content-between mb-2">
            <div className="text-margin-start12">
              <h3>More Events</h3>
            </div>
          </div>
          <div>
            <div className="row">
              {events?.map((event, i) => (
                <div className="col-custom-2 col-md-3" id="custom-col" key={i}>
                  <Link to={`/event/${event?.code}`} key={i}>
                    <EventCard event={event} />
                  </Link>
                </div>
              ))}
            </div>
            <div className="mb-5">
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={events?.length}
                paginate={paginate}
              />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <h4 className="text-center">No Event is Available at the Moment</h4>
      </div>
    )
  ) : (
    <div className="col-lg-12">
      <div className="row advert-margin">
        {Array(10)
          .fill("")
          .map((_, i) => (
            <div className="col-custom-2 col-md-3 col-6" id="custom-col" key={i}>
              <SkeletonPlaceholder />
            </div>
          ))}
      </div>
    </div>
  );
};
export default MoreEvents;
