import React from "react";
import CardItem from "../../components/CardItem";

const RecentlyEndedAwards = ({ awards }) => {
  return (
    <div className="col-lg-12">
      <div className="row advert-margin mt-5">
        <div className="col-lg-12 mb-2">
          <div className="text-margin-start12">
            <h3>Some Recently Ended Awards</h3>
          </div>
        </div>
        <div className="col-lg-12 col-sm-auto">
          <div className="row">
            {awards
              ?.filter(
                ({ status, end_date }) =>
                  status === "ended" || new Date() >= new Date(end_date)
              )
              .slice(0, 10)
              .map((award, i) => (
                <div className="col-custom-2 col-md-3" key={i}>
                  {/* <Link to={`/award/${award?.id}/categories`}> */}
                  <CardItem image={award?.image} title={award?.title} />
                  {/* </Link> */}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RecentlyEndedAwards;
