import React from "react";

const AwardCategoryCard = ({ image, title, id, setSelectedCategory }) => {
  return (
    <div className="col-custom-2 col-md-3" id="custom-col">
      <div className="card-trans" onClick={() => setSelectedCategory(id)}>
        <div className="card-h-category" onClick={() => setSelectedCategory(id)}>
        <div className="card-img-cover3" onClick={() => setSelectedCategory(id)}>
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${image}`}
            alt="award"
            className="rounded-circle"
            onClick={() => setSelectedCategory(id)}
          />
        </div>
        </div>
        <div
          className="award-text mt-2 text-center text-capitalize"
          onClick={() => setSelectedCategory(id)}
          title={title}
        >
          <h4>{title}</h4>
        </div>
        {/* <div className="award-title-text1 mt-1" title="Award Title">
          <h4>{status}</h4>
        </div> */}
        {/* <div className="award-title-text1 mt-1" title="Award Title">
            <h4>Award Title</h4>
          </div> */}
      </div>
    </div>
  );
};

export default AwardCategoryCard;
