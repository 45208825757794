import Spinners from "../Spinners";
import React from "react";

const Loader = () => {
  return (
    <div className="bg-default">
      <h2 className="text-white text-center">
        {Spinners.rotatingLines} Loading... Please Wait...
      </h2>
    </div>
  );
};

export default Loader;
