import React, { useEffect, useState } from "react";
import axios from "axios";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";
import FeaturedAwards from "./components/FeaturedAwards";
import Advertisement from "./components/Advertisement";
import Services from "./Services";
import HowItWorks from "./components/HowItWorks";
import PaymentChannels from "./components/PaymentChannels";
import Slider from "react-slick";
import OngoingAwards from "./components/OngoingAwards";
import RecentlyEndedAwards from "./components/RecentlyEndedAwards";

const HomePage = () => {
  useEffect(() => {
    window.location.replace("https://www.opencastgh.com");
  });

  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    fade: true,
    cssEase: "ease",
    speed: 6500,
    slidesPerRow: 1,
    slidesToShow: 1,
    nextArrow: (
      <img
        src="/assets/images/arrow-right.png"
        alt="arrow"
        className="arrow-right-pos1 slick-next"
      />
    ),
    prevArrow: (
      <img
        src="/assets/images/arrow-left.png"
        alt="arrow"
        className="arrow-left-pos1 slick-next"
      />
    ),
  };
  const [awards, setAwards] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
    axios.get("/api/v2/guest/awards/all").then((res) => {
      if (res.data.status === 200 && res.data.success === true) {
        setAwards(res.data.data);
      }
    });
  }, []);

  return (
    <>
      <Nav />
      <div className="slider variable">
        <Slider {...settings}>
          <div className="carousel-div">
            <img
              src="/assets/images/slides/slide1.webp"
              alt="Advertisement"
              width={"100%"}
            />
          </div>
          <div className="carousel-div">
            <img
              src="/assets/images/slides/slide2.webp"
              alt="Advertisement"
              width={"100%"}
            />
          </div>
          <div className="carousel-div">
            <img
              src="/assets/images/slides/slide3.webp"
              alt="Advertisement"
              width={"100%"}
            />
          </div>
        </Slider>
      </div>
      <Services />
      <FeaturedAwards awards={awards} />
      <Advertisement />
      <OngoingAwards awards={awards} />
      <HowItWorks />
      <RecentlyEndedAwards awards={awards} />
      <Advertisement />
      <PaymentChannels />

      <Footer />
    </>
  );
};
export default HomePage;
