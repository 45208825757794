import { useRef } from "react";
import Slider from "react-slick";
import useFetch from "../../../../components/customHooks/useFetch";

const EventCategory = () => {
    const settings = {
        infinite: true,
        autoplay: true,
        variableWidth: true,
        speed: 600,
        arrows: false,
        dots: false
    };
    const sliderRef = useRef(null)

    const {data:categories}=useFetch('/api/v2/category/all');


    return (
        <div className="col-lg-12">
            <div className="advert-margin mt-5">
               
                <div className="d-flex justify-content-between mb-2">
                    <div className="text-margin-start12">
                        <h3>Event Categories</h3>
                    </div>
                    <div>
                        <img
                            src="/assets/images/arrow-left1.png"
                            alt="arrow"
                            onClick={() => sliderRef.current.slickPrev()}
                            className="cur-pointer cur-width"
                        />
                        <img
                            src="/assets/images/arrow-right1.png"
                            alt="arrow"
                            onClick={() => sliderRef.current.slickNext()}
                            className="ms-3 cur-pointer cur-width"
                        />

                    </div>
                </div>
                <section className="slider variable">
                    <Slider ref={sliderRef} {...settings}>

                        {categories?.map((category, i) => {
                            return (
                                <div className="cate-cover" key={i}>
                                    <div className="cover-bg">
                                        <img src={`${process.env.REACT_APP_BACKEND_URL}/${category?.image}`} loading="lazy" alt="Cover"  className="rounded-circle" />
                                    </div>
                                    <div className="cate-name">
                                        <h4>{category?.title}</h4>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </section>

            </div>
        </div>

    )
}
export default EventCategory