const HowItWorks = () => {
  return (
    <div className="advert-margin">
      <div className="text-center featured-event2">
        <h1>
          {" "}
          <i>How It Works</i>
        </h1>
      </div>
      <div className="col-lg-12">
        <div className="row mt-lg-3 mt-3 align-items-center justify-content-between">
          <div className="col-lg-2 d-none d-lg-block">
            <img
              src="/assets/images/device.png"
              loading="lazy"
              alt="device"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-9 col-12">
            <div className="row">
              <div className="col-lg-6 col-6 col-md-6">
                <div className="d-lg-flex align-items-center text-position-center">
                  <div className="step-img-cover">
                    {/* <i className="fas fa-thumbs-up text-white"></i> */}
                    <i className="fas fa-user text-white"></i>
                  </div>
                  <div className="step-text d-block">
                    <h4 className="ms-lg-3">Create Account</h4>
                    <h5 className="ms-lg-3">
                      Easily create an account by providing few user details
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-6 col-md-6">
                <div className="d-lg-flex align-items-center text-position-center">
                  <div className="step-img-cover">
                    <i className="fas fa-calendar text-white"></i>
                  </div>
                  <div className="step-text d-block">
                    <h4 className="ms-lg-3">Setup Award Event</h4>
                    <h5 className="ms-lg-3">
                      Create your event together with various categories
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-6  col-6 col-md-6 mt-5">
                <div className="d-lg-flex align-items-center text-position-center">
                  <div className="step-img-cover">
                    <i className="fas fa-tachometer-alt text-white"></i>
                  </div>
                  <div className="step-text d-block">
                    <h4 className="ms-lg-3">Manage Event Dashboard</h4>
                    <h5 className="ms-lg-3">
                      Easily manage your nonimees and results right{" "}
                      <span className="d-lg-block">from your dashboard</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-6  col-6 col-md-6 mt-5">
                <div className="d-lg-flex align-items-center text-position-center">
                  <div className="step-img-cover">
                    <i className="fas fa-credit-card text-white"></i>
                  </div>
                  <div className="step-text d-block">
                    <h4 className="ms-lg-3">Start Receiving Payment</h4>
                    <h5 className="ms-lg-3">
                      Track all payment transactions and make withdrawals{" "}
                      <span className="d-lg-block">at any point in time</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
