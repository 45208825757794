import axios from "axios";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import routes from "../../../components/helpers/routes";
import TopBar from "./TopBar";

const SideBar = () => {
  const navigate=useNavigate();
  const links = [
    {
      id: 1,
      title: "Awards",
      url: "/awards",
      icon: "fas fa-medal",
      hasChildren: true,
      slug: "awards",
      children: [
        {
          id: 0,
          title: "Manage Awards",
          url: "/dashboard/awards",
          icon: "fas fa-trophy",
        },
        {
          id: 1,
          title: "Bulk Voting",
          url: routes.ADMIN_AWARD_BULKVOTING_SCREEN,
          icon: "fas fa-address-book",
        },
        {
          id: 2,
          title: "View Results",
          url: routes.RESULTS_AWARDS,
          icon: "fas fa-chart-bar",
        },
        {
          id: 3,
          title: "Transactions",
          url: routes.TRANSACTION_AWARDS,
          icon: "fas fa-key",
        },
      ],
      funcCallback: () => {},
    },
    {
      id: 3,
      title: "Event Ticketing",
      url: "/dashboard/events",
      icon: "fa fa-calendar-day",
      hasChildren: false,
      funcCallback: () => {},
    },
   
    {
      id: 2,
      title: "Profile",
      url: "/transactions",
      icon: "fas fa-user",
      hasChildren: false,
      funcCallback: () => {},
    },
    {
      id: 3,
      title: "Logout",
      url: "",
      icon: "fa fa-sign-out-alt",
      funcCallback: () => {
        axios.post('/api/v2/auth/logout').then(_=>{
          localStorage.clear()
          navigate('/login')
        }).catch(_=>{
          localStorage.clear()
          navigate('/login')
        })
      },
    },
  ];

  const location = useLocation();

  return (
    <>
      <TopBar />
      <div className={"sidebar"} id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li
                className={`${location.pathname === "/dashboard/" && "active"}`}
              >
                {" "}
                <Link to="/dashboard">
                  <i className="fas fa-tachometer-alt"></i>
                  <span>Dashboard</span>
                </Link>{" "}
              </li>
              <li className="list-divider"></li>

              {links.map((link, index) => (
                <>
                  <li
                    className={`${location.pathname === link?.url && "active"}`}
                    key={index}
                  >
                    {link?.hasChildren ? (
                      <a
                        href={`#${link?.slug}`}
                        data-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        key={link?.id}
                      >
                        <i className={`${link?.icon}`}></i>
                        <span>{link?.title}</span>
                      </a>
                    ) : (
                      <Link to={link?.url} key={link?.id} onClick={link?.funcCallback}>
                        <i className={`${link?.icon}`}></i>
                        <span>{link?.title}</span>
                      </Link>
                    )}
                  </li>
                  <div className="collapse ms-3" id={link?.slug}>
                    {link?.children?.map((child) => (
                      <Link
                        to={child?.url}
                        style={childMenu}
                        className={`${
                          location.pathname === child?.url && "active"
                        } child-nav`}
                      >
                        <i className={`${child?.icon}`} style={icon}></i>
                        <span style={childMenuSpan}>{child?.title}</span>
                      </Link>
                    ))}
                  </div>
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;

const icon = {
  display: "inline-block",
  fontSize: "19px",
  lineHeight: "24px",
  textAlign: "left",
  verticalAlign: "middle",
  width: "20px",
  transition: "all 0.2s ease-in-out 0s",
};

const childMenu = {
  alignItems: "center",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "flex-start",
  padding: "12px 10px",
  position: "relative",
  transition: "all 0.2s ease-in-out 0s",
  color: "#333",
};
const childMenuSpan = {
  transition: "all 0.2s ease-in-out 0s",
  display: "inline-block",
  marginLeft: "10px",
  whiteSpace: "nowrap",
};


