import React from "react";
import { Link } from "react-router-dom";

const TransactionAwardCard = ({ award }) => {
  return (
    <div className="col-lg-3">
      <div className="card mb-5 mb-lg-3">
        <div className="card-body">
          <figure>
            <img
              className="img-thumbnail"
              width="100%"
              src={`${process.env.REACT_APP_BACKEND_URL}/${award?.image}`}
              alt="Event"
            />
          </figure>
          <div className="container">
            <div className="row mb-3">
              <div className="col-md-12">
                <div
                  className="h5 text-center text-capitalize textElipse"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={award?.title}
                >
                  {award?.title}
                </div>
              </div>
            </div>
          </div>
          <Link
            to={`/dashboard/award/${award?.id}/transactions`}
            className="btn btn-block btn-primary text-uppercase"
          >
            View Transactions
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TransactionAwardCard;


