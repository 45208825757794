import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import routes from "../../../../components/helpers/routes";
import Swal from "sweetalert2";
import useFetch from "../../../../components/customHooks/useFetch";
import Loader from "../../../../components/helpers/Loader";
import PagePagination from "../../../../components/PagePagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const AllNominees = () => {
  const { id } = useParams(); //Award ID
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const {
    data: nominees,
    isloaded,
    errors,
  } = useFetch(`/api/v2/nominees/award/${id}/all`, reload);

  if (errors?.data?.status === 400) {
    const msg =
      errors?.data?.error_msg?.award ||
      "This award has already ended, kindly contact our support team for assistance/support";
    Swal.fire("Failed", msg, "info").then(() => {
      navigate("*");
    });
  }

  const deleteNominee = (id) => {
    Swal.fire({
      title: "Question",
      text: "This action blocks nominee from being voted for or appearing on the results chart, Do you want to proceed?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#011535",
      cancelButtonText: "No",
    }).then((action) => {
      if (action.isConfirmed) {
        axios.post(`/api/v2/nominees/delete/${id}`).then((res) => {
          let msg = res.data.msg;
          Swal.fire({
            title: "Deleted",
            text: msg,
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
            timer: 2000,
          }).then((_) => {
            setReload(!reload);
          });
        });
      }
    });
  };

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemPerPage = 4;

  useEffect(() => {
    const endOffset = itemOffset + itemPerPage;
    setCurrentItems(nominees?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(nominees?.length / itemPerPage));
  }, [itemOffset, itemPerPage, nominees]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemPerPage) % nominees?.length;
    setItemOffset(newOffset);
  };

  const [search, setSearch] = useState("");

  const fileType = "xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(nominees);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "AwardNominees.xlsx");
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2">Nominees List</h4>
                <Link
                  to={routes.DASHBOARD}
                  className="btn btn-primary float-right veiwbutton"
                >
                  Back
                </Link>
                <Link
                  to={`/dashboard/award/${id}/nominee/add`}
                  className="btn btn-primary float-right veiwbutton mr-3"
                >
                  Add Nominee
                </Link>
                <Link
                  to={`/dashboard/award/${id}/categories`}
                  className="btn btn-primary float-right veiwbutton mr-3"
                >
                  Manage Categories
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="d-flex justify-content-between align-content-center px-4 pt-4">
                <div className="w-50 mb-2">
                  <input
                    type="text"
                    className="form-control text-center"
                    placeholder="Search Nominee"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div>
                  <button className="btn btn-primary" onClick={exportToCSV}>
                    Export as Excel
                  </button>
                </div>
              </div>

              <div className="card-body booking_card">
                <div className="table-responsive">
                  {isloaded ? (
                    <>
                      <table
                        id="nominee-table"
                        className="datatable table table-stripped table table-hover table-center mb-0"
                      >
                        <thead>
                          <tr>
                            <th>Nominee Code</th>
                            <th>Stage Name</th>
                            <th>Full Name</th>
                            <th>Category</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.length > 0 ? (
                            currentItems
                              ?.filter(
                                (item) =>
                                  item?.stage_name
                                    ?.toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                  item?.full_name
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                              )
                              ?.map((nominee, index) => (
                                <tr key={index}>
                                  <td className="text-uppercase">
                                    {nominee?.nominee_code}
                                  </td>
                                  <td className="text-capitalize">
                                    {nominee?.stage_name || "N/A"}
                                  </td>
                                  <td className="text-capitalize">
                                    {nominee?.full_name}
                                  </td>
                                  <td className="text-capitalize">
                                    {nominee?.category?.title}
                                  </td>
                                  <td className="text-capitalize">
                                    {nominee?.nominee_status}
                                  </td>
                                  <td>
                                    <div className="actions text-left">
                                      <Link
                                        to={`/dashboard/nominees/${nominee?.id}`}
                                        className="btn btn-sm btn-info mr-2"
                                      >
                                        <span
                                          className="fas fa-pen"
                                          title="Edit Details"
                                        ></span>
                                      </Link>

                                      {nominee?.nominee_status === "active" && (
                                        <button
                                          onClick={() =>
                                            deleteNominee(nominee?.id)
                                          }
                                          className="btn btn-sm btn-danger mr-2"
                                        >
                                          <span
                                            className="fas fa-trash"
                                            title="Delete"
                                          ></span>
                                        </button>
                                      )}
                                      {nominee?.nominee_status ===
                                        "pending" && (
                                        <button
                                          onClick={() =>
                                            deleteNominee(nominee?.id)
                                          }
                                          className="btn btn-sm btn-danger mr-2"
                                        >
                                          <span
                                            className="fas fa-trash"
                                            title="Delete"
                                          ></span>
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan={5} className="text-center">
                                <h2 className="mt-3 text-center">
                                  No Nominee Available
                                </h2>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                <div className="pagin-pos">
                  <PagePagination
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllNominees;
