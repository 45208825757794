import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const ResultCard = ({ award,url,awardImage,reload,setReload }) => {

 
  const handleEventState = (status) => {
    axios
      .post("/api/v2/awards/change-results-status", { award_id: award?.id, results_status: status })
      .then((res) => {
        if (res?.status === 200) {
          Swal.fire("Info", res?.data?.msg, "info");
          setReload(!reload);
        }
      })
      .catch((err) => {
        Swal.fire("Update Failed", "Error Updating Event State", "error");
      });
  };

  return (
    <div className="col-lg-3">
      <div className="card mb-5 mb-lg-3">
      <AwardStatusActions
          status={award?.results}
          handleEventState={handleEventState}
        />
        <div className="card-body">
          <figure>
            <img
              className="img-thumbnail"
              width="100%"
              src={`${process.env.REACT_APP_BACKEND_URL}/${award?.image || awardImage}`}
              alt="Event"
            />
          </figure>
          <div className="container">
            <div className="row mb-3">
              <div className="col-md-12">
                <div
                  className="h5 text-center text-capitalize textElipse"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={award?.title}
                >
                  {award?.title}
                </div>
              </div>
            </div>
          </div>
          <Link
            to={url}
            className="btn btn-block btn-primary text-uppercase"
          >
            View Results
          </Link>
          {/* <Link
            to={url}
            className="btn btn-block btn-primary text-uppercase"
          >
            Results by Date
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default ResultCard;

const AwardStatusActions = ({ status, handleEventState }) => {
  return (
    <>
      {status === "published" && (
        <div className="card colk bg-danger text-white">
          <div className="card-body">
            <button
              onClick={() => handleEventState("unpublished")}
              className="text-white btn bg-danger border-0 btn-outline-danger"
            >
              Hide Results
            </button>
          </div>
        </div>
      )}

      {status === "unpublished" && (
        <div className="card colk bg-success text-white">
          <div className="card-body">
            <button
              onClick={(e) => handleEventState("published")}
              className="text-white btn bg-success border-0 btn-outline-success"
            >
             Publish Results
            </button>
          </div>
        </div>
      )}
    </>
  );
};
