import { Link, useNavigate, useParams } from "react-router-dom";
// import useFetch from "../../../../components/customHooks/useFetch";
import { getCurrentDate } from "../../../../components/helpers/getCurrentDate";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import PagePagination from "../../../../components/PagePagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import LoadingPage from "../../../LoadingPage";
import axios from "axios";

const AwardTransactions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [reload, setReload] = useState(false);
  const [data, setData] = useState();
  const [isloaded, setIsLoaded] = useState(false);
  var curr = new Date();
  curr.setDate(curr.getDate() + 3);
  var date = curr.toISOString().substring(0, 10);

  const [userInput, setUserInput] = useState({
    from: getCurrentDate("-"),
    to: getCurrentDate("-"),
  });

  useEffect(() => {
    axios
      .get(
        `/api/v2/transaction/award/${id}?page=${page}&from=${userInput?.from}&to=${userInput?.to}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoaded(true);
          setData(res.data.data);
        }
      })
      .catch((err) => {
        setIsLoaded(true);
        console.log(err?.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }, [reload, navigate, id, page,userInput?.from,userInput?.to]);

  const handlePageClicked = (e) => {
    setPage(e.selected+1);
    setReload(!reload);
  
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    setReload(!reload);
    axios
      .get(
        `/api/v2/transaction/award/${id}?page=${page}&from=${userInput?.from}&to=${userInput?.to}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoaded(true);
          setData(res.data.data);
        }
      })
      .catch((err) => {
        setIsLoaded(true);
        console.log(err?.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  const fileType = "xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data?.transactions?.data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const records = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(records, "Transactions.xlsx");
  };

  

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="mt-5">
                <h4 className="card-title float-left mt-2 text-uppercase">
                  {data?.award?.title || "Loading..."}
                </h4>{" "}
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-primary float-right veiwbutton"
                >
                  Back
                </button>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-sm-6 col-12">
            <div className="card board1 fill padding-card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div>
                    <h3 className="card_widget_header">
                      {Number(data?.deposits) || 0}
                    </h3>
                    <h6 className="text-muted">Total Payments</h6>
                  </div>
                  <div className="ml-auto mt-md-3 mt-lg-0">
                    {" "}
                    <span className="opacity-7 text-muted">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#009688"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user-plus"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-sm-6 col-12">
            <div className="card board1 fill padding-card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div>
                    <h3 className="card_widget_header">
                      {Number(data?.withdrawals) || 0}
                    </h3>
                    <h6 className="text-muted">Total Withdrawals</h6>
                  </div>
                  <div className="ml-auto mt-md-3 mt-lg-0">
                    {" "}
                    <span className="opacity-7 text-muted">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#009688"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user-plus"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-sm-6 col-12">
            <div className="card board1 fill padding-card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div>
                    <h3 className="card_widget_header">
                      {Number(data?.award_balance)||0}
                    </h3>
                    <h6 className="text-muted">Award Balance</h6>
                  </div>
                  <div className="ml-auto mt-md-3 mt-lg-0">
                    {" "}
                    <span className="opacity-7 text-muted">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#009688"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user-plus"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {data?.allow_withdrawal && (
          <div className="container mb-2">
            <div className="d-flex  justify-content-end align-items-right">
              <Link
                to={`/dashboard/award/${id}/withdrawal`}
                className="btn btn-success pull-end text-nowrap"
              >
                Make Withdrawal
              </Link>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-hearder">
                <form className="mx-4 mt-3" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="">From</label>
                      <input
                        type="date"
                        defaultValue={
                          new Date(date).toISOString().split("T")[0]
                        }
                        className="form-control"
                        required
                        name="from"
                        value={userInput?.from}
                        onChange={handleUserInput}
                      />

                      {/* <div className="text-danger">Error Message</div> */}
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="">To</label>
                      <input
                        type="date"
                        defaultValue={
                          new Date(date).toISOString().split("T")[0]
                        }
                        className="form-control"
                        required
                        name="to"
                        value={userInput?.to}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="col-md-2 mt-4">
                      <button className="btn btn-success mt-2" type="submit">
                        Load
                      </button>
                    </div>
                    <div className="col-md-2 mt-4">
                      <button
                        className="btn btn-primary mt-2 text-nowrap"
                        onClick={exportToCSV}
                      >
                        Export as Excel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {isloaded ? (
                <div className="card-body booking_card">
                  <div className="table-responsive">
                    <table
                      id="transaction-table"
                      className="datatable table table-stripped table table-hover table-center mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Ref#</th>
                          <th>Nominee Code</th>
                          <th>Amount Paid</th>
                          <th>Less Deduction</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Payment Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.transactions?.data.map((trans, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-uppercase">
                                {trans?.ref_code}
                              </td>
                              <td className="text-uppercase">
                                {trans?.nominee_code}
                              </td>
                              <td> {trans?.payment_amount} </td>
                              <td>
                                {" "}
                                {trans?.payment_amount -
                                  trans?.amount_deducted}{" "}
                              </td>
                              <td className="text-capitalize">
                                {" "}
                                {trans?.transaction_type}
                              </td>
                              <td className="text-capitalize">
                                {" "}
                                {trans?.payment_status}{" "}
                              </td>
                              <td>
                                {" "}
                                {format(
                                  new Date(trans?.created_at),
                                  "do MMM, yyyy h:m aaa"
                                )}{" "}
                              </td>
                              <td> {trans?.momo_number || "N/A"} </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagin-pos">
                    <PagePagination
                      pageCount={data?.transactions?.last_page}
                      handlePageClick={handlePageClicked}
                    />
                  </div>
                </div>
              ) : (
                <LoadingPage />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardTransactions;
