import { useMemo, useState,useEffect,React } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox";
import "@reach/combobox/styles.css";




export default function NewMap({getMapLocation}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBsnVLVZOQYt7P1IJKKZ_nAoBjlMn5wgjw",
    libraries: ["places"]
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map getMapLocation={getMapLocation}/>;
}

function Map({getMapLocation}) {
  useMemo(() => ({ lat: -3.745, lng: -38.523 }), []);
  const [selected, setSelected] = useState({ lat: 6.6666004, lng: -1.6162709,address:'' });
  
  useEffect(()=>{
    getMapLocation(selected.lng,selected.lat,selected.address);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selected])
  
  return (
    <>
      <div>
        <PlacesAutoComplete setSelected={setSelected} />
      </div>

      <GoogleMap
        zoom={12}
        center={selected}
        mapContainerStyle={{ width: "100%", height: "50vh" }}
      >
        {selected && <MarkerF position={selected} />}
      </GoogleMap>
    </>
  );
}

const PlacesAutoComplete = ({ setSelected }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng ,address});
  };
  
  return (
    <>
      <div className="row">
        <div className="col-12 ml-5">
          <div className="row">
            <div className="col-lg-9">
              <Combobox onSelect={handleSelect}>
                <ComboboxInput
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  disabled={!ready}
                  className="form-control mx-auto mt-4 mb-3 combobox-input"
                  placeholder="Search an address"
                />

                <ComboboxPopover className="comboSearch">
                  <ComboboxList>
                    {status === "OK" &&
                      data.map(({ place_id, description }) => (
                        <ComboboxOption key={place_id} value={description} />
                      ))}
                  </ComboboxList>
                </ComboboxPopover>
              </Combobox>
            </div>
            <div className="col-lg-2">
                <button type="button" className="btn btn-success mt-4 " data-dismiss="modal" aria-label="Close">Go</button>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};


// .combobox-input{
//   border: 1px solid #5e5e5e !important;
//   box-shadow: 0 3px 10px 0px rgb(0 0 0 / 10%);
//   z-index: 10;
// }
// .combobox-input:focus{
//   border: 1px solid #5e5e5e !important;
//   box-shadow: 0 3px 10px 0px rgb(0 0 0 / 10%);
// }



// .comboSearch{
//   z-index: 999999999999999999;
// }