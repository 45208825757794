import React from "react";

function DirectionButton({event,btnClass}) {
  return (
    <a href={`https://maps.google.com/maps?q=${event?.lat},${event?.long}`}
      // onClick={() =>
      //   (window.location.href = `https://maps.google.com/maps?q=${event?.lat},${event?.long}`)
      // }
      target={"_blank"}
      className={`btn ${btnClass} w-100`} rel="noreferrer"
    >
      Get Directions
    </a>
  );
}

export default DirectionButton;
