import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../../../components/Button";
import Swal from "sweetalert2";
import axios from "axios";

const TableRows = ({ ticket, event_id, reloadTable, setReloadTable }) => {
  const navigate = useNavigate();
  const [isDeleting, setDeleting] = useState(false);

  const handleStatusChange = (status, message) => {
    Swal.fire({
      title: "Question",
      icon: "question",
      text: message,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      confirmButtonColor: "#011535",
    }).then((action) => {
      if (action.isConfirmed) {
        setDeleting(true);
        const data = {
          status: status,
          ticket_id: ticket?.id,
        };
        axios
          .post(`/api/v2/ticket/change-status`, data)
          .then((res) => {
            if (res.data.success === true && res.data.status === 200) {
              Swal.fire({
                title: "Success",
                text: "Ticket status has been updated successfully",
                timer: 2000,
                icon: "info",
                confirmButtonColor: "#011535",
              }).then((_) => {
                setDeleting(false);
                setReloadTable(!reloadTable);
              });
            }
          })
          .catch((err) => {
            setDeleting(false);
            Swal.fire({
              title: "Failed",
              text: "Error Updating Ticket Status",
              timer: 2000,
              icon: "info",
              confirmButtonColor: "#011535",
            });
          });
      }
    });
  };

  return (
    <tr>
      <td className="text-capitalize">{ticket?.title}</td>
      <td className="text-capitalize">{ticket?.price}</td>
      <td className="text-capitalize text-center">{ticket?.slots_available}</td>
      <td className="text-center">{ticket?.slots_occupied}</td>
      <td className="text-center">
        {ticket?.slots_available - ticket?.slots_occupied}
      </td>
      <td className="text-center">
        {Number(ticket?.price || 0)?.toFixed(2) *
          Number(ticket?.slots_occupied || 0).toFixed()}
      </td>
      <td>
        <div className="actions text-center">
          <span
            className={`btn btn-sm ${
              (ticket?.status === "active" || ticket?.status === "full") &&
              "bg-success-light"
            } ${
              (ticket?.status === "blocked" || ticket?.status === "closed") &&
              "bg-danger-light"
            } mr-2 text-center text-capitalize`}
          >
            {ticket?.status}
          </span>
        </div>
      </td>
      <td>
        {Number(localStorage.getItem("manage_ticket")) === Number(1) && (
          <>
            <div className="d-flex align-items-end justify-content-end">
              <div>
                <Button
                  buttonText={
                    <span>
                      <i className="fas fa-pencil-alt"></i>
                    </span>
                  }
                  cssClasses={"btn btn-outline-info"}
                  callback={() =>
                    navigate(
                      `/dashboard/event/${event_id}/ticket/${ticket?.id}/edit`
                    )
                  }
                />
              </div>
              {(ticket?.status === "active" || ticket?.status === "full") && (
                <div className="ml-2">
                  <Button
                    buttonText={
                      <span>
                        <i className="fas fa-trash-alt"></i>
                      </span>
                    }
                    cssClasses={"btn btn-danger"}
                    callback={() =>
                      handleStatusChange(
                        "blocked",
                        "This action will block ticket from being purchased or verified. Do you want to proceed on with this action?"
                      )
                    }
                    isloading={isDeleting}
                  />
                </div>
              )}
              {(ticket?.status === "blocked" ||
                ticket?.status === "closed") && (
                <div className="ml-2">
                  <Button
                    buttonText={
                      <span>
                        <i className="fe fe-check" title="Activate Ticket"></i>
                      </span>
                    }
                    cssClasses={"btn btn-outline-success"}
                    callback={() =>
                      handleStatusChange(
                        "active",
                        "Do you want to activate this ticket?"
                      )
                    }
                    isloading={isDeleting}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default TableRows;
