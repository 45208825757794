import { useParams } from "react-router-dom";
import { useState } from "react";
import Pagination from "../../../components/Pagination";
import BarChart from "../../../components/BarChart";
import useFetch from "../../../components/customHooks/useFetch";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";

const GuestResults = () => {
  const { category_id } = useParams();

  const { data } = useFetch(`/api/v2/guest/category/${category_id}/results`);
  var curr = new Date();
  curr.setDate(curr.getDate() + 3);
  // var date = curr.toISOString().substring(0, 10);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data?.results?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [search, setSearch] = useState("");

  return (
    <>
      <Nav />
      <div className="award-div d-flex">
        <div className="award-img-title">
          <h1 className="text-white text-capitalize">
            {data?.category?.award?.title || "Loading..."} (Results)
          </h1>
          <h3 className="text-white text-capitalize text-center">
            {data?.category?.title || "Loading..."} (Results)
          </h3>
        </div>
      </div>
      <div className="content container">
        <BarChart results={data?.results} />
        <div className="row mt-3">
          <div className="col-sm-2"></div>
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-hearder">
                <div className="d-flex justify-content-between mx-3 mt-2">
                  <div className="p-2">
                    <h5>Contestants Vote Count</h5>
                  </div>
                  <div className="p-2">
                    <input
                      type="text"
                      placeholder="Search Here"
                      className="form-control"
                      required
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body booking_card">
                <div className="table-responsive">
                  <table
                    id="results-table"
                    className="datatable table table-stripped table table-hover table-center mb-0"
                  >
                    <thead>
                      <tr>
                        <th>Nominee Code</th>
                        <th>Full Name/Stage Name</th>
                        <th className="text-center">Votes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts
                        ?.filter(
                          (item) =>
                            search === "" ||
                            item?.stage_name
                              ?.toLowerCase()
                              .includes(search.toLowerCase()) ||
                            item?.full_name
                              ?.toLowerCase()
                              .includes(search.toLowerCase()) ||
                            item?.nominee_code
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                        )
                        ?.map((result, index) => (
                          <tr key={index}>
                            <td className="text-uppercase">
                              {result?.nominee_code}
                            </td>
                            <td className="text-capitalize">
                              {result?.stage_name || result?.full_name}
                            </td>
                            <td className="text-center">
                              {result?.votes?.count || 0}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagin-pos">
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={data?.results?.length}
                    paginate={paginate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GuestResults;
